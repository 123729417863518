import { connect } from 'react-redux';

import {
  deleteFavoritesRequest,
  getFavoritesRequest,
  setFavoritesRequest,
} from '../../../../redux/reducers/favorites/actions';
import { favoritesProductsSelector } from '../../../../redux/reducers/favorites/selectors';
import { actionCreators as modalActionCreators } from '../../../../redux/reducers/modal';
import { setIsSearchEnabled } from '../../../../redux/reducers/search/actions';
import Layout from '../../components/Layout';

const mapStateToProps = (state) => ({
  modalChildrens: state?.modal?.modalChildrens,
  favorites: favoritesProductsSelector(state),
});

const mapDispatchToProps = {
  showModal: modalActionCreators.showModal,
  hideModal: modalActionCreators.hideModal,
  getFavoritesRequest,
  setFavoritesRequest,
  deleteFavoritesRequest,
  setIsSearchEnabled,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
