import classNames from 'classnames';

import { PropsWithClassName } from '@/shared/types/util';

import LoaderWithOverlay from '../WithOverlay/WithOverlay';

import style from './Radial.module.css';

export function RadialLoader({ className }: PropsWithClassName) {
  return (
    <svg className={classNames(style.root, className)} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <circle
        cx='50'
        cy='50'
        r='44'
        fill='transparent'
        stroke='currentColor'
        strokeOpacity='0.4'
        strokeWidth='18'
        className={style.track}
      />
      <circle
        cx='50'
        cy='50'
        r='44'
        fill='transparent'
        stroke='currentColor'
        strokeDasharray='276.460 276.460'
        strokeDashoffset='276.46'
        strokeWidth='18'
        className={style.ring}
      />
      <circle
        cx='50'
        cy='50'
        r='44'
        fill='transparent'
        stroke='curr'
        strokeDasharray='276.460 276.460'
        strokeDashoffset='276.46'
        strokeWidth='18'
        className={style.overlay}
      />
    </svg>
  );
}

// eslint-disable-next-line max-len
export const RadialLoaderWithOverlay = ({ className }: PropsWithClassName) => (
  <LoaderWithOverlay className={className} component={RadialLoader} />
);
