export { default as isLegacyStyleEnabled, PRODUCT_AVAILABILITY } from './lib';
export { default as getBaseCookies } from './lib/cookies';

export { default as BaseAppRequest } from './api/utils/client';
export { default as AppRedirect } from './model/redirects';
export { default as RegionService } from './model/region';
export { default as AppMenu } from './model/menu';
export { default as AppUser } from './model/user';
export { default as Shops } from './model/shops';
export type {default as MainFilter} from './model/main-filter/type';

export { ApiResource, AbstractApiResource } from './api';
export { default as RegionApi } from './api/region';
export { default as DeliveryApi } from './api/delivery';
export type { UrlFactory } from './api';

export {
  useAppStore,
  createAppStore,
  useAppUi,
  useAppPortal,
  useAppClient,
  useAppDictionary,
  useAppLayoutData,
  useAppStoreApi,
  AppStoreContext,
} from './store';
export { default as useRegionId, useRegion } from './model/region/store/useRegionId';
export {
  useModeliSectionRoute,
  useAdresaMagazinovSectionRoute,
  useCartSectionRoute,
  useProductSectionRoute,
  useContactSectionRoute,
} from './config';
export { default as useAppUserFavorites } from './model/user/store/useAppFavorites';

export type { BaseAppRequestMethods } from './api/utils/client';
export type {
  Contacts,
  ModelColorItem,
  PageBanner,
  PageShop,
  SliderItem,
  ServiceItem,
  AdvantageItem,
  ReviewItem,
  InviteItem,
  ArticleItem,
  ProjectItem,
  SeoText,
  FilterItemShort as FilterItem,
} from './model/type';
export type { ProductSize, ProductColor, ProductPrice, ProductComment, MinifiedOffer } from './model/type/product';
export type {
  EmailData,
  ContactsData,
  ContactsPopupData,
  ContactsDetailItem,
  ContactsDetailData,
} from './model/contacts';
export type { SpecificationItem, SpecificationColorItem } from './model/type/specification';
export type { AppClient } from './store/client';
export type { AppUi } from './store/ui';
export type { RegionShort, RegionFull, PageInfoByRegion } from './model/region/type';
export type { CatalogType } from './model/type/catalog';
export type { Shop } from './model/type/shop';
export type { FooterData } from './model/type/footer';
export type { BaseQuery, QueryWithPagination } from './model/type/query';
export type { default as UserData } from './model/user/type';
export type { ColorItem } from './model/type/color';

export { API_ROOT, API_VERSION, API_RESOURCE, API_CONTENT_ENDPOINT } from './config/constants/api';
export { BASE_DOMAIN } from './lib';
export { COOKIE_NAME, COOKIE_WHITELIST } from './config/constants/api';
export { SECTION_TO_ROUTE, APP_SECTION } from './config/constants/routes';

/* CART */
export { default as useAppCart } from './store/cart/useAppCart';
export type { Cart } from './store/cart/type';
