import { GetServerSidePropsContext } from 'next';

import { BaseAppRequest } from '@/core/api/utils/client';
import ApiResource from '@/core/api/utils/endpoint';
import { FilterByRegionItem, PageInfoByRegion, RegionFull, RegionShop, RegionShort } from '@/core/model/region/type';
import { toResponseError } from '@/shared/api/util';
import { Id } from '@/shared/types';

const ENDPOINT = {
  INFO: '/info',
  BY_IP: '/by-ip',
  FILTERS: '/filters',
  SHOPS: '/shops',
};

class RegionApi {
  readonly client: BaseAppRequest;

  constructor(ctx?: GetServerSidePropsContext) {
    this.client = new BaseAppRequest(ctx);
  }

  getFullRegionById = async (id: Id) => {
    const url = ApiResource.region.get(`/${id}`);

    try {
      const { data } = await this.client.get<RegionFull>(url);
      return data;
    } catch (error) {
      console.error('[region][api][getFullRegionById]', { url, error, id });
      throw toResponseError(error);
    }
  };

  getByIp = async (ip: string) => {
    const url = ApiResource.region.get(`${ENDPOINT.BY_IP}/${ip}`);

    try {
      const { data } = await this.client.get<RegionShort>(url);
      return data;
    } catch (error) {
      console.error('[region][api][getByIp]', { url, error, ip });
      throw toResponseError(error);
    }
  };

  getList = async () => {
    try {
      const url = ApiResource.region.get();

      const { data } = await this.client.get<RegionFull[]>(url);

      return data;
    } catch (error) {
      console.error('[region][api][getList]', { error });
      throw toResponseError(error);
    }
  };

  getInfoByRegionId = async (cityId: Id) => {
    try {
      const url = ApiResource.region.get(`/${cityId}${ENDPOINT.INFO}`);

      const { data } = await this.client.get<PageInfoByRegion>(url);

      return data;
    } catch (error) {
      console.error('[region][api][getInfoByRegionId]', { error });
      throw toResponseError(error);
    }
  };

  getFiltersByRegionId = async (cityId: Id) => {
    try {
      const url = ApiResource.region.get(`/${cityId}${ENDPOINT.FILTERS}`);

      return this.client.get<FilterByRegionItem[]>(url);
    } catch (error) {
      console.error('[region][api][getFiltersByRegionId]', { error });
      throw toResponseError(error);
    }
  };

  getShopsByRegionId = async (cityId: Id) => {
    try {
      const url = ApiResource.region.get(`/${cityId}${ENDPOINT.SHOPS}`);

      const { data } = await this.client.get<RegionShop[]>(url);

      return data || [];
    } catch (error) {
      console.error('[region][api][getShopsByRegionId]', { error });
      throw toResponseError(error);
    }
  };
}

export default RegionApi;
