import { ContactsData } from '@/core/model/type';
import { FooterData } from '@/core/model/type/footer';
import { MenuCategory } from '@/core/model/type/menu';

export interface AppLayoutData {
  contacts: ContactsData;
  contactsData: ContactsData;
  footerData: FooterData;
  menuCategories: Record<string, MenuCategory>;
  menuCodes: string[];
}

const DEF_VALUE: AppLayoutData = {
  contacts: {} as ContactsData,
  contactsData: {} as ContactsData,
  footerData: {} as FooterData,
  menuCategories: {},
  menuCodes: [],
};

const createLayoutDataStore = () => ({
  ...DEF_VALUE,
});

export default createLayoutDataStore;
