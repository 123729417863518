import { ofType } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import initializeAxiosInstance from '../../api/initializeAxiosInstance';

import {
  deleteFavoritesReceive,
  deleteFavoritesRequest,
  getFavoritesReceive,
  getFavoritesRequest,
  setFavoritesReceive,
  setFavoritesRequest,
} from './actions';

const getFavoritesFromLocalStorage = () => {
  const rawFavorites = global.window?.localStorage?.getItem('favorites');
  let favorites;
  try {
    favorites = JSON.parse(rawFavorites)?.state || [];
  } catch (e) {
    favorites = [];
  }
  return favorites;
};

export const getFavoritesRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(getFavoritesRequest),
    mergeMap(() => {
      const isUserAuthorized = !!state$.value?.cabinet?.userAuthorized?.authorized;

      const instance = initializeAxiosInstance({ store: state$ });

      if (isUserAuthorized) {
        return from(instance.get('/user/favorites')).pipe(
          mergeMap((response) =>
            of(
              getFavoritesReceive({
                favoritesProducts: response.data?.products?.data || [],
                pagination: response.data?.products?.pagination,
              })
            )
          ),
          catchError((error) => {
            if (error) {
              return of(
                getFavoritesReceive({
                  favoritesProducts: [],
                  pagination: null,
                })
              );
            }

            return EMPTY;
          })
        );
      }

      const favoritesProducts = getFavoritesFromLocalStorage();
      const productIds = favoritesProducts?.map?.((item) => item.id);

      return from(instance.get('/user/favorites', { params: { id: productIds } })).pipe(
        mergeMap((result) => {
          if (result) {
            const products = result?.data?.products?.data || [];

            return of(getFavoritesReceive({ favoritesProducts: products.filter((item) => !!item) }));
          }
          return EMPTY;
        }),
        catchError((error) => {
          if (error) {
            return of(getFavoritesReceive({ favoritesProducts: [] }));
          }

          return EMPTY;
        })
      );
    })
  );

export const setFavoritesRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(setFavoritesRequest),
    mergeMap((actionData) => {
      const {
        payload: { product },
      } = actionData;

      const isUserAuthorized = !!state$.value?.cabinet?.userAuthorized?.authorized;

      const instance = initializeAxiosInstance({ store: state$ });

      if (isUserAuthorized) {
        return from(instance.post('user/favorites', { id: product?.id })).pipe(
          mergeMap((response) =>
            of(
              setFavoritesReceive({
                favoritesProducts: response.data?.products?.data?.filter((item) => !!item) || [],
                pagination: response.data?.products?.pagination,
              }),
              getFavoritesReceive({
                favoritesProducts: response.data?.products?.data?.filter((item) => !!item) || [],
                pagination: response.data?.products?.pagination,
              })
            )
          ),
          catchError((error) => {
            if (error) {
              return of(setFavoritesReceive({ favoritesProducts: [] }));
            }

            return EMPTY;
          })
        );
      }

      const favorites = getFavoritesFromLocalStorage();
      console.log('old favorites', favorites);
      const newFavorites = [
        ...favorites
          .filter((item) => !!item)
          .filter((x) => x?.id.toString() !== product?.id.toString())
          .map((item) => ({ id: item?.id })),
        { id: product.id },
      ];

      console.log('newFavorites', newFavorites);
      global.window?.localStorage?.setItem('favorites', JSON.stringify(newFavorites));

      return of(setFavoritesReceive({ favoritesProducts: newFavorites }), getFavoritesRequest());
    })
  );

export const deleteFavoritesRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(deleteFavoritesRequest),
    mergeMap((actionData) => {
      const {
        payload: { product },
      } = actionData;

      const isUserAuthorized = !!state$.value?.cabinet?.userAuthorized?.authorized;

      const instance = initializeAxiosInstance({ store: state$ });

      if (isUserAuthorized) {
        return from(instance.delete('user/favorites', { params: { id: product?.id } })).pipe(
          mergeMap((response) =>
            of(
              deleteFavoritesReceive({
                favoritesProducts: response.data?.products?.data?.filter((item) => !!item) || [],
                pagination: response.data?.products?.pagination,
              }),
              getFavoritesReceive({
                favoritesProducts: response.data?.products?.data?.filter((item) => !!item) || [],
                pagination: response.data?.products?.pagination,
              })
            )
          ),
          catchError((error) => {
            if (error) {
              return of(deleteFavoritesReceive({ favoritesProducts: [] }));
            }

            return EMPTY;
          })
        );
      }

      const favoriteProducts = state$.value?.favorites?.favoritesProducts || [];
      const favorites = getFavoritesFromLocalStorage();
      const newFavorites = (favorites || []).filter((x) => x?.id.toString() !== product?.id.toString());

      global.window?.localStorage?.setItem('favorites', JSON.stringify(newFavorites));

      return of(
        deleteFavoritesReceive({
          favoritesProducts: favoriteProducts
            .filter((item) => !!item)
            .filter((x) => x?.id.toString() !== product?.id.toString()),
        }),
        getFavoritesRequest()
      );
    })
  );
