import { NEXT_PUBLIC_API_ROOT } from 'cfg';

import { API_RESOURCE, API_VERSION } from '../../../config/constants/api';

import AbstractApiResource from './abstract';

class RegionApiResource extends AbstractApiResource {
  constructor() {
    super(`${NEXT_PUBLIC_API_ROOT}${API_VERSION.REST}${API_RESOURCE.REGION}`);
  }

  get = (url?: string) => this.urlFactory(url ?? '');
}

export default RegionApiResource;
