/* eslint-disable no-unsafe-optional-chaining */
import { GetServerSidePropsContext } from 'next';

import ShopsApi from '@/core/api/shops';
import { toResponseError } from '@/shared/api/util';
import { Id } from '@/shared/types';

class Shops {
  private data: any[] | null;

  readonly client: ShopsApi;

  constructor(ctx?: GetServerSidePropsContext) {
    this.client = new ShopsApi(ctx);
    this.data = null;
  }

  getData = async (regionId: Id) => {
    try {
      const data = await this.client.getPage();
      this.data = data;

      const result = this.mapShops(regionId);

      return result;
    } catch (error) {
      console.error('[core][model][shops][getData]');
      throw toResponseError(error);
    }
  };

  mapShops = (regionId: Id) =>
    this?.data
      ?.filter((shop) => !regionId || +regionId === +shop?.regionId)
      ?.reduce((reducer, item) => {
        const temp = reducer;
        temp[item.id] = item;

        return temp;
      }, {});
}

export default Shops;
