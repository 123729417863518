import React, {
  memo, useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import classnames from 'classnames';
import throttle from 'lodash/throttle';
import Nuka from 'nuka-carousel';

import arrowLeft from '../../assets/imgs/common/arrow-left.svg';
import arrowLeftWithBorder from '../../assets/imgs/common/arrow-left-with-border.svg';
import arrowRight from '../../assets/imgs/common/arrow-right.svg';
import arrowRightWithBorder from '../../assets/imgs/common/arrow-right-with-border.svg';
import SvgIcon from '../SvgIcon';

import styles from './Carousel.module.css';


function renderDefaultBottomRightControls(options) {
  const { slideCount, currentSlide, goToSlide } = options;
  const points = new Array(slideCount || 0).fill(true);

  return (
    <div className={styles.points}>
      {points.map((point, i) => (
        <button
          key={`point-${i}`}
          type='button'
          onClick={() => goToSlide(i)}
          className={classnames(styles.point, { [styles.selected]: currentSlide === i })}
        />
      ))}
    </div>
  );
}

const CarouselContent = memo(({
  className,
  containerClassName,
  theme,
  containerRef,
  goToSlideRef,
  slidesToShow,
  handleAfterSlide,
  handleBeforeSlide,
  renderBottomCenterControls,
  renderBottomRightControls,
  isLoaded,
  isAutoplayDisabled,
  isArrowButtonShadowEnabled,
  isDefaultBottomRightControlsEnabled,
  children,
}) => {
  const renderCenterLeftControls = useCallback(({
    goToSlide,
    previousSlide,
    previousDisabled,
    onUserNavigation,
    defaultControlsConfig: {
      previousButtonOnClick,
    },
  }) => {
    if (goToSlideRef) {
      goToSlideRef.current = goToSlide;
    }

    const handleClick = (event) => {
      previousButtonOnClick?.(event);

      if (event.defaultPrevented) {
        return;
      }

      onUserNavigation(event);

      event.preventDefault();
      previousSlide();
    };

    if (theme === 'new') {
      return (
        <button
          type='button'
          aria-label='Назад'
          disabled={previousDisabled}
          onClick={handleClick}
          className={classnames(styles.arrowButton, styles.new, { [styles.shadowed]: !!isArrowButtonShadowEnabled })}
        >
          <SvgIcon svg={arrowLeftWithBorder} />
        </button>
      );
    }
    return (
      <button
        type='button'
        aria-label='Назад'
        disabled={previousDisabled}
        onClick={handleClick}
        className={classnames(styles.arrowButton, { [styles.shadowed]: !!isArrowButtonShadowEnabled })}
      >
        <SvgIcon svg={arrowLeft} />
      </button>
    );
  }, [goToSlideRef, isArrowButtonShadowEnabled]);

  const renderCenterRightControls = useCallback(({
    goToSlide,
    nextSlide,
    nextDisabled,
    onUserNavigation,
    defaultControlsConfig: {
      nextButtonOnClick,
    },
  }) => {
    if (goToSlideRef) {
      goToSlideRef.current = goToSlide;
    }

    const handleClick = (event) => {
      nextButtonOnClick?.(event);

      if (event.defaultPrevented) {
        return;
      }

      onUserNavigation(event);

      event.preventDefault();
      nextSlide();
    };

    if (theme === 'new') {
      return (
        <button
          type='button'
          aria-label='Вперед'
          disabled={nextDisabled}
          onClick={handleClick}
          className={classnames(styles.arrowButton, styles.new, { [styles.shadowed]: !!isArrowButtonShadowEnabled })}
        >
          <SvgIcon svg={arrowRightWithBorder} />
        </button>
      );
    }
    return (
      <button
        type='button'
        aria-label='Вперед'
        disabled={nextDisabled}
        onClick={handleClick}
        className={classnames(styles.arrowButton, { [styles.shadowed]: !!isArrowButtonShadowEnabled })}
      >
        <SvgIcon svg={arrowRight} />
      </button>
    );
  }, [goToSlideRef, isArrowButtonShadowEnabled]);

  return (
    <div ref={containerRef} className={classnames(styles.container, containerClassName, { [styles.loaded]: isLoaded })}>
      <Nuka
        className={classnames(styles.customCarousel, className, { [styles.paddedBottom]: isDefaultBottomRightControlsEnabled })}
        slidesToShow={slidesToShow || 1}
        wrapAround
        autoplay={process.env.NODE_ENV === 'production' && !isAutoplayDisabled}
        autoplayInterval={8000}
        afterSlide={handleAfterSlide}
        beforeSlide={handleBeforeSlide}
        width='100%'
        heightMode='max'
        disableAnimation={false}
        renderTopCenterControls={null}
        renderBottomCenterControls={isDefaultBottomRightControlsEnabled ? renderDefaultBottomRightControls : renderBottomCenterControls}
        renderBottomRightControls={isDefaultBottomRightControlsEnabled ? renderDefaultBottomRightControls : renderBottomRightControls}
        renderCenterLeftControls={renderCenterLeftControls}
        renderCenterRightControls={renderCenterRightControls}
      >
        {children}
      </Nuka>
    </div>
  );
});

const Carousel = (props) => {
  const {
    goToSlideRef,
    className,
    containerClassName,
    children,
    isSingleSlide,
    isAutoplayDisabled,
    isRoundSlideCountDisabled,
    isArrowButtonShadowEnabled,
    isDefaultBottomRightControlsEnabled,
    slideSize,
    slideWidth,
    afterSlide,
    beforeSlide,
    theme,
    renderBottomCenterControls = null,
    renderBottomRightControls = null,
    onCalculationSlideSize = null,
  } = props;

  const [isFewSlidesCount, setIsFewSlidesCount] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);

  const containerRef = useRef();

  const getMeasures = useCallback(() => {
    if (containerRef.current) {
      const width = containerRef.current?.getBoundingClientRect()?.width;

      if (width) {
        let newSlideWidth;

        if (onCalculationSlideSize) {
          const calculatedSlideSize = onCalculationSlideSize();

          newSlideWidth = calculatedSlideSize?.width;
        } else {
          newSlideWidth = (typeof slideSize?.width === 'number' ? slideSize?.width : null) || slideWidth || 320;
        }

        let newSlidesToShow = width < 475 && !isRoundSlideCountDisabled ? 1 : Math.floor((width - (width < 800 ? 32 : 64)) / newSlideWidth);

        if (newSlidesToShow < 1) {
          newSlidesToShow = 1;
        }

        if (isSingleSlide) {
          newSlideWidth = width;
          newSlidesToShow = 1;
        }

        const newIsFewSlidesCount = children?.length ? (children?.length ?? 0) * newSlideWidth < width : false;

        setIsFewSlidesCount(newIsFewSlidesCount);
        setSlidesToShow(newSlidesToShow);
      }
    }
  }, [
    containerRef?.current,
    onCalculationSlideSize,
    slideSize,
    isRoundSlideCountDisabled,
    isSingleSlide,
    children?.length,
    setIsFewSlidesCount,
    setSlidesToShow,
  ]);

  useEffect(() => {
    getMeasures();
  }, []);

  useEffect(() => {
    const throttledGetMeasures = () => throttle(() => {
      getMeasures();
    }, 50);

    global?.window?.addEventListener('resize', throttledGetMeasures);

    return () => {
      global?.window?.removeEventListener('resize', throttledGetMeasures);
    };
  }, [
    containerRef?.current,
    onCalculationSlideSize,
    slideSize,
    isRoundSlideCountDisabled,
    isSingleSlide,
    children?.length,
    setIsFewSlidesCount,
    setSlidesToShow,
  ]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const handleAfterSlide = useCallback((i) => {
    if (afterSlide) {
      afterSlide(i);
    }
  }, [afterSlide]);

  const handleBeforeSlide = useCallback((i) => {
    if (beforeSlide) {
      beforeSlide(i);
    }
  }, [beforeSlide]);

  if (isFewSlidesCount && !isSingleSlide) {
    return (
      <div ref={containerRef} className={classnames(styles.container, containerClassName, { [styles.loaded]: isLoaded })}>
        <div className={styles.simpleGallery}>
          {children}
        </div>
      </div>
    );
  }

  return (
    <CarouselContent
      className={className}
      containerClassName={containerClassName}
      theme={theme}
      slidesToShow={slidesToShow}
      containerRef={containerRef}
      goToSlideRef={goToSlideRef}
      handleAfterSlide={handleAfterSlide}
      handleBeforeSlide={handleBeforeSlide}
      renderBottomCenterControls={renderBottomCenterControls}
      renderBottomRightControls={renderBottomRightControls}
      isLoaded={isLoaded}
      isAutoplayDisabled={isAutoplayDisabled}
      isArrowButtonShadowEnabled={isArrowButtonShadowEnabled}
      isDefaultBottomRightControlsEnabled={isDefaultBottomRightControlsEnabled}
    >
      {children}
    </CarouselContent>
  );
};

export default memo(Carousel);
