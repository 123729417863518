import { useCallback, useMemo } from 'react';
import { createSelector } from 'reselect';

import { useAppStoreApi } from '../AppStoreProvider';
import { GlobalAppStore } from '../type';
import useAppStore from '../useAppStore';

import { UiCounters, UiLayout } from '.';

const uiSelector = createSelector([(state: GlobalAppStore) => state.ui], (ui) => ui);

const useAppUi = () => {
  const { getState, setState } = useAppStoreApi();
  const ui = useAppStore(uiSelector);

  const setCounters = useCallback(
    (counters: UiCounters) =>
      setState((state) => {
        state.ui.counters = counters;
      }),
    [setState]
  );

  const setLayout = useCallback(
    (layout: Partial<UiLayout>) => {
      const prev = getState().ui.layout;

      setState((state) => {
        state.ui.layout = {
          ...prev,
          ...(layout as any),
        };
      });
    },
    [setState, getState]
  );

  const setOffset = useCallback(
    (offset: Partial<{ x: number; y: number }>) => {
      const prevOffset = getState().ui.offset;
      setState((state) => {
        state.ui.offset = {
          ...prevOffset,
          ...offset,
        };
      });
    },
    [setState, getState]
  );

  const setHeaderOffset = useCallback(
    (offset: number) => {
      setState((state) => {
        state.ui.layout.headerOffset = offset;
      });
    },
    [setState]
  );

  return useMemo(
    () => ({
      ...ui,
      setCounters,
      setLayout,
      setOffset,
      setHeaderOffset,
    }),
    [ui, setCounters, setLayout, setOffset, setHeaderOffset]
  );
};

export default useAppUi;
