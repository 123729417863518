import React, { useState } from 'react';
import classnames from 'classnames';
import InputMask from 'react-input-mask';

import styles from './PhoneField.module.css';

import CloseIcon from '../../icons/Close';

const showErrorOnChange = ({
   meta: {
     submitError,
     dirtySinceLastSubmit,
     error,
     touched,
     modified,
   },
 }) => !!(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified));

const showErrorOnBlur = ({
   meta: {
     submitError,
     dirtySinceLastSubmit,
     error,
     touched,
   },
 }) => !!(((submitError && !dirtySinceLastSubmit) || error) && touched);

const PhoneField = (props) => {
  const {
    input: {
      name, type, value, onChange, ...restInput
    },
    meta,
    placeholder,
    required,
    className,
    theme,
    size,
    showError = showErrorOnChange,
    ...rest
  } = props;

  const { touched, error, submitError } = meta;

  const isError = showError({ meta });

  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleClear = () => {
    if (onChange) {
      onChange('');
    }
  };

  return (
    <div
      className={classnames(styles.textField, className, {
        [styles.textFieldError]: isError,
        [styles.mini]: size === 'mini',
        [styles.white]: theme === 'white',
        [styles.gray]: theme === 'gray',
        [styles.bordered]: theme === 'bordered',
      })}
      {...rest}
    >
      <InputMask
        className={styles.textFieldInput}
        mask='+7 (999) 999-99-99'
        value={value}
        type={type}
        required={required}
        placeholder={placeholder}
        onChange={handleChange}
        {...restInput}
      />
      {touched && (
        <div className={styles.textFieldError}>
          {error || submitError}
        </div>
      )}
      {value?.length !== 0 && (
        <button className={styles.clear} onClick={handleClear}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
};

export default PhoneField;