import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isNil from 'lodash/isNil';

import useWindowResize from '../../../../hooks/useWindowResize';

import closeIcon from '../../../../icons/CloseIcon';

import styles from './DropdownWrapper.module.css';


const DropdownWrapper = (props) => {
  const {
    children,
    isOpen,
    mode,
    wide,
    popupOffsetRight: rawPopupOffsetRight,
    popupWidth = 340,
    parentRef,
    onCloseClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const handleCloseClick = () => {
    if (onCloseClick) {
      onCloseClick();
    }
  };

  const handleMouseEnter = () => {
    if (onMouseEnter) {
      onMouseEnter();
    }
  };

  const handleMouseLeave = () => {
    if (onMouseLeave) {
      onMouseLeave();
    }
  };

  const popupOffsetRight = isNil(rawPopupOffsetRight) ? 45 : rawPopupOffsetRight;

  const windowSize = useWindowResize();

  const width = windowSize.width || 1024;

  const dropdownWrapperRef = useRef();

  let wrapperStyle = {};

  if (mode === 'popup') {
    wrapperStyle = {
      width: popupWidth,
      right: -popupOffsetRight,
    };

    if (width < 375) {
      wrapperStyle.width = width - 30;
    }

    if (wide && width < 1280) {
      wrapperStyle.width = width;
    }

    if (parentRef?.current) {
      const rect = parentRef?.current?.getBoundingClientRect();

      if (wide) {
        wrapperStyle.right = -(width - rect.right);
      } else {
        if (rect.right + popupOffsetRight + 15 > width) {
          wrapperStyle.right = -popupOffsetRight + ((rect.right + popupOffsetRight + 15) - width) + (width < 375 ? 0 : 15);
        }

        if (rect.right + popupOffsetRight - wrapperStyle.width < 15) {
          wrapperStyle.right = -popupOffsetRight - (rect.right + popupOffsetRight - wrapperStyle.width - 15);
        }
      }
    }
  }


  return (
    <div
      ref={dropdownWrapperRef}
      style={wrapperStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classnames(styles.dropdownWrapper, {
        [styles.open]: isOpen,
        [styles.popup]: mode === 'popup',
        [styles.wide]: !!wide,
      })}
    >
      {(mode !== 'popup' || !!wide) && (
        <div
          className={styles.dropdownWrapperBackdrop}
          onClick={handleCloseClick}
          tabIndex='0'
          role='button'
        />
      )}
      {!!isOpen && (
        <div className={styles.dropdownWrapperContent}>
          <div className={styles.dropdownWrapperBody}>
            {children}
          </div>
          {mode !== 'popup' && (
            <div className={styles.dropdownWrapperActions}>
              <button className={styles.dropdownWrapperCloseButton} onClick={handleCloseClick}>
                {closeIcon}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};


DropdownWrapper.propTypes = {
  children: PropTypes.object,
  isOpen: PropTypes.bool,
  mode: PropTypes.string,
  wide: PropTypes.bool,
  popupOffsetRight: PropTypes.number,
  popupWidth: PropTypes.number,
  parentRef: PropTypes.object,
  onCloseClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

DropdownWrapper.defaultProps = {
  children: null,
  isOpen: false,
  mode: null,
  wide: false,
  popupOffsetRight: null,
  popupWidth: 340,
  parentRef: null,
  onCloseClick: null,
  onMouseEnter: null,
  onMouseLeave: null,
};

export default DropdownWrapper;
