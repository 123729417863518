import { handleActions } from 'redux-actions';

import {
  sendFormRequest,
  sendFormSuccess,
  sendFormFailure,
} from './actions';


const initialState = {
  isSendFormFetching: false,
};


export default handleActions({
  [sendFormRequest]: state => ({
    ...state,
    isSendFormFetching: true,
  }),

  [sendFormSuccess]: state => ({
    ...state,
    isSendFormFetching: false,
  }),

  [sendFormFailure]: state => ({
    ...state,
    isSendFormFetching: false,

  }),

}, initialState);
