import { useCallback, useState } from 'react';

function useToggle(initialValue = false): [boolean, (value?: boolean) => void] {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback((value?: boolean) => {
    if (typeof value === 'boolean') {
      setValue(value);
      return;
    }

    setValue((v) => !v);
  }, []);

  return [value, toggle];
}

export default useToggle;
