import { Cart } from './type';

export type CartStore = Cart;

const DEF_VALUE = {
  price: 0,
  priceFormatted: '0 ₽',
  oldPrice: null,
  oldPriceFormatted: null,
  items: [],
  coupon: null,
  installment: null,
  deliveryServices: [],
  additionalProducts: [],
  isFetching: false,
  error: null,
  isProductFetching: {},
};

const createCartStore = () => ({
  ...DEF_VALUE,
});

export default createCartStore;
