import { createAction } from 'redux-actions';

export const getFavoritesRequest = createAction('GET_FAVORITES_REQUEST');
export const getFavoritesReceive = createAction('GET_FAVORITES_RECEIVE');

export const setFavoritesRequest = createAction('SET_FAVORITES_REQUEST');
export const setFavoritesReceive = createAction('SET_FAVORITES_RECEIVE');

export const deleteFavoritesRequest = createAction('DELETE_FAVORITES_REQUEST');
export const deleteFavoritesReceive = createAction('DELETE_FAVORITES_RECEIVE');
