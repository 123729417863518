import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import Link from 'next/link';
import { useAmp } from 'next/amp';

import * as classnames from 'classnames';
import MakeAsyncFunction from '../../helpers/makeAsyncFunction';

import { promiseListener } from '../../redux/store/createStore';

import Typography from '../../components/Typography';

import FormPlaceholder from '../FormPlaceholder';
import TextField from '../TextField';
import Button from '../Button';

import styles from './UniversalInlineForm.module.css';


const UniversalInlineForm = (props) => {
  const {
    title,
    formPlaceholderActions,
    description,
    start,
    resolve,
    reject,
    actionTitle = 'Жду звонка',
    fields,
    onSubmit: onExternalSubmit,
    className,
    theme,
    url,
    buttonProps,
    getRequest,
    getSuccess,
    getFailure,
  } = props;

  const safeButtonProps = buttonProps || {};
  const isAmp = useAmp();

  const ERR_MSG_REQUIRED = theme === 'row' ? 'Не заполнено обязательное поле' : 'Обязательное поле';
  const ERR_MSG_INCORRECT = 'Поле заполнено некорректно';

  const [isSended, setIsSended] = useState(false);

  const renderedFooter = (
    <>
      <Button className='universalInlineFormAmpButton' type='submit'>
        {actionTitle}
      </Button>

      <div className='universalInlineFormAmpText'>
        Нажимая на кнопку “{actionTitle}”, вы соглашаетесь с{' '}
        <Link href='/politika' className='universalInlineFormAmpLink'>

            политикой конфиденциальности

        </Link>{' '}
        и даете свое согласие на обработку{' '}
        <Link href='/dogovor-oferta' className='universalInlineFormAmpLink'>
          персональных данных
        </Link>
        .
      </div>
    </>
  );

  const setPayload = (action, payload) => ({
    ...action,
    payload: {
      values: {
        ...payload,
      },

      path: url,
      getRequest,
      getSuccess,
      getFailure,
    },
  });

  const handleValidate = (values) => {
    const errors = {};

    fields.forEach((field) => {
      const value = values[field.name];

      if (field?.required && !value) {
        errors[field.name] = ERR_MSG_REQUIRED;
      } else if (field?.name === 'phone') {
        const phone = value?.replace(/\D/g, '');

        if (phone?.length < 11) {
          errors[field.name] = ERR_MSG_INCORRECT;
        }
      }
    });

    return errors;
  };

  const makeHandleSubmit = ({ onSubmit }) => (values) => {
    setIsSended(true);

    onExternalSubmit({ onSubmit, values });
  };

  if (isAmp) {
    return (
      <form
        className={classnames(
          'universalInlineFormAmp',
          'sample-form',
          {
            mini: theme === 'mini',
            row: theme === 'row',
          },
          className
        )}
        method='post'
        action-xhr={`/rest${url}`}
        target='_top'
        custom-validation-reporting='show-first-on-submit'
      >
        {title ? (
          typeof title === 'string' ? (
            <Typography
              variant='h4'
              component='h2'
              className='universalInlineFormAmpTitle'
            >
              {title}
            </Typography>
          ) : (
            title
          )
        ) : null}

        {description ? (
          typeof description === 'string' ? (
            <Typography
              variant='body2'
              className='universalInlineFormAmpDescription'
            >
              {description}
            </Typography>
          ) : (
            description
          )
        ) : null}

        <div className='universalInlineFormAmpContent'>
          <div className='universalInlineFormAmpBody'>
            {fields?.map((field) => {
              const {
                name,
                id,
                component,
                placeholder,
                theme,
                required,
                input,
                type,
                ...other
              } = field;

              return (
                <>
                  <div className={classnames('textField', theme)}>
                    <input
                      className='textFieldInput'
                      name={name}
                      placeholder={placeholder}
                      type={type}
                      id={id}
                      required={required}
                      {...other}
                    />
                    <span
                      className='textFieldError'
                      visible-when-invalid='valueMissing'
                      validation-for={id}
                    >
                      {ERR_MSG_REQUIRED}
                    </span>
                    <span
                      className='textFieldError'
                      visible-when-invalid='patternMismatch'
                      validation-for={id}
                    >
                      {ERR_MSG_INCORRECT}
                    </span>
                  </div>
                </>
              );
            })}

            {theme === 'row' ? (
              <div className='row'>{renderedFooter}</div>
            ) : (
              renderedFooter
            )}
          </div>
        </div>
        <div submit-success>
          <template type='amp-mustache'>
            <FormPlaceholder
              title={(
                <Typography variant='h4' component='div' bold color='link'>
                  Спасибо!
                </Typography>
              )}
              subtitle={(
                <Typography variant='h6' component='div'>
                  Ваш запрос был успешно отправлен!
                </Typography>
              )}
              description={(
                <Typography variant='body2' component='p'>
                  Наш менеджер свяжется с вами в ближайшее время и сообщит всю
                  необходимую информацию.
                </Typography>
              )}
            />
          </template>
        </div>
      </form>
    );
  }
  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={start}
      resolve={resolve}
      reject={reject}
      setPayload={setPayload}
    >
      {onSubmit => (
        <Form
          onSubmit={makeHandleSubmit({ onSubmit })}
          validate={handleValidate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            errors,
            values,
          }) => {
            const renderedFooter = (
              <>
                <Button
                  className={styles.universalInlineFormButton}
                  type='submit'
                  disabled={submitting}
                  {...safeButtonProps}
                >
                  {actionTitle}
                </Button>

                <div className={styles.universalInlineFormText}>
                  Нажимая на кнопку “{actionTitle}”, вы соглашаетесь с{' '}
                  <Link href='/politika' className={styles.universalInlineFormLink}>

                      политикой конфиденциальности

                  </Link>{' '}
                  и даете свое согласие на обработку{' '}
                  <Link href='/dogovor-oferta' className={styles.universalInlineFormLink}>

                      персональных данных

                  </Link>
                  .
                </div>
              </>
            );

            return (
              <form
                onSubmit={handleSubmit}
                className={classnames(
                  styles.universalInlineForm,
                  {
                    [styles.mini]: theme === 'mini',
                    [styles.row]: theme === 'row',
                  },
                  className
                )}
              >
                {title ? (
                  typeof title === 'string' ? (
                    <Typography
                      variant='h4'
                      component='h2'
                      className={styles.universalInlineFormTitle}
                    >
                      {title}
                    </Typography>
                  ) : (
                    title
                  )
                ) : null}

                {description ? (
                  typeof description === 'string' ? (
                    <Typography
                      variant='body2'
                      className={styles.universalInlineFormDescription}
                    >
                      {description}
                    </Typography>
                  ) : (
                    description
                  )
                ) : null}

                <div className={styles.universalInlineFormContent}>
                  <div className={styles.universalInlineFormBody}>
                    {fields.map((field) => {
                      const {
                        name,
                        id,
                        component,
                        placeholder,
                        theme,
                        required,
                        disabled,
                        ...other
                      } = field;

                      return (
                        <Field
                          className={styles.universalInlineFormField}
                          component={component || TextField}
                          name={name}
                          id={id}
                          placeholder={placeholder}
                          theme={theme}
                          disabled={
                            typeof disabled === 'function'
                              ? disabled({ values })
                              : disabled
                          }
                          {...other}
                        />
                      );
                    })}

                    {theme === 'row' ? (
                      <div className={styles.row}>{renderedFooter}</div>
                    ) : (
                      renderedFooter
                    )}
                  </div>
                </div>

                {isSended && (
                  <FormPlaceholder
                    className={styles.universalInlineFormPlaceholder}
                    isSpaced
                    actions={formPlaceholderActions}
                    title={(
                      <Typography
                        variant='h4'
                        component='div'
                        bold
                        color='link'
                      >
                        Спасибо!
                      </Typography>
                    )}
                    subtitle={(
                      <Typography variant='h6' component='div'>
                        Ваш запрос был успешно отправлен!
                      </Typography>
                    )}
                    description={(
                      <Typography variant='body2' component='p'>
                        Наш менеджер свяжется с вами в ближайшее время и сообщит
                        всю необходимую информацию.
                      </Typography>
                    )}
                  />
                )}
              </form>
            );
          }}
        />
      )}
    </MakeAsyncFunction>
  );
};

export default UniversalInlineForm;
