import { connect } from 'react-redux';

import { isProductShowPopupShowSelector } from '../../redux/reducers/popups/selectors';
import { hideProductShowPopup } from '../../redux/reducers/popups/actions';

import ProductShowPopup from './ProductShowPopup';

const mapStateToProps = (state) => ({
  isProductShowPopupShow: isProductShowPopupShowSelector(state),
});

const mapDispatchToProps = {
  hideProductShowPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductShowPopup);
