import { createAction } from 'redux-actions';


export const clearSearchRequest = createAction('CLEAR_SEARCH_REQUEST');

export const getSearchTerminate = createAction('GET_SEARCH_TERMINATE');
export const getSearchRequest = createAction('GET_SEARCH_REQUEST');
export const getSearchSuccess = createAction('GET_SEARCH_SUCCESS');
export const getSearchFailure = createAction('GET_SEARCH_FAILURE');

export const setIsSearchEnabled = createAction('SET_IS_SEARCH_ENABLED');
