import initializeAxiosInstance from '../redux/api/initializeAxiosInstance';

const getRequest = async ({ url, method, ctx, isNewApi }, ...params) => {
  const isServer = typeof global?.window === 'undefined';

  try {
    const instance = initializeAxiosInstance({ ctx, isNewApi });

    const result = await instance[method](url, ...(params || []));

    return result;
  } catch (e) {
    if (isServer) {
      throw e;
    } else {
      return null;
    }
  }
};

export default getRequest;
