import useAppStoreInitialize from '../store/useAppStoreInitialize';

import { AppInitializeProps } from './page/type';

const useAppMount = (props: AppInitializeProps) => {
  const store = useAppStoreInitialize(props);

  return store;
};

export default useAppMount;
