export const BREAKPOINT = {
  XS: 375,
  SM: 540,
  MD: 768,
  LG: 1024,
  XL: 1280,
  XXL: 1920,
};

export const MOBILE_FIRST_MEDIA = {
  XXL: `(min-width: ${BREAKPOINT.XL + 1}px)`,
  XL: `(min-width: ${BREAKPOINT.LG + 1}px)`,
  LG: `(min-width: ${BREAKPOINT.MD + 1}px)`,
  MD: `(min-width: ${BREAKPOINT.SM + 1}px)`,
  SM: `(min-width: ${BREAKPOINT.XS + 1}px)`,
  XS: `(max-width: ${BREAKPOINT.XS}px)`,
};

export const DESKTOP_FIRST_MEDIA = {
  XXXL: `(min-width: ${BREAKPOINT.XXL + 1}px)`,
  XXL: `(max-width: ${BREAKPOINT.XXL + 1}px)`,
  XL: `(max-width: ${BREAKPOINT.XL}px)`,
  LG: `(max-width: ${BREAKPOINT.LG}px)`,
  MD: `(max-width: ${BREAKPOINT.MD}px)`,
  SM: `(max-width: ${BREAKPOINT.SM}px)`,
  XS: `(max-width: ${BREAKPOINT.XS}px)`,
};
