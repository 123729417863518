
export const GET_USER_ORDERS_LIST = 'GET_USER_ORDERS_LIST'
export const GET_USER_ORDERS_LIST_SUCCESS = 'GET_USER_ORDERS_LIST_SUCCESS'
export const GET_USER_ORDERS_LIST_FAIL = 'GET_USER_ORDERS_LIST_FAIL'

export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL'

export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL'

export const CHANGE_USER_DATA = 'CHANGE_USER_DATA'
export const CHANGE_USER_DATA_SUCCESS = 'CHANGE_USER_DATA_SUCCESS'
export const CHANGE_USER_DATA_FAIL = 'CHANGE_USER_DATA_FAIL'

export const SEND_PASSWORD = 'SEND_PASSWORD'
export const SEND_PASSWORD_SUCCESS = 'SEND_PASSWORD_SUCCESS'
export const SEND_PASSWORD_FAIL = 'SEND_PASSWORD_FAIL'
