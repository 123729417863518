import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { useAppStoreApi } from './AppStoreProvider';
import { GlobalAppStore } from './type';

type Selector<S> = (store: GlobalAppStore) => S;
const useAppStore = <S = object>(selector: Selector<S>, equalityFn?: (left: S, right: S) => boolean) => {
  const store = useAppStoreApi();


  return useStoreWithEqualityFn(store, selector, equalityFn ?? shallow);
};

export default useAppStore;
