import { useReducer } from 'react';

const UPDATE_STEP = 1_000_000;

const updateReducer = (num = 0): number => (num + 1) % UPDATE_STEP;

function useUpdate(): [number, () => void] {
  return useReducer(updateReducer, 0);
}

export default useUpdate;
