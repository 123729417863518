import { API_RESOURCE, API_ROOT, API_VERSION } from '../../../config/constants/api';

import AbstractApiResource from './abstract';

export class ModelsApiResource extends AbstractApiResource {
  constructor() {
    super(`${API_ROOT}${API_VERSION.V1}${API_RESOURCE.MODELS}`);
  }

  get = <Query>(url: string, query?: Partial<Query>) => this.urlFactory(`${url}`, query);
}

export default ModelsApiResource;
