/* eslint-disable no-underscore-dangle */
import { AxiosRequestConfig, AxiosResponse, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import { GetServerSidePropsContext } from 'next';
import { NotFoundError, RedirectError } from 'types/errors';

import { getClientIp } from '@/application/lib';
import getBaseCookies from '@/core/lib/cookies';
import { RESPONSE_STATUS, ResponseWrapper } from '@/shared/api';
import ApiClient from '@/shared/api/client';
import { toResponseError } from '@/shared/api/util';
import CookieService from '@/shared/lib/helpers/cookies';
import { isDevDebugMode, isServerDebug } from '@/shared/lib/helpers/predicates';

import getBaseRequestHeaders from '.';

export interface BaseAppRequestMethods {
  fetchQuery: <T = any, D = any>(fetchOptions: AxiosRequestConfig<D>) => Promise<AxiosResponse<T> | T>;
}

export interface FetchQueryOptions<D> extends AxiosRequestConfig<D> {
  rawResponse?: boolean;
}
const DEF_CONFIG = {
  withCredentials: true,
  timeout: 30000,
};

const cookieService = new CookieService();

export class BaseAppRequest implements BaseAppRequestMethods {
  private _client: ApiClient;

  readonly _ctx?: GetServerSidePropsContext;

  constructor(ctx?: GetServerSidePropsContext) {
    this._ctx = ctx;

    const initialConfig = this.getInitialConfig();
    this._client = new ApiClient(initialConfig);
  }

  public get config(): AxiosRequestConfig {
    return this._client.config;
  }

  public get client(): ApiClient {
    return this._client;
  }

  public set client(client: ApiClient) {
    this._client = client;
  }

  public getInitialConfig = () => {
    if (!this._ctx) {
      const headers = getBaseRequestHeaders() ?? {};
      return { headers, ...DEF_CONFIG };
    }

    const baseHeaders = getBaseRequestHeaders() ?? {};
    const serverHeaders = this.getServerHeaders() ?? {};
    const headers = { ...baseHeaders, ...serverHeaders };
    return { headers, ...DEF_CONFIG };
  };

  public get = async <T = any, D = any>(url: string, fetchOptions?: AxiosRequestConfig<D>) => {
    try {
      const reqHeaders = {
        ...(this?.config?.headers ?? {}),
        ...(fetchOptions?.headers ?? {}),
      };

      const config = {
        ...(this.config ?? {}),
        headers: reqHeaders,
      };

      const response = await this.client.get<T>(url, config);

      const { headers } = response;
      this.setResponseCookies(headers);

      return response;
    } catch (error: unknown) {
      throw toResponseError(error);
    }
  };

  public post = async <T = any, D = any>(url: string, fetchOptions?: AxiosRequestConfig<D>): Promise<T> => {
    try {
      const response = await this.client.post<T>(url, fetchOptions);

      return response as T;
    } catch (error: unknown) {
      throw toResponseError(error);
    }
  };

  public delete = async <T = any, D = any>(url: string, fetchOptions?: AxiosRequestConfig<D>): Promise<T> => {
    try {
      const response = await this.client.delete<T>(url, fetchOptions);

      return response as T;
    } catch (error: unknown) {
      throw toResponseError(error);
    }
  }

  public fetchQuery = async <T = any, D = any>(fetchOptions: FetchQueryOptions<D>) => {
    try {
      const response = await this.client.fetchQuery<T, D>(fetchOptions);

      const { headers } = response;

      this.setResponseCookies(headers);

      return this.unwrapResponse(response as AxiosResponse<ResponseWrapper<T>>);
    } catch (error: unknown) {
      throw toResponseError(error);
    }
  };

  unwrapResponse = <T = any>(response: AxiosResponse<ResponseWrapper<T>>): T => {
    const { data: { data, status, error } = {} } = response;

    if (status === RESPONSE_STATUS.ERROR) {
      throw toResponseError(error);
    }

    return data!;
  };

  processError = (error: any) => {
    const { response } = error;

    if (isDevDebugMode()) {
      console.log(response);
    }

    if (
      (response?.status === 302 || response?.status === 301 || response?.status === 307 || response?.status === 308) &&
      response?.headers?.get('X-LOCATION')
    ) {
      return Promise.reject(
        new RedirectError({
          destination: response.headers.get('X-LOCATION'),
          permanent: response.status === 301 || response.status === 308,
        })
      );
    }

    if (response?.status === 404 || response?.status === 400) {
      return Promise.reject(new NotFoundError());
    }

    throw toResponseError(error);
  };

  getServerHeaders = () => {
    if (!this._ctx) {
      return;
    }

    const { req } = this._ctx;

    const cookies = getBaseCookies(this._ctx);
    const ifModifiedSince = req.headers['if-modified-since'] || null;
    const ip = getClientIp(req);
    const userAgent = req ? req.headers['user-agent'] : global.navigator.userAgent;

    const result = {
      Cookie: cookies ? cookieService.stringify(cookies) : null,
      'X-FRONTEND-IP': ip || null,
      'X-FRONTEND-USERAGENT': userAgent || null,
      'X-IF-MODIFIED-SINCE': ifModifiedSince || null,
    };

    const headers = Object.entries(result).reduce((reducer, [key, value]) => {
      if (value) {
        return { ...reducer, [key]: value };
      }

      return reducer;
    }, {});

    return headers;
  };

  setResponseCookies = (headers: RawAxiosResponseHeaders | AxiosResponseHeaders) => {
    if (!this._ctx) {
      return;
    }

    const clientCookies = cookieService.parseSetCookie(headers['set-cookie'] ?? []);

    if (!clientCookies?.length) {
      return;
    }

    clientCookies.forEach((cookie) => {
      const { name, value, ...restProps } = cookie;
      const options = this._ctx ? { ctx: this._ctx, ...restProps } : undefined;

      if (this._ctx?.query && isServerDebug(this._ctx.query)) {
        console.log('[core][api][client][set cookie]', { name, value, options: restProps });
      }

      cookieService.setValue(name, value, options);
    });
  };
}

export default BaseAppRequest;
