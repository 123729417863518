import { PropsWithClassName } from '@/shared/types/util';

function ArrowDownIcon({ className }: PropsWithClassName) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      fill='none'
      viewBox='0 0 32 32'
    >
      <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' d='M9 13l7 7 7-7' />
    </svg>
  );
}

export default ArrowDownIcon;
