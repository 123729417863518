import { connect } from 'react-redux';

import HeaderNav from './HeaderNav';

import { actionCreators as modalsActionCreators } from '../../../../redux/reducers/modal';
import { favoritesProductsSelector } from '../../../../redux/reducers/favorites/selectors';
import { shopsCountSelector, currentShopsCountSelector } from '../../../../redux/reducers/info/selectors';
import { isSearchFetchingSelector } from '../../../../redux/reducers/search/selectors';
import cabinetActions from '../../../../redux/reducers/cabinet/actions';


const mapStateToProps = (state, props) => ({
  shopsCount: shopsCountSelector(state, props),
  currentShopsCount: currentShopsCountSelector(state, props),
  favorites: favoritesProductsSelector(state),
  isSearchFetching: isSearchFetchingSelector(state),
});

const mapDispatchToProps = {
  showModal: modalsActionCreators.showModal,
  logout: cabinetActions.logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);
