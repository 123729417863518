import { NEXT_PUBLIC_API_ROOT } from 'cfg';

export const API_ROOT = NEXT_PUBLIC_API_ROOT?.includes('/rest')
  ? NEXT_PUBLIC_API_ROOT.replace('/rest', '')
  : NEXT_PUBLIC_API_ROOT;

export const API_VERSION = {
  REST: '/rest',
  V1: '/shop/api/v1',
  V2: '/shop/api/v2',
};

export const API_RESOURCE = {
  CONTENT: '/content',
  CONTACTS: '/contacts',
  FOOTER: '/footer',
  FORMS: '/forms',
  MODELS: '/models',
  MENU: '/menu',
  PRODUCTS: '/products',
  REGION: '/regions',
  REDIRECTS: '/redirects',
  USER: '/user',
  CATALOG: '/catalog',
  MAIN_FILTERS: '/main/filters',
  ATELIER: '/atelier'
} as const;

export const API_CONTENT_ENDPOINT = {
  PAGE: '/page',
  FORM: '/form',
};

export const COOKIE_NAME = {
  PHPSESSID: 'PHPSESSID',
  FUSER: 'fuser',
  SESSION: 'session',
  COUPON_CODE: 'couponCode',
  DEVICE_UUID: 'deviceUUID',
  MINDBOX_DEVICE_UUID: 'mindboxDeviceUUID',
  GCLID: 'gclid',
  GA: '_ga',
  ISSALESMAN: 'issalesman',
  FEATURE_DECORATORS: 'feature:decorators',
  FEATURE_DEMONSTRATION_BLINK: 'feature-demonstration-blink',
};

export const REGION_COOKIE = {
  CURRENT_ID: 'regionId',
  CONFIRMED: 'isRegionConfirmed',
};

export const COOKIE_WHITELIST = [
  COOKIE_NAME.PHPSESSID,
  COOKIE_NAME.FUSER,
  COOKIE_NAME.SESSION,
  REGION_COOKIE.CONFIRMED,
  REGION_COOKIE.CURRENT_ID,
  COOKIE_NAME.COUPON_CODE,
  COOKIE_NAME.DEVICE_UUID,
  COOKIE_NAME.MINDBOX_DEVICE_UUID,
  COOKIE_NAME.GCLID,
  COOKIE_NAME.GA,
  COOKIE_NAME.ISSALESMAN,
  COOKIE_NAME.FEATURE_DECORATORS,
];

export const API_CATALOG_TYPE = {
  KOVER: 'kovery',
  LIBERTY: 'liberty',
} as const;
