import React from 'react';

const Close = () => (
  <svg width='1em' height='1em' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.00008 8.41429L12.293 13.7072L13.7072 12.293L8.41429 7.00008L13.7072 1.70718L12.293 0.292969L7.00008 5.58586L1.70718 0.292969L0.292969 1.70718L5.58586 7.00008L0.292969 12.293L1.70718 13.7072L7.00008 8.41429Z'
      fill='currentColor'
    />
  </svg>

);

export default Close;
