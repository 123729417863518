import { API_RESOURCE, API_ROOT, API_VERSION } from '../../../config/constants/api';

import AbstractApiResource from './abstract';

class FooterApiResource extends AbstractApiResource {
  constructor() {
    super(`${API_ROOT}${API_VERSION.V1}${API_RESOURCE.FOOTER}`);
  }

  get = (query: { cityId: number }) => this.urlFactory('', query);
}

export default FooterApiResource;
