import Image from 'next/image';
import React from 'react';

const LayoutMaintenance = () => (
  <div
    style={{
      padding: 24,
      opacity: '0.87',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 320,
    }}
  >
    <h1 style={{ textAlign: 'center' }}>Мы наводим красоту и скоро вернемся!</h1>
    <Image
      src='/static/imgs/maintenance.jpg'
      width='200'
      height='200'
      style={{ objectFit: 'contain', display: 'block', margin: '24px 0' }}
      alt=''
    />
  </div>
);

export default LayoutMaintenance;
