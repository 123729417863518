import { useCallback, useMemo } from 'react';

import { useAppStoreApi } from '../AppStoreProvider';
import useAppStore from '../useAppStore';

import { Cart } from './type';

const useAppCart = () => {
  const { setState } = useAppStoreApi();
  const cart = useAppStore((state) => state.cart);

  const setCart = useCallback(
    (cart: Cart) =>
      setState((state) => {
        state.cart = cart;
      }),
    [setState]
  );

  const setIsProductFetching = useCallback((productId: string, loadingState: boolean) => {
    setState((state) => {
      if (typeof state.cart.isProductFetching === "undefined") {
        state.cart.isProductFetching = {};
      }

      state.cart.isProductFetching[productId] = loadingState;
    })
  }, [setState])

  const setIsFetching = useCallback(
    (value: boolean) =>
      setState((state) => {
        state.cart.isFetching = value;
      }),
    [setState]
  );

  return useMemo(
    () => ({
      ...cart,
      setIsProductFetching,
      setIsFetching,
      setCart,
    }),
    [cart, setIsProductFetching, setIsFetching, setCart]
  );
};

export default useAppCart;
