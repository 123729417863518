import React from 'react';
import classnames from 'classnames';

import Button from '../Button';

import styles from './FormPlaceholder.module.css';

const FormPlaceholder = ({ actions, title, subtitle, description, button, isSpaced, className }) => {
  return (
    <div className={classnames(styles.formPlaceholder, { [styles.spaced]: isSpaced }, className)}>
      {actions}
      <div className={styles.formPlaceholderContent}>
        {title}
        {subtitle}
        {description}
        {button && (
          <Button className={styles.formPlaceholderButton} type='button' {...button}>
            {button?.title}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FormPlaceholder;
