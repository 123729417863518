import { useMemo } from 'react';

import { createAppStore } from '@/core';
import createUserStore from '@/core/model/user/store';

import { AppInitializeProps } from '../config/page/type';

const useAppStoreInitialize = (props: AppInitializeProps) => {
  const { session, cart: legacyCart } = props;

  const result = useMemo(() => {
    if (!session) {
      return createAppStore({ cart: legacyCart });
    }

    const { user } = session;

    const sessionUser = user?.authorized ? user : createUserStore();
    const update = { ...session, user: sessionUser };

    return createAppStore(update);
  }, [session, legacyCart]);

  return result;
};

export default useAppStoreInitialize;
