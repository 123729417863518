import React, { ReactNode } from 'react';
import { useAmp } from 'next/amp';
import dynamic from 'next/dynamic';
import Script from 'next/script';

const DynamicAmpAppStyles = dynamic(() => import('../../../components/AmpAppStyles'));

interface IAmpWrapProps {
  ampOnly?: ReactNode;
  nonAmp?: ReactNode;
}

function AmpWrap({ ampOnly, nonAmp }: IAmpWrapProps) {
  const isAmp = useAmp();

  if (ampOnly) {
    return !!isAmp && ampOnly;
  }

  return !isAmp && nonAmp;
}

const integrations = () => [
  <Script key='gtm' id='gtm-script' src='https://www.googletagmanager.com/gtm.js?id=GTM-TVR8MV&l=dataLayer' />,
  <Script key='mindbox' id='mindbox-tracker-script' src='https://api.mindbox.ru/scripts/v1/tracker.js' />,
];

const AppBootstrap = () => (
  <>
    <AmpWrap nonAmp={integrations()} />

    <AmpWrap ampOnly={<DynamicAmpAppStyles />} />
  </>
);

export default AppBootstrap;
