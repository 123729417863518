import getBaseDomain from 'helpers/getBaseDomain';
import { get } from 'lodash';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import { RedirectError } from 'types/errors';

import { getClientIp } from '@/application/lib';
import RegionApi from '@/core/api/region';
import { REGION_COOKIE } from '@/core/config/constants/api';
import { BASE_DOMAIN } from '@/core/lib';
import getBaseCookies from '@/core/lib/cookies';
import { toResponseError } from '@/shared/api/util';
import { isServerDebugKey } from '@/shared/lib/helpers/predicates';
import { identifyHost } from '@/shared/lib/helpers/url';

import { RegionFull } from './type';

interface DomainFromRegionProps {
  req: NextPageContext['req'];
  cookies: Record<string, string>;
  isPreventRedirect?: boolean;
}

class RegionService {
  readonly ctx: GetServerSidePropsContext;

  readonly api: RegionApi;

  public list?: RegionFull[];

  public value?: RegionFull | null = null;

  constructor(ctx: GetServerSidePropsContext) {
    this.ctx = ctx;
    this.api = new RegionApi(ctx);
  }

  getRegionFromCookies = (cookies: Record<string, string>) => {
    const parsedId = cookies?.[REGION_COOKIE.CURRENT_ID] || '';

    const currentRegionId = +parsedId;

    return this.list?.length ? this.list.find((r) => +r.id === +currentRegionId) : null;
  };

  getRegionFromSubdomain = (subdomain: string) => {
    if (!this.list?.length) {
      throw new Error('Regions list is empty');
    }

    return this.list.find((r) => r.subdomain === subdomain);
  };

  getByIp = async (ip: string) => {
    const { id } = await this.api.getByIp(ip);

    const result = await this.api.getFullRegionById(+id);

    this.value = result;

    return result;
  };

  getDomainFromRegion = (props: DomainFromRegionProps) => {
    const { req, cookies, isPreventRedirect } = props;

    if (!req) {
      throw new Error('Request is empty');
    }

    if (!this.list?.length) {
      throw new Error('Regions list is empty');
    }

    const regionFromCookies = this.getRegionFromCookies(cookies);

    const { isRegionConfirmationShowed, currentRegionId } = cookies;
    const { host, subdomain } = identifyHost(req) ?? {};
    const baseDomain = (host ? getBaseDomain({ regions: this.list, host }) : BASE_DOMAIN) as string;

    const url = req ? new URL(req?.url ?? '', `https://${host}`) : null;
    const asPath = url
      ? `${decodeURIComponent(url.pathname)}${decodeURIComponent(url.search)}${decodeURIComponent(url.hash)}`
      : '';
    const targetHostname = `${regionFromCookies?.subdomain ? `${regionFromCookies.subdomain}.` : ''}${baseDomain}`;

    const isRedirectError =
      !isPreventRedirect && req.headers.host !== targetHostname && isRegionConfirmationShowed && currentRegionId;

    if (isRedirectError) {
      console.error('[application][model][RegionService][getDomainFromRegion][isRedirectError]', {
        isRegionConfirmationShowed,
        currentRegionId,
        isPreventRedirect,
        headers: req.headers,
        host,
        targetHostname,
      });

      throw new RedirectError({
        destination: `https://${targetHostname}${asPath || ''}`,
        permanent: false,
      });
    }

    return {
      baseDomain,
      subdomain,
      asPath,
    };
  };

  getRegion = async (ctx: GetServerSidePropsContext, cookies: Record<string, any>) => {
    try {
      const { req, query } = ctx;
      if (!this.list?.length) {
        throw new Error('Regions list is empty');
      }

      const isPreventRedirect = !!get(ctx.query, 'isPreventRedirect', false);
      const regionFromCookies = this.getRegionFromCookies(cookies);
      const { subdomain } = this.getDomainFromRegion({ req: ctx.req, cookies, isPreventRedirect }) ?? {};

      const regionFromSubdomain = subdomain ? this.getRegionFromSubdomain('stage') : null;

      const region: RegionFull | null = regionFromSubdomain || regionFromCookies || null;

      if (isServerDebugKey(query, 'region')) {
        console.log('[application][model][RegionService][getRegion]', {
          subdomain,
          regionFromSubdomain,
          regionFromCookies,
          region,
        });
      }

      if (!region) {
        const ip = getClientIp(req);
        return this.getByIp(ip);
      }

      const { id } = region;

      const result = await this.api.getFullRegionById(+id);

      this.value = {...result, ...region};

      return result;
    } catch (error: unknown) {
      console.error('[application][model][RegionService][getRegion]', error);

      throw toResponseError(error);
    }
  };

  getList = async () => {
    try {
      const result = await this.api.getList();
      this.list = result;
      return result;
    } catch (error) {
      throw toResponseError(error);
    }
  };

  initialize = async ({
    ctx,
    regionsCache,
    onRegionsLoaded,
  }: {
    ctx: GetServerSidePropsContext,
    regionsCache: { regions: any[], expires: number } | null,
    onRegionsLoaded?: ({ regions }: { regions: any[] }) => Promise<void>,
  }) => {
    if (!this.list?.length) {
      if (regionsCache?.regions?.length && regionsCache?.expires > Date.now()) {
        this.list = regionsCache?.regions;
      } else {
        await this.getList();

        if (this.list?.length) {
          onRegionsLoaded?.({ regions: this.list });
        }
      }
    }

    await this.getRegion(ctx, getBaseCookies(ctx));
  };
}

export default RegionService;
