import { NextPageContext } from 'next';

export const getClientIp = (req: NextPageContext['req']): string => {
  const result = req?.headers?.['x-real-ip'] || req?.headers?.['x-forwarded-for'] || req?.socket?.remoteAddress || '';

  return result as string;
};

export const getUserAgent = (req: NextPageContext['req']): string | void =>
  req ? req.headers['user-agent'] : global?.navigator?.userAgent;
