import { AppUserStore } from '../type';

const DEF_VALUE: AppUserStore = {
  authorized: false,
  favorites: [],
};

const createUserStore = () => ({
  ...DEF_VALUE,
});

export default createUserStore;
