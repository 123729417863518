import { connect } from 'react-redux';

import HeaderContent from './HeaderContent';

import { actionCreators as modalsActionCreators } from '../../../../redux/reducers/modal';
import { isSearchFetchingSelector } from '../../../../redux/reducers/search/selectors';


const mapStateToProps = (state, props) => ({
  isSearchFetching: isSearchFetchingSelector(state),
});

const mapDispatchToProps = {
  showModal: modalsActionCreators.showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContent);
