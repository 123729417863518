/* eslint-disable no-return-await */
import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosStatic,
  RawAxiosRequestHeaders,
} from 'axios';

import { isClientDebug } from '../lib/helpers/predicates';

import { transformAxiosError } from './util';

export type ApiClientConfig = Partial<{
  baseUrl: string;
  headers: RawAxiosRequestHeaders | AxiosHeaders;
  withCredentials: boolean;
  timeout: number;
}>;

class ApiClient {
  readonly client: AxiosStatic;

  readonly config: ApiClientConfig = {};

  constructor(config: ApiClientConfig = {}) {
    this.client = axios;
    this.config = config;
  }

  public getUrl = (url: string): string => {
    const { baseUrl } = this.config;

    if (baseUrl) {
      return `${baseUrl}${url}`;
    }

    return url;
  };

  public getHeaders = (headers?: RawAxiosRequestHeaders | AxiosHeaders): RawAxiosRequestHeaders | AxiosHeaders => {
    const { headers: configHeaders = {} } = this.config;

    return { ...configHeaders, ...(headers ?? {}) };
  };

  getConfig = <D>(config?: AxiosRequestConfig<D>) => {
    if (!config) {
      return this.config;
    }

    const { headers, ...rest } = config;

    return {
      ...rest,
      ...this.config,
      headers: this.getHeaders(headers),
    };
  };

  // eslint-disable-next-line max-len
  public processRequest = async <T = any>(request: Promise<AxiosResponse<T>>, headers: any): Promise<T> => {
    try {
      const response = await request;

      if ((response?.data as any)?.data) {
        (response.data as any).data.headers = headers;
      }

      return response.data;
    } catch (error: unknown) {
      throw transformAxiosError(error as AxiosError);
    }
  };

  public fetchQuery = async <T = any, D = any>(fetchOptions: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> => {
    try {
      const { url: endpoint, ...rest } = fetchOptions;

      const url = this.getUrl(endpoint || '');
      const config = this.getConfig({ ...rest, url });

      const result = await this.client(config);

      if (isClientDebug() || process.env.NEXT_PUBLIC_IS_DEBUG) {
        console.log(`[api][client][fetchQuery][request]`, { config });
        console.log(`[api][client][fetchQuery][response]`, { config, response: result });
      }
      return result;
    } catch (error: unknown) {
      throw transformAxiosError(error as AxiosError);
    }
  };

  public get = async <T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> => {
    const queryConfig = this.getConfig(config);

    return await this.client.get(this.getUrl(url), queryConfig);
  };

  public delete = async <T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> => {
    const queryConfig = this.getConfig(config);

    return await this.client.delete(this.getUrl(url), queryConfig);
  }

  public head = async <T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> =>
    await this.client.head(this.getUrl(url), config);

  public options = async <T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> =>
    await this.client.options(this.getUrl(url), config);

  public post = async <T = any, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<AxiosResponse<T>> => {
    const queryConfig = this.getConfig(config);

    return await this.client.post(this.getUrl(url), data, queryConfig);
  };

  public put = async <T = any, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<AxiosResponse<T>> => await this.client.put(this.getUrl(url), data, config);

  public patch = async <T = any, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<AxiosResponse<T>> => await this.client.patch(this.getUrl(url), data, config);

  public postForm = async <T = any, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<AxiosResponse<T>> => await this.client.postForm(this.getUrl(url), data, config);

  public putForm = async <T = any, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<AxiosResponse<T>> => await this.client.putForm(this.getUrl(url), data, config);

  public patchForm = async <T = any, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<AxiosResponse<T>> => await this.client.patchForm(this.getUrl(url), data, config);
}

export default ApiClient;
