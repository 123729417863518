const ACTION_HANDLERS = {

  GET_USER_ORDERS_LIST_SUCCESS: (state, action) => {
    return Object.assign({}, state, {
      userOrdersList: action.responseJSON,
    });
  },
  LOGIN_USER_SUCCESS: (state, action) => {
    return Object.assign({}, state, {
      userData: action.responseJSON,
      userAuthorized: true,
    });
  },
  LOGOUT_USER_SUCCESS: (state) => {
    return Object.assign({}, state, {
      userData: {},
      userAuthorized: false,
    });
  },
  CHANGE_USER_DATA_SUCCESS: (state, action) => {
    return Object.assign({}, state, {
      userData: action.responseJSON,
    });
  },
  SET_IS_ENTER_ERROR: (state, action) => {
    return Object.assign({}, state, {
      isEnterError: action.isEnterError,
    });
  },
};

const initialState = {
  userAuthorized: false,
  userOrdersList: [],
  userData: null,
  isEnterError: false,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
