import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

import { isClient } from '../lib/helpers/predicates';

import useRefCallback, { RefCallbackData, RefElement } from './useRefCallback';

type ScrollHandler = (evt: Event) => void;

type ScrollPosition = [number, number];

type ScrollData = [(node: HTMLElement) => void, ScrollPosition];

interface UseSyncScrollProps {
  target: RefElement<HTMLElement>;
  active?: boolean;
  onScroll?: (evt: Event) => void;
}

interface UseScrollHandlerProps {
  onScroll: ScrollHandler;
  root?: MutableRefObject<HTMLElement | null> | Document | HTMLElement | null;
  delay?: number;
}

const DEF_BOX = [0, 0] as ScrollPosition;

const DEF_DELAY = 5;

export const useScrollHandler = ({ onScroll, root, delay }: UseScrollHandlerProps): RefCallbackData<HTMLElement> => {
  const [setRef, ref, isReady, clearRef] = useRefCallback<HTMLElement>();

  const safeTarget = isClient ? window : null;
  const target = (root ?? ref?.current ?? safeTarget) as Document | HTMLElement;

  useEffect(() => {
    const scrollHandler = (evt: Event) => {
      if (!target) {
        return;
      }

      const throttledOnScroll = throttle(() => {
        onScroll?.(evt);
      }, delay ?? DEF_DELAY);

      throttledOnScroll();
    };

    const clearScroll = () => {
      if (!target) {
        return;
      }

      target.removeEventListener('scroll', scrollHandler as unknown as EventListener);
    };

    const bindScroll = () => {
      if (!target) {
        return;
      }

      clearScroll();

      target?.addEventListener('scroll', scrollHandler as unknown as EventListener);
    };

    bindScroll();

    return () => clearScroll();
  }, [target, delay, isReady, onScroll]);

  return [setRef, ref, isReady, clearRef];
};

export const useScrollPosition = (): ScrollData => {
  const [scroll, setScroll] = useState<ScrollPosition>(DEF_BOX);

  const onScroll = useCallback<(evt: Event) => void>((evt) => {
    if (!evt) {
      return;
    }

    const target = evt.target as HTMLElement;

    setScroll([target.scrollLeft, target.scrollTop]);
  }, []);

  const [setRef] = useScrollHandler({ onScroll });

  return [setRef, scroll];
};

export const useSyncScroll = (props: UseSyncScrollProps) => {
  const { target, active, onScroll } = props;

  const root = isClient ? document : null;

  const scrollHandler = useCallback(
    (evt: UIEvent) => {
      if (!target.current || !active || !evt.target) {
        return;
      }

      const { documentElement } = evt.target as Document;

      const value = documentElement.scrollTop;

      target.current.scroll({ top: value });

      onScroll?.(evt);
    },
    [target, active, onScroll]
  );

  useScrollHandler({ onScroll: scrollHandler, root });
};
