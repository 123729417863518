import { transformTreeToFlatObject } from 'redux/helpers/menu';

import MenuApi from '@/core/api/menu';
import { toResponseError } from '@/shared/api/util';

import { MenuCategory } from '../type/menu';

const api = new MenuApi();

class AppMenu {
  public categories: Record<string, MenuCategory> | null;

  public codes: string[];

  constructor() {
    this.categories = null;
    this.codes = [];
  }

  normalize = (categories: MenuCategory[]): Record<string, MenuCategory> => transformTreeToFlatObject(categories);

  request = async () => {
    try {
      const result = await api.get();

      return result;
    } catch (error) {
      console.error('[menu][model][request]', { error });

      throw toResponseError(error);
    }
  };

  mapCodes = (categories: MenuCategory[]) => categories.map((item) => item.code);

  initialize = async () => {
    const result = await this.request();

    this.categories = this.normalize(result);
    this.codes = this.mapCodes(result);

    return {
      categories: this.categories,
      codes: this.codes,
    };
  };
}

export default AppMenu;
