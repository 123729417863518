import { RegionShort } from '../type';

const DEF_VALUE: RegionShort = {
  id: 28,
  name: 'Москва и обл.',
  coordinates: '55.7540471,37.620405',
};

export type RegionStore = RegionShort;

const createRegionStore = () => ({ ...DEF_VALUE });

export default createRegionStore;
