import { useEffect } from 'react';
import { useRouter } from 'next/router';

const useRouteEvent = ({ onStart, onEnd }) => {
  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeStart', onStart);
    router.events.on('routeChangeComplete', onEnd);
    router.events.on('routeChangeError', onEnd);

    return () => {
      router.events.off('routeChangeStart', onStart);
      router.events.off('routeChangeComplete', onEnd);
      router.events.off('routeChangeError', onEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);
};

export default useRouteEvent;
