import React from 'react';

import styles from './Bar.module.css';

const Bar = ({ progress, animationDuration }) => (
  <div
    className={styles.bar}
    style={{
      marginLeft: `${(-1 + progress) * 100}%`,
      transitionDuration: `${animationDuration}ms`,
    }}
  >
    <div className={styles.peg} />
  </div>
);

export default Bar;
