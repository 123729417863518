import Redirect from '@/core/model/redirects/type';
import { toResponseError } from '@/shared/api/util';

import BaseAppRequest from '../utils/client';
import ApiResource from '../utils/endpoint';

class RedirectsApi {
  readonly client: BaseAppRequest;

  constructor() {
    this.client = new BaseAppRequest();
  }

  get = async () => {
    const url = ApiResource.redirects.get();

    try {
      const { data } = await this.client.get<Redirect[]>(url);
      return data;
    } catch (error) {
      console.error('[redirects][api][get]', { url, error });
      throw toResponseError(error);
    }
  };
}

export default RedirectsApi;
