import { GetServerSidePropsContext } from 'next';

import { BaseAppRequest } from '@/core/api/utils/client';
import ApiResource from '@/core/api/utils/endpoint';
import { toResponseError } from '@/shared/api/util';

class ShopsApi {
  readonly client: BaseAppRequest;

  constructor(ctx?: GetServerSidePropsContext) {
    this.client = new BaseAppRequest(ctx);
  }

  getPage = async () => {
    const url = ApiResource.shops.get();

    try {
      const { data } = await this.client.get<any>(url);
      return data;
    } catch (error) {
      console.error('[delivery][api][getPage]', { url, error });
      throw toResponseError(error);
    }
  };
}

export default ShopsApi;
