import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createStore } from 'zustand/vanilla';

import { Id } from '@/shared/types';

import createRegionStore from '../model/region/store';
import createUserStore from '../model/user/store';

import createCartStore from './cart';
import createClientStore from './client';
import createDictionaryStore from './dictionary';
import createLayoutDataStore from './layout-data';
import createPortalStore from './portals';
import createAppSettingStore from './settings';
import { GlobalAppStore } from './type';
import createUiStore from './ui';

const createAppStore = (initial?: Partial<GlobalAppStore>) => createStore<GlobalAppStore>()(
    immer(
      persist(
        () => ({
          cart: createCartStore(),
          client: createClientStore(),
          dictionary: createDictionaryStore(),
          layoutData: createLayoutDataStore(),
          portals: createPortalStore(),
          region: createRegionStore(),
          settings: createAppSettingStore(),
          ui: createUiStore(),
          user: createUserStore(),
          ...(initial ?? {}),
        }),
        {
          name: 'favorites',
          partialize: (state) => state.user.favorites,
          merge: (persisted: Id[], persistInitial) => {
            const { user } = persistInitial;

            let favoriteList: number[] = [];
            if (user.authorized) {
              favoriteList = user.favorites.map(Number);
            } else {
              favoriteList = persisted.map(Number);
            }

            return {
              ...persistInitial,
              user: { ...user, favorites: Array.from(new Set(favoriteList ?? [])) }
            };
          },
        }
      )
    )
  );

export default createAppStore;
