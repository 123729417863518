import React from 'react';

import classnames from 'classnames';
import Typography from '../Typography';

import styles from './HowToFitBanner.module.css';


export default function HowToFitBanner({ className, mode }) {
  return (
    <div className={classnames(styles.layout, className, { [styles.column]: mode === 'column' })}>
      <Typography variant='h5' bold component='div'>
        Как воспользоваться фотопримеркой
      </Typography>

      <div className={styles.items}>
        <div className={styles.item}>
          <Typography className={styles.itemTitle} variant='body1'>
            В карточке товара нажмите синюю кнопку «Фотопримерка в 3 клика».
          </Typography>
        </div>

        <div className={styles.item}>
          <Typography className={styles.itemTitle} variant='body1'>
            Загрузите фото интерьера, система сама положит в него ковёр.
          </Typography>
        </div>

        <div className={styles.item}>
          <Typography className={styles.itemTitle} variant='body1'>
            Скачайте фото и поделитесь с&nbsp;близкими!
          </Typography>
        </div>

      </div>
    </div>
  );
}
