import { APP_SECTION, SECTION_TO_ROUTE } from '@/core/config/constants/routes';
import useSectionRoute, { createRouteUrl, RouteItem } from '@/shared/hooks/useSectionRoute';

const BASE_ROUTE = SECTION_TO_ROUTE[APP_SECTION.FAVORITES];

const GET_FAVORITES_ROUTE = {
  ROOT: () => createRouteUrl(BASE_ROUTE),
} as const;

const FAVORITES_SECTION = {
  section: APP_SECTION.FAVORITES,
  getUrl: GET_FAVORITES_ROUTE.ROOT,
};

const useFavoriteSectionRoute = () => useSectionRoute(FAVORITES_SECTION) as RouteItem;

export default useFavoriteSectionRoute;
