import { configureStore } from '@reduxjs/toolkit';
import debug from 'debug';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import createReduxPromiseListener from 'redux-promise-listener';
import thunk from 'redux-thunk';

import { isDevDebugMode } from '@/shared/lib/helpers/predicates';

import callAPIMiddleware from '../middlewares/callApi';
import cabinet from '../reducers/cabinet';
import favorites from '../reducers/favorites';
import modal from '../reducers/modal';
import popups from '../reducers/popups';
import search from '../reducers/search';
import transport from '../reducers/transport';

import rootEpic from './epics';

const reduxPromiseListener = createReduxPromiseListener();
const logger = createLogger({ collapsed: true });
const epicMiddleware = createEpicMiddleware({});

const getMiddleware = () => {
  const middleware = [thunk, callAPIMiddleware, epicMiddleware, reduxPromiseListener.middleware];

  if (isDevDebugMode()) {
    debug.enable('*');
  }

  if (isDevDebugMode()) {
    middleware.push(logger);
  }

  return middleware;
};

export const initStore = (context) => {
  const middleware = getMiddleware();

  const store = configureStore({
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: {
          extraArgument: { context },
        },
      }).concat(middleware),
    reducer: {
      cabinet,
      favorites,
      popups,
      search,
      transport,
      modal,
    },
  });

  epicMiddleware.run(rootEpic);

  return store;
};

export const promiseListener = reduxPromiseListener;

export default initStore;
