import getRequest from './getRequest';

export default async function getAllArticles({ ctx, cityId } = {}) {
  const params = {};

  if(cityId) {
    params.cityId = cityId;
  }

  const response = await getRequest({
    url: '/blog',
    method: 'get',
    ctx,
  }, { params });

  return response?.data;
}
