import React, { useState } from 'react';

import classnames from 'classnames';

import Typography from '../Typography';

import Button from '../../controls/Button';

import styles from './PromocodePopup.module.css';


export default function PromocodePopup({
  className, isPromocodePopupShow, promocode, hidePromocodePopup,
}) {
  const [isPromocodeCopied, setIsPromocodeCopied] = useState(false);

  const handleClose = () => {
    hidePromocodePopup();
  };

  const handleCopy = (e) => {
    const el = document.createElement('textarea');
    el.value = promocode;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setIsPromocodeCopied(true);

    e.stopPropagation();

    setTimeout(() => setIsPromocodeCopied(false), 10000);
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return !!isPromocodePopupShow && (
    <div className={classnames(styles.layout, className)} onClick={handleClose}>
      <div className={styles.content} onClick={handleContentClick}>
        <img src='/static/imgs/popups/check.jpg' alt='' loading='lazy' />

        <Typography variant='h1' component='span' className={styles.title}>Ваша заявка принята</Typography>
        <Typography variant='body1' component='p' color='secondary' className={styles.description}>С вами свяжется оператор для уточнения деталей заказа.</Typography>
        <Typography variant='body1' component='p' color='secondary' className={styles.hint}>Ваш промокод на скидку*</Typography>
        <Typography variant='h4' component='p' bold className={styles.promocode}>{promocode}</Typography>
        <Button className={styles.button} onClick={handleCopy} medium>{isPromocodeCopied ? 'Промокод скопирован' : 'Скопировать промокод'}</Button>
        <Typography variant='body3' component='p' color='secondary' className={styles.terms}>* Промокод действует на ковры стоимостью от 30 000 рублей, срок действия промокода 3 дня с момента выдачи</Typography>

        <Button className={styles.close} text onClick={handleClose}>
          <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M3.70711 2.29289C3.31658 1.90237 2.68342 1.90237 2.29289 2.29289C1.90237 2.68342 1.90237 3.31658 2.29289 3.70711L14.5858 16L2.29289 28.2929C1.90237 28.6834 1.90237 29.3166 2.29289 29.7071C2.68342 30.0976 3.31658 30.0976 3.70711 29.7071L16 17.4142L28.2929 29.7071C28.6834 30.0976 29.3166 30.0976 29.7071 29.7071C30.0976 29.3166 30.0976 28.6834 29.7071 28.2929L17.4142 16L29.7071 3.70711C30.0976 3.31658 30.0976 2.68342 29.7071 2.29289C29.3166 1.90237 28.6834 1.90237 28.2929 2.29289L16 14.5858L3.70711 2.29289Z' fill='#C0AB87' />
          </svg>
        </Button>
      </div>
    </div>
  );
}
