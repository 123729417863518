import { handleActions } from 'redux-actions';

import {
  showProductShowPopup,
  hideProductShowPopup,
} from './actions';


const initialState = {
  promocode: null,
  isPromocodePopupShow: false,
  isPromocodeFetching: false,
  isProductShowFetching: false,
  isProductShowPopupShow: false,
  isPreorderPopupShow: false,
};

export default handleActions({
  [showProductShowPopup]: state => ({
    ...state,
    isProductShowPopupShow: true,
  }),

  [hideProductShowPopup]: state => ({
    ...state,
    isProductShowPopupShow: false,
  }),

}, initialState);
