/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { isFalsyOrEmpty } from '../predicates';

const DEF_FILTER_FN = (entry: [string, any]) => {
  const [, value] = entry ?? [];
  return !isFalsyOrEmpty(value);
};

export const isNotEmptyObject = (value: unknown) => typeof value === 'object' && value !== null;

export const filterObjectValues = (value: Record<string, any>, filterFn: (value: any) => boolean = DEF_FILTER_FN) =>
  Object.entries(value).reduce((acc: Record<string, any>, entry: [string, any]) => {
    const [key, value] = entry ?? [];

    if (!filterFn(entry)) {
      return acc;
    }

    acc[key] = value;

    return acc;
  }, {});
