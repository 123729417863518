import { APP_SECTION, SECTION_TO_ROUTE } from '@/core/config/constants/routes';
import useSectionRoute, { createRouteUrl, RouteItem } from '@/shared/hooks/useSectionRoute';

const PAGE_NAME = {
  CARD: 'card',
} as const;

type PageName = typeof PAGE_NAME[keyof typeof PAGE_NAME];

const BASE_ROUTE = SECTION_TO_ROUTE[APP_SECTION.MODELI];

const GET_MODELI_ROUTE = {
  ROOT: (query?: Record<string, any>) => createRouteUrl(BASE_ROUTE, query),
  CARD: (id: string) => createRouteUrl(`${BASE_ROUTE}/${id}`),
} as const;

const MODEL_SECTION = {
  section: APP_SECTION.MODELI,
  getUrl: GET_MODELI_ROUTE.ROOT,
  page: {
    [PAGE_NAME.CARD]: GET_MODELI_ROUTE.CARD,
  },
};

const useModeliSectionRoute = () => useSectionRoute<PageName>(MODEL_SECTION) as RouteItem<PageName>;

export default useModeliSectionRoute;
