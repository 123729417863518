import { NEXT_PUBLIC_API_ROOT } from 'cfg';

import { API_RESOURCE, API_VERSION } from '../../../config/constants/api';

import AbstractApiResource from './abstract';

class ContactsApiResource extends AbstractApiResource {
  constructor() {
    super(`${NEXT_PUBLIC_API_ROOT}${API_VERSION.V1}${API_RESOURCE.CONTACTS}`);
  }

  get = (url?: string, query?: Record<string, any>) => {
    if (!url) {
      return this.urlFactory('', query);
    }

    return this.urlFactory(url, query);
  };
}

export default ContactsApiResource;