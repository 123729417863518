import React from 'react';

import classnames from 'classnames';

import Button from '../../controls/Button';
import ProductShowForm from '../ProductShowForm';

import styles from './ProductShowPopup.module.css';
import useActiveOffer from '../../hooks/useActiveOffer';


export default function ProductShowPopup({
  className, isProductShowPopupShow, product, hideProductShowPopup,
}) {
  const activeOffer = useActiveOffer({ product });
  const handleClose = () => {
    hideProductShowPopup();
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return !!isProductShowPopupShow && (
    <div className={classnames(styles.layout, className)} role='button' tabIndex='0' onClick={handleClose}>
      <div className={styles.content} role='button' tabIndex='0' onClick={handleContentClick}>
        <ProductShowForm activeOffer={activeOffer} theme='mini' onSubmit={handleClose} />

        <Button className={styles.close} text onClick={handleClose}>
          <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M3.70711 2.29289C3.31658 1.90237 2.68342 1.90237 2.29289 2.29289C1.90237 2.68342 1.90237 3.31658 2.29289 3.70711L14.5858 16L2.29289 28.2929C1.90237 28.6834 1.90237 29.3166 2.29289 29.7071C2.68342 30.0976 3.31658 30.0976 3.70711 29.7071L16 17.4142L28.2929 29.7071C28.6834 30.0976 29.3166 30.0976 29.7071 29.7071C30.0976 29.3166 30.0976 28.6834 29.7071 28.2929L17.4142 16L29.7071 3.70711C30.0976 3.31658 30.0976 2.68342 29.7071 2.29289C29.3166 1.90237 28.6834 1.90237 28.2929 2.29289L16 14.5858L3.70711 2.29289Z' fill='#C0AB87' />
          </svg>
        </Button>
      </div>
    </div>
  );
}
