import { APP_SECTION, SECTION_TO_ROUTE } from '@/core/config/constants/routes';
import useSectionRoute, { createRouteUrl, RouteItem } from '@/shared/hooks/useSectionRoute';

export interface ProductRouteGetterProps {
  id: string;
  splat?: string[];
  query?: Record<string, any>;
}

const BASE_ROUTE = SECTION_TO_ROUTE[APP_SECTION.PRODUCT];

const GET_PRODUCT_ROUTE = {
  ROOT: (params: ProductRouteGetterProps) => {
    const { id, splat, query } = params ?? {};
    const url = Array.isArray(splat) && splat?.length ? `${id}/${splat.join('/')}` : id;

    return createRouteUrl(`${BASE_ROUTE}/${url}`, query);
  },
} as const;

const PRODUCT_SECTION = {
  section: APP_SECTION.PRODUCT,
  getUrl: GET_PRODUCT_ROUTE.ROOT,
  replace: true,
};

const useProductSectionRoute = () => useSectionRoute(PRODUCT_SECTION) as RouteItem;

export default useProductSectionRoute;
