import { API_ROOT, API_VERSION } from '../../../config/constants/api';

import AbstractApiResource from './abstract';

class CartApiResource extends AbstractApiResource {
  constructor() {
    super(`${API_ROOT}${API_VERSION.V1}`);
  }

  get = (url: string, query?: Record<string, any>) => this.urlFactory(url, query);
}

export default CartApiResource;
