import React from 'react';

import styles from './Container.module.css';

const Container = ({ children, isFinished }) => (
  <div
    className={styles.container}
    style={{
      display: isFinished ? 'none' : 'flex',
    }}
  >
    {children}
  </div>
);

export default Container;
