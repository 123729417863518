import React from 'react';


export const TempContext = React.createContext(false);
export const PagePropsContext = React.createContext(null);
export const DefaultWidthContext = React.createContext(1280);
export const InfoContext = React.createContext(null);
export const UserAgentContext = React.createContext({});
export const BannerObserverContext = React.createContext(null);
export const ProductItemObserverContext = React.createContext(null);
