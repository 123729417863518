import { connect } from 'react-redux';

import HeaderHints from './HeaderHints';

import { actionCreators as modalsActionCreators } from '../../../../redux/reducers/modal';
import { shopsCountSelector, currentShopsCountSelector } from '../../../../redux/reducers/info/selectors';


const mapStateToProps = (state, props) => ({
  shopsCount: shopsCountSelector(state, props),
  currentShopsCount: currentShopsCountSelector(state, props),
});

const mapDispatchToProps = {
  showModal: modalsActionCreators.showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderHints);
