import axios from 'axios';
import * as AxiosLogger from 'axios-logger';
import { setGlobalConfig } from 'axios-logger';
import { FORCE_BASIC_AUTH } from 'cfg';
import debug from 'debug';
import { parseCookies } from 'nookies';
import setCookieParser from 'set-cookie-parser';

import cookieNames from '../../constants/cookies';
import { NotFoundError, RedirectError } from '../../types/errors';

setGlobalConfig({
  dateFormat: 'HH:MM:ss:l',
  data: false,
});

const getCookieString = (cookies) => {
  const reducer = (acc, currVal) => `${acc}${currVal}=${cookies[currVal]};`;

  return Object.keys(cookies).reduce(reducer, '');
};

const initializeAxiosInstance = ({ ctx, isNewApi, isNotFoundEnabled = true } = {}) => {
  const isServer = typeof global?.window === 'undefined';
  const isDebug = process.env.NODE_ENV === 'development' || false;

  if (isDebug) {
    debug.enable('*');
  }

  const headers = {
    'content-type': 'application/json',
  };

  if (!process.env.NEXT_PUBLIC_IS_DEBUG && isServer) {
    const { req } = ctx || {};

    if (req) {
      // remove fucking matomo's broken cookies
      // const cookiesRegexpPrivate = /^_/g;
      const cookiesRegexpMatomo = /^_pk_/g;
      const cookiesRegexpExp = /^experiment-/g;
      const cookiesWhitelist = [
        'PHPSESSID',
        'fuser',
        'session',
        cookieNames.isRegionConfirmationShowed,
        cookieNames.currentRegionId,
        cookieNames.coupon,
        'deviceUUID',
        'mindboxDeviceUUID',
        'gclid',
        '_ga',
        cookieNames.isSalesMan,
        'feature:decorators',
      ];

      let cookie = parseCookies(ctx) || {};

      if (typeof cookie === 'object' && Object.keys(cookie).length) {
        cookie = Object.keys(cookie)
          .filter(
            (key) =>
              !cookiesRegexpMatomo.test(key) && (cookiesRegexpExp.test(key) || cookiesWhitelist.find((x) => x === key))
          )
          .reduce((reducer, key) => ({ ...reducer, [key]: cookie[key] }), {});
      }

      const ifModifiedSince = req.headers['if-modified-since'] || null;
      const ip = req.headers['x-real-ip'] || req.headers['x-forwarded-for'] || req.ip || null;
      const userAgent = req ? req.headers['user-agent'] : global.navigator.userAgent;

      if (cookie) {
        headers.Cookie = getCookieString(cookie);
      }

      if (ip) {
        headers['X-FRONTEND-IP'] = ip;
      }

      if (ifModifiedSince) {
        headers['X-IF-MODIFIED-SINCE'] = ifModifiedSince;
      }

      if (userAgent) {
        headers['X-FRONTEND-USERAGENT'] = userAgent;
      }
    }
  }

  const passAuthorization =
    FORCE_BASIC_AUTH ||
    ((process.env.NODE_ENV === 'development' || process.env.NEXT_PUBLIC_IS_DEBUG) && !headers.Authorization);

  if (passAuthorization) {
    headers.Authorization = 'Basic a292ZXI6a292ZXI=';
  }

  if (isDebug) {
    console.log('Set headers', headers);
  }

  const envBaseURL = process.env.NEXT_PUBLIC_API_ROOT;

  const baseURL = `${envBaseURL.replace('/rest', '')}${isNewApi ? '/' : '/rest'}`;

  const instance = axios.create({
    baseURL,
    timeout: 30000,
    headers,
    withCredentials: process.env.NODE_ENV === 'production',
  });

  if (isDebug || process.env.NEXT_PUBLIC_IS_DEBUG) {
    instance.interceptors.request.use(AxiosLogger.requestLogger, AxiosLogger.errorLogger);
    instance.interceptors.response.use(AxiosLogger.responseLogger, AxiosLogger.errorLogger);
  }

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      try {
        if (ctx && !ctx?.res?.headersSent) {
          const cookies = setCookieParser.parse(response.headers['set-cookie']);

          if (cookies?.length) {
            cookies.forEach((cookie) => {
              const {
                name,
                // value,
                // ...cookieParams
              } = cookie;

              ctx.res.serverCookies = {
                [name]: cookie,
                ...(ctx.res?.serverCookies ?? {}),
              };
              // setCookie(ctx, name, value, cookieParams);
            });
          }

          const { req } = ctx || {};

          const ifModifiedSince = req.headers['if-modified-since'] || null;

          if (ifModifiedSince && response.status === 304) {
            ctx.res.statusCode = 304;
          }
        }
      } catch {
        return response;
      }

      return response;
    },
    (error) => {
      const { response } = error;

      if (isDebug) {
        console.log(response);
      }

      if (
        (response?.status === 302 ||
          response?.status === 301 ||
          response?.status === 307 ||
          response?.status === 308) &&
        response?.headers?.get('X-LOCATION')
      ) {
        return Promise.reject(
          new RedirectError({
            destination: response.headers.get('X-LOCATION'),
            permanent: response.status === 301 || response.status === 308,
          })
        );
      }

      if ((response?.status === 404 || response?.status === 400) && isNotFoundEnabled) {
        return Promise.reject(new NotFoundError());
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default initializeAxiosInstance;
