/* eslint-disable consistent-return */
import { MutableRefObject, RefObject, useEffect } from 'react';

interface UseResizeObserverProps {
  target?: MutableRefObject<HTMLElement> | RefObject<HTMLElement>;
  callback: (element: HTMLElement, entry: ResizeObserverEntry) => void;
  deps?: any[];
}

function useResizeObserver(props: UseResizeObserverProps) {
  const { target, callback, deps = [] } = props;

  useEffect(() => {
    const element = target?.current;

    if (!element) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      callback(element, entries[0]);
    });

    observer.observe(element);
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, ...deps]);

  return null;
}

export default useResizeObserver;
