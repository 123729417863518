/* eslint-disable max-classes-per-file */
export class NotFoundError extends Error {
  name = 'NotFoundError';
}

export class RedirectError extends Error {
  name = 'RedirectError';

  constructor(redirect) {
    super('Redirect was founded');

    this.redirect = redirect;
  }
}

// http://localhost:4000/blog/trendy-novinki-novosti/chistka-i-ukhod-za-kovrami/

export const processingApiErrors = (e) => {
  if (e instanceof RedirectError && e.redirect) {
    return { redirect: e.redirect };
  }

  if (e instanceof NotFoundError) {
    return { notFound: true };
  }

  throw e;
};
