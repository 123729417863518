import { RegionFull } from '@/core/model/region/type';
import { ColorItem } from '@/core/model/type/color';

export interface AppDictionaryStore {
  regions: RegionFull[];
  colors: ColorItem[];
}

const DEF_VALUE: AppDictionaryStore = {
  regions: [],
  colors: [],
};

const createDictionaryStore = () => ({
  ...DEF_VALUE,
});

export default createDictionaryStore;
