import { createUrl } from '@/shared/lib/helpers/url';

export type UrlFactory =
  | ((endpoint: string, query?: Record<string, any>) => string)
  | ((query?: Record<string, any>) => string);

abstract class AbstractApiResource {
  protected get: UrlFactory;

  readonly baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  readonly urlFactory = (url?: string, query?: Record<string, any>) => {
    const root = url ? `${this.baseUrl}${url}` : this.baseUrl;

    return createUrl(root, query);
  };
}

export default AbstractApiResource;
