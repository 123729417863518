import { combineEpics } from 'redux-observable';

import {
  deleteFavoritesRequestEpic,
  getFavoritesRequestEpic,
  setFavoritesRequestEpic,
} from '../reducers/favorites/epics';
import { getSearchRequestEpic } from '../reducers/search/epics';
import { sendFormRequestEpic } from '../reducers/transport/epics';

const rootEpic = (t, o, s) =>
  combineEpics(
    getFavoritesRequestEpic,
    setFavoritesRequestEpic,
    deleteFavoritesRequestEpic,

    getSearchRequestEpic,

    sendFormRequestEpic
  )(t, o, s);

export default rootEpic;
