import { connect } from 'react-redux';

import { isPromocodePopupShowSelector, promocodeSelector } from '../../redux/reducers/popups/selectors';
import { hidePromocodePopup } from '../../redux/reducers/popups/actions';

import PromocodePopup from './PromocodePopup';

const mapStateToProps = state => ({
  isPromocodePopupShow: isPromocodePopupShowSelector(state),
  promocode: promocodeSelector(state),
});

const mapDispatchToProps = {
  hidePromocodePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromocodePopup);
