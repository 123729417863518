import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useAmp } from 'next/amp';

import styles from './Button.module.css';

import getAmpClassName from '../../helpers/getAmpClassName';

const Button = (props) => {
  const {
    redesign,
    clickHandler,
    className,
    text,
    type,
    link,
    href,
    as,
    variant,
    prefetch,
    children,
    transparent,
    transparentWhite,
    shallow,
    inverse,
    highlight,
    isTurboMode,
    medium,
    widthMax,
    isExternalLink,
    wide,
    ...rest
  } = props;

  const baseUrl = process.env.NODE_ENV === 'production' ? 'https://kover.ru' : 'https://kover.ru';
  const isAmp = useAmp();
  const getClassName = getAmpClassName(styles, isAmp);

  const classNames = classnames(
    'customComponent',
    getClassName('customButton'),
    {
      [getClassName('redesign')]: redesign,
      [getClassName('solid')]: variant === 'solid',
      [getClassName('transparent')]: transparent,
      [getClassName('transparentWhite')]: transparentWhite,
      [getClassName('text')]: text,
      [getClassName('highlight')]: highlight,
      [getClassName('inverse')]: inverse,
      [getClassName('medium')]: medium,
      [getClassName('widthMax')]: widthMax,
      [getClassName('wide')]: wide,
    },
    className,
  );

  if (isTurboMode) {
    return (
      <button
        type='button'
        formAction={href?.startsWith('http') ? `${baseUrl}${href}` : href}
        data-background-color='#191E80'
        data-color='#ffffff'
        data-turbo={!href?.startsWith('http')}
        // data-primary='true'
      >
        {children}
      </button>
    );
  }

  if (isExternalLink) {
    return (
      <a className={classNames} href={link || href} {...rest}>
        {children}
      </a>
    );
  }

  if (link) {
    return (
      (<Link href={link} prefetch={prefetch} className={classNames} {...rest}>

        {children}

      </Link>)
    );
  }

  if (href) {
    return (
      (<Link
        href={href}
        shallow={shallow}
        prefetch={prefetch}
        className={classNames}
        {...rest}>

        {children}

      </Link>)
    );
  }


  return (
    <button
      type={type || 'button'}
      className={classnames(classNames)}
      onClick={() => {
        if (clickHandler) {
          clickHandler();
        }
      }}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  clickHandler: PropTypes.func,
  className: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  prefetch: PropTypes.bool,
  isTurboMode: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  prefetch: true,
};

export default Button;
