const getAmpClassName = (styles, isAmp) => (...className) => {
  if (!isAmp) {
    return className
      .map((item) => {
        if (typeof item !== 'string') {
          return '';
        }
        if (styles[item] === undefined) {
          return '';
        }
        return styles[item];
      })
      .join(' ');
  }
  return className
    .map((item) => {
      if (typeof item !== 'string') {
        return '';
      }
      return item;
    })
    .join(' ');
};

export default getAmpClassName;
