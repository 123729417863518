import { AppSettings } from '@/core/model/type/settings';

export type AppSettingStore = AppSettings;

const DEF_VALUE: AppSettings = {
  isSalesMan: 0,
  isDecoratorsEnabled: false,
  isRegionConfirmationShowed: false,
  isPreventRedirect: false,
  isDebug: false,
  isMobileForce: false,
  ifModifiedSince: '',
};

const createAppSettingStore = () => ({
  ...DEF_VALUE,
});

export default createAppSettingStore;
