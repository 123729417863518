/* eslint-disable react/forbid-prop-types */
import React, { useRef } from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import ClickOutside from '../../../ClickOutside';
import DropdownWrapper from '../DropdownWrapper';

import styles from './HeaderDropdownMenu.module.css';

const CatalogDropdown = dynamic(() => import('../CatalogDropdown'), { ssr: false });

const HeaderDropdownMenu = (props) => {
  const { name, isOpen, info, className, makeHandleCloseDropdown, onToggleDropdown } = props;

  const headerDropdownActionRef = useRef();

  const trackFirstLevelClick = () => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Главное меню',
      eventAction: 'Первый уровень',
      eventLabel: name,
    });
  };

  const handleClickOutside = () => {
    if (isOpen) {
      if (onToggleDropdown) {
        onToggleDropdown({ isOpen: false });
      }
    }
  };

  const handleCloseClick = () => {
    if (onToggleDropdown) {
      onToggleDropdown({ isOpen: false });
    }
  };

  const handleOpenClick = () => {
    trackFirstLevelClick();

    if (onToggleDropdown) {
      onToggleDropdown({ isOpen: !isOpen });
    }
  };

  const menuIcon = (
    <svg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13 1.42167C13 1.65455 12.8112 1.84334 12.5783 1.84334H1.42167C1.18879 1.84334 1 1.65455 1 1.42167C1 1.18879 1.18879 1 1.42167 1H12.5783C12.8112 1 13 1.18879 13 1.42167ZM13 5.5C13 5.73288 12.8112 5.92167 12.5783 5.92167H1.42167C1.18879 5.92167 1 5.73288 1 5.5C1 5.26712 1.18879 5.07833 1.42167 5.07833H12.5783C12.8112 5.07833 13 5.26712 13 5.5ZM13 9.57833C13 9.81121 12.8112 10 12.5783 10H1.42167C1.18879 10 1 9.81121 1 9.57833C1 9.34545 1.18879 9.15666 1.42167 9.15666H12.5783C12.8112 9.15666 13 9.34545 13 9.57833Z'
        fill='#D5C4A7'
        stroke='#D5C4A7'
        strokeWidth='0.5'
      />
    </svg>
  );

  const closeIcon = (
    <svg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13 11L1 1' stroke='#D5C4A7' strokeWidth='1' />
      <path d='M1 11L13 1' stroke='#D5C4A7' strokeWidth='1' />
    </svg>
  );

  const icon = isOpen ? closeIcon : menuIcon;

  const content = (
    <>
      <div className={styles.headerDropdownActionIcon}>{icon}</div>
      <span className={styles.headerDropdownActionIconTitle}>Каталог</span>
    </>
  );

  const action = (
    <button
      type='button'
      className={classnames(styles.headerDropdownActionTitle)}
      onClick={handleOpenClick}
      aria-label='Меню каталога'
    >
      {content}
    </button>
  );

  const renderDropdown = () => {
    if (!process.browser) {
      return null;
    }

    return <CatalogDropdown info={info} onCloseDropdown={makeHandleCloseDropdown('all')} />;
  };

  const renderedDropdown = renderDropdown();

  return (
    <ClickOutside
      wrapperRef={headerDropdownActionRef}
      handleClickOutside={handleClickOutside}
      className={classnames(
        styles.headerDropdownMenu,
        styles.normal,
        {
          [styles.active]: isOpen,
        },
        className
      )}
    >
      {action}

      <DropdownWrapper parentRef={headerDropdownActionRef} isOpen={isOpen} onCloseClick={handleCloseClick}>
        <div className={styles.dropdown}>{renderedDropdown}</div>
      </DropdownWrapper>
    </ClickOutside>
  );
};

HeaderDropdownMenu.propTypes = {
  name: PropTypes.string,
  isOpen: PropTypes.bool,
  region: PropTypes.object,
  info: PropTypes.object,
  productsCount: PropTypes.number,
  className: PropTypes.string,
  menuCategories: PropTypes.object.isRequired,
  makeHandleCloseDropdown: PropTypes.func,
  onToggleDropdown: PropTypes.func,
};

HeaderDropdownMenu.defaultProps = {
  name: null,
  isOpen: false,
  productsCount: null,
  info: null,
  className: null,
  makeHandleCloseDropdown: null,
  onToggleDropdown: null,
};

export default HeaderDropdownMenu;
