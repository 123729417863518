import { NextApiRequestCookies } from 'next/dist/server/api-utils';

// TODO: После рефакторинга лэйаута, убрать isPhone
export interface AppClient {
  host: string;
  domain: string;
  cookies: NextApiRequestCookies;
  userAgent: string;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isPhone: boolean;
}

const DEF_VALUE: AppClient = {
  host: '',
  domain: '',
  cookies: {},
  userAgent: '',
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  isPhone: false,
};

const createClientStore = () => ({
  ...DEF_VALUE,
});

export default createClientStore;
