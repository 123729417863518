import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import logo from '../../assets/imgs/logo.svg';
import SvgIcon from '../SvgIcon';

import styles from './Logo.module.css';

const Logo = ({ className }) => (
  <div className={classnames(styles.defaultLogo, className)}>
    <SvgIcon svg={logo} isAutoSizeDisabled />
  </div>
);

Logo.propTypes = {
  isAmp: PropTypes.bool,
  className: PropTypes.string,
};

Logo.defaultProps = {
  isAmp: false,
  className: null,
};

export default Logo;
