import { GetServerSidePropsContext } from 'next';

import UserApi from '@/core/api/user';
import { toResponseError } from '@/shared/api/util';

import UserData from './type';

class AppUser {
  private data: UserData | null = null;

  readonly api: UserApi;

  constructor(ctx?: GetServerSidePropsContext) {
    this.api = new UserApi(ctx);
  }

  getData = () => this.api.get();

  getFavorites = async (query?: Record<string, any>) => {
    try {
      const response = await this.api.getFavorites(query);

      if (Array.isArray(response.data)) {
        return response.data;
      }

      return response.data.products.data.map((i) => i.id);
    } catch (error) {
      throw toResponseError(error);
    }
  };

  initialize = async () => {
    try {
      const data = await this.getData();
      this.data = data;

      const { authorized } = data;

      if (!authorized) {
        return {
          ...data,
          favorites: [],
        };
      }

      const favorites = await this.getFavorites();
      return {
        ...data,
        favorites,
      };
    } catch (error) {
      throw toResponseError(error);
    }
  };
}

export default AppUser;
