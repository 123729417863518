import React, { useMemo } from 'react';
import * as classnames from 'classnames';
import ExtendedMarkdown from 'markdown-to-jsx';
import * as PropTypes from 'prop-types';
import uuid from 'uuid-v4';

import { NEXT_PUBLIC_CDN_ROOT } from '../../cfg';
import Carousel from '../../components/Carousel';
import ExpansionPanelMarkdown from '../../components/ExpansionPanelMarkdown';
import IndividualSizesForm from '../../components/IndividualSizesForm';
import Typography from '../../components/Typography';
import Button from '../Button';

import styles from './Markdown.module.css';


const Markdown = React.forwardRef((props, ref) => {
  const {
    className,
    source: rawSource,
    style,
    theme,
    isExtendedMode,
    isSingleFontEnabled,
    isTurboMode,
    isAmp,
    header = null,
    footer = null,
  } = props;

  let content = null;

  const baseUrl = NEXT_PUBLIC_CDN_ROOT;

  const source = useMemo(() => {
    const temp = rawSource ? rawSource?.replace(/<img /g, "<img loading='lazy'") : rawSource;

    return temp;
  }, [rawSource]);

  if (isAmp) {
    content = (
      <div className='markdown'>
        <ExtendedMarkdown
          options={{
            slugify: () => uuid(),
            overrides: {
              Button: {
                component: Button,
                props: {
                  isTurboMode: true,
                },
              },
              Typography: {
                component: Typography,
                props: {
                  isTurboMode: true,
                },
              },
              IndividualSizesForm: {
                component: IndividualSizesForm,
                props: {
                  isTurboMode: true,
                },
              },
              ExpansionPanelMarkdown: {
                component: ExpansionPanelMarkdown,
                isTurboMode: true,
              },
            },
            createElement(type, properties, children) {
              const newProperties = { ...properties };

              if (type === 'a') {
                if (newProperties?.href && newProperties?.href?.toString().startsWith('/')) {
                  newProperties.href = `${baseUrl}${newProperties.href}`;
                }

                // if (newProperties?.href && newProperties?.href?.toString().startsWith('#')) {
                //   newProperties.href = `${baseUrl}/${newProperties.href}`;
                // }
              }

              if (type === 'img') {
                if (newProperties?.src && newProperties?.src?.toString().startsWith('/')) {
                  newProperties.src = `${baseUrl}${newProperties.src}`;
                }

                if (!newProperties?.title && newProperties?.alt) {
                  newProperties.title = newProperties?.alt;
                }

                if (!newProperties.width || !newProperties.height) {
                  newProperties.width = '1.33';
                  newProperties.height = '1';
                }

                return React.createElement('amp-img', newProperties, children);
              }

              if (type === 'iframe') {
                newProperties.frameborder = '0';
                newProperties.layout = 'responsive';

                return React.createElement('amp-iframe', newProperties, children);
              }

              return React.createElement(type, newProperties, children);
            },
          }}
        >
          {source}
        </ExtendedMarkdown>
      </div>
    );
  } else if (isExtendedMode) {
    content = (
      <ExtendedMarkdown
        options={{
          overrides: {
            Button: {
              component: Button,
            },
            Typography: {
              component: Typography,
            },
            Carousel: {
              component: Carousel,
            },
            IndividualSizesForm: {
              component: IndividualSizesForm,
            },
            ExpansionPanelMarkdown: {
              component: ExpansionPanelMarkdown,
            },
          },
          createElement(type, properties, children) {
            const newProperties = { ...properties };

            let newType = type;

            if (newType === 'img') {
              newProperties.loading = 'lazy';

              if (newProperties?.src && newProperties?.src?.toString().startsWith('/')) {
                newProperties.src = `${baseUrl}${newProperties.src}`;
              }
            }

            if (newType === 'p' && newProperties?.class) {
              newType = 'div';
            }

            return React.createElement(newType, newProperties, children);
          },
        }}
      >
        {source}
      </ExtendedMarkdown>
    );
  } else if (isTurboMode) {
    content = (
      <ExtendedMarkdown
        options={{
          slugify: () => uuid(),
          overrides: {
            Button: {
              component: Button,
              props: {
                isTurboMode: true,
              },
            },
            Typography: {
              component: Typography,
              props: {
                isTurboMode: true,
              },
            },
            Carousel: {
              component: Carousel,
              props: {
                isTurboMode: true,
              },
            },
            IndividualSizesForm: {
              component: IndividualSizesForm,
              props: {
                isTurboMode: true,
              },
            },
            ExpansionPanelMarkdown: {
              component: ExpansionPanelMarkdown,
            },
          },
          createElement(type, properties, children) {
            const newProperties = { ...properties };

            if (type === 'a') {
              if (newProperties?.href && newProperties?.href?.toString().startsWith('/')) {
                newProperties.href = `${baseUrl}${newProperties.href}`;
              }

              // if (newProperties?.href && newProperties?.href?.toString().startsWith('#')) {
              //   newProperties.href = `${baseUrl}/${newProperties.href}`;
              // }
            }

            if (type === 'img') {
              newProperties.loading = 'lazy';

              if (newProperties?.src && newProperties?.src?.toString().startsWith('/')) {
                newProperties.src = `${baseUrl}${newProperties.src}`;
              }

              if (!newProperties?.title && newProperties?.alt) {
                newProperties.title = newProperties?.alt;
              }
            }

            return React.createElement(type, newProperties, children);
          },
        }}
      >
        {source}
      </ExtendedMarkdown>
    );
    return content;
  } else {
    content = (
      <ExtendedMarkdown
        options={{
          overrides: {
            Button: {
              component: Button,
            },
            Typography: {
              component: Typography,
            },
            Carousel: {
              component: Carousel,
            },
            IndividualSizesForm: {
              component: IndividualSizesForm,
            },
            ExpansionPanelMarkdown: {
              component: ExpansionPanelMarkdown,
            },
          },
          createElement(type, properties, children) {
            const newProperties = { ...properties };

            if (type === 'img') {
              newProperties.loading = 'lazy';

              if (newProperties?.src && newProperties?.src?.toString().startsWith('/')) {
                newProperties.src = `${baseUrl}${newProperties.src}`;
              }
            }

            return React.createElement(type, newProperties, children);
          },
        }}
      >
        {source}
      </ExtendedMarkdown>
    );
  }

  return (
    <div
      className={classnames(
        !theme && styles.markdown,
        className,
        {
          [styles.singleFontMode]: !!isSingleFontEnabled,
          [styles.promoMarkdown]: theme === 'promo',
        }
      )}
      style={style}
      ref={ref}
    >
      {header}
      {content}
      {footer}
    </div>
  );
});

Markdown.propTypes = {
  className: PropTypes.string,
  source: PropTypes.string.isRequired,
  style: PropTypes.object,
  isExtendedMode: PropTypes.bool,
  isAmp: PropTypes.bool,
  isTurboMode: PropTypes.bool,
};

Markdown.defaultProps = {
  className: null,
  style: null,
  isExtendedMode: false,
  isAmp: false,
  isTurboMode: false,
};

export default Markdown;
