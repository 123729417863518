import { useContext } from 'react';

import useCookie from './useCookie';

import { PagePropsContext } from '../contexts';

const useGclid = (router) => {
  const { domain } = useContext(PagePropsContext);
  const [gclidCookie, setGclid] = useCookie('gclid');

  const { gclid: gclidParam, gclsrc: gclsrcParam } = router.query;

  const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;

  if (gclidParam && isGclsrcValid && gclidCookie !== gclidParam) {
    setGclid(gclidParam, { expires: 90, domain });
  }

  return gclidParam || gclidCookie;
};

export default useGclid;
