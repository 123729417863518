import { API_RESOURCE, API_ROOT, API_VERSION } from '../../../config/constants/api';

import AbstractApiResource from './abstract';

interface FormApiQuery {
  cityId: number;
  code?: string;
}

class FormApiResource extends AbstractApiResource {
  constructor() {
    super(`${API_ROOT}${API_VERSION.V1}${API_RESOURCE.FORMS}`);
  }

  get = (url: string, query: FormApiQuery) => this.urlFactory(`${url}`, query);
}

export default FormApiResource;
