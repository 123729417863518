import useSWR from 'swr';

import React, { useContext } from 'react';
import get from 'lodash/get';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';

import initializeAxiosInstance from '../../redux/api/initializeAxiosInstance';
import useCookie from '../../hooks/useCookie';

import styles from './DiscountPopups.module.css';
import { NEXT_PUBLIC_CDN_ROOT } from '../../cfg';
import { PagePropsContext } from '../../contexts';

const fetcher = url => initializeAxiosInstance({})
  .then(axios => axios.get(url))
  .then(res => res.data);


const DiscountPopups = ({ router, host }) => {
  const { domain } = useContext(PagePropsContext);
  const { query } = router;

  const utmCampaign = get(query, 'utm_campaign');

  if (!utmCampaign) {
    return null;
  }

  const cookieIsOpenPopupName = `${utmCampaign}-PopupIsOpened`;

  const [isOpen, setIsOpenPopup] = useCookie(cookieIsOpenPopupName, false);

  const { data, error } = useSWR(`${`${process.env.NEXT_PUBLIC_API_ROOT.replace('/rest', '')}/rest` || `https://${host}/rest` || 'https://kover.ru/rest'}/popups/?utm_campaign=${utmCampaign}`, fetcher);

  if (isOpen) {
    return null;
  }

  if (error || !data) {
    return null;
  }

  const title = get(data, 'title');
  const img = get(data, 'img');
  const description = get(data, 'description');
  const code = get(data, 'code');
  const href = get(data, 'href');

  return (
    <div className={styles.discountBanner}>
      <div className={styles.discountBannerContent}>
        <div className={styles.discountBannerTitle}>{title}</div>
        <img className={styles.discountBannerImg} src={`${NEXT_PUBLIC_CDN_ROOT}${img}`} alt={title} loading='lazy' />
        <div className={styles.discountBannerDescription}>{description}</div>
        <div className={styles.discountBannerPromo}>
          <div>{get(code, 'title')}</div>
          <strong className={styles.discountBannerCode}>{get(code, 'value')}</strong>
        </div>
        <a className={styles.discountBannerLink} href={get(href, 'value')}>{get(href, 'title')}</a>

        <button
          type='button'
          className={styles.discountBannerButton}
          onClick={() => {
            setIsOpenPopup(true, { domain });
          }}
        >
          <img className={styles.discountBannerButtonImg} src='/static/imgs/mainPage/close.png' alt='Закрыть' loading='lazy' />
        </button>
      </div>
    </div>
  );
};

DiscountPopups.propTypes = {
  router: PropTypes.object.isRequired,
};

export default withRouter(DiscountPopups);
