import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

import { isClient } from '../lib/helpers/predicates';

interface WindowSize {
  width: number | undefined;
  height: number | undefined;
}

const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: isClient ? window.innerWidth : 0,
    height: isClient ? window.innerHeight : 0,
  });

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    const throttledHandleResize = throttle(handleResize, 50);

    window.addEventListener('resize', throttledHandleResize);
    handleResize();

    return () => window.removeEventListener('resize', throttledHandleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
