import { APP_SECTION, SECTION_TO_ROUTE } from '@/core/config/constants/routes';
import useSectionRoute, { createRouteUrl, RouteItem } from '@/shared/hooks/useSectionRoute';

const PAGE_NAME = {
  ITEM: 'item',
} as const;

type PageName = typeof PAGE_NAME[keyof typeof PAGE_NAME];

const BASE_ROUTE = SECTION_TO_ROUTE[APP_SECTION.ADRESA_MAGAZINOV];

const GET_ADRESA_MAGAZINOV_ROUTE = {
  ROOT: (query?: Record<string, any>) => createRouteUrl(BASE_ROUTE, query),
  ITEM: (code: string) => createRouteUrl(`${BASE_ROUTE}/${code}`),
} as const;

const ADRESA_MAGAZINOV_SECTION = {
  section: APP_SECTION.ADRESA_MAGAZINOV,
  getUrl: GET_ADRESA_MAGAZINOV_ROUTE.ROOT,
  page: {
    [PAGE_NAME.ITEM]: GET_ADRESA_MAGAZINOV_ROUTE.ITEM,
  },
} as const;

const useAdresaMagazinovSectionRoute = () => useSectionRoute<PageName>(ADRESA_MAGAZINOV_SECTION) as RouteItem<PageName>;

export default useAdresaMagazinovSectionRoute;
