import { API_ROOT } from '../../../config/constants/api';

import AbstractApiResource from './abstract';

interface ContentQuery {
  url: string;
  cityId: number;
  apiCode: string;
  code: string;
}

class ContentApiResource extends AbstractApiResource {
  constructor() {
    super(`${API_ROOT}`);
  }

  get = (url: string, query: Partial<ContentQuery>) => this.urlFactory(`${url}`, query);
}

export default ContentApiResource;
