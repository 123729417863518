import { ofType } from 'redux-observable';
import { from, of, merge, EMPTY } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

import {
  sendFormRequest, sendFormSuccess, sendFormFailure,
} from './actions';

import initializeAxiosInstance from '../../api/initializeAxiosInstance';


export const sendFormRequestEpic = (action$, state$) => action$.pipe(ofType(sendFormRequest), mergeMap((actionData) => {
  const {
    payload: {
      path,
      values,
      file,
      isNewApi,
      getRequest,
      getSuccess,
      getFailure,
      onSuccess,
      onFailure,
      isServerErrorHandlingEnabled,
    },
  } = actionData;

  const formData = new FormData();

  if (values) {
    Object.keys(values)
      .filter(key => values[key])
      .forEach((key) => {
        formData.append(key, values[key]);
      });
  }

  if (file) {
    formData.append('file', file);
  }

  const instance = initializeAxiosInstance({ store: state$, isNewApi });

  return merge(getRequest ? of(getRequest()) : EMPTY, from(instance.post(path, isNewApi ? values : formData, {
    headers: {
      'content-type': isNewApi ? 'application/json' : 'multipart/form-data',
    },
  }))
    .pipe(mergeMap((result) => {
      if (result) {
        const actions = [];

        if (result.data.error) {
          if (isServerErrorHandlingEnabled) {
            const errorActions = [];

            if (getFailure) {
              errorActions.push(getFailure(result?.data));
            }

            if (onFailure) {
              onFailure(result?.data);
            }

            return of(sendFormFailure(result?.data), ...errorActions);
          }
          throw new Error(result.data.error?.text || 'Transport Error');
        }

        if (getSuccess) {
          actions.push(getSuccess(result.data));
        }

        if (onSuccess) {
          onSuccess(result.data);
        }

        return of(sendFormSuccess(), ...actions);
      }
      return EMPTY;
    }), catchError((error) => {
      if (error) {
        const actions = [];

        if (getFailure) {
          actions.push(getFailure(error));
        }

        if (onFailure) {
          onFailure(error);
        }

        return of(sendFormFailure(error), ...actions);
      }

      return EMPTY;
    })));
}));
