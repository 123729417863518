import getNormalizedUrl from 'helpers/getNormalizedUrl';
import isUrlEqual from 'helpers/isUrlEqual';
import { NextPageContext } from 'next';
import { RedirectError } from 'types/errors';

import RedirectsApi from '@/core/api/redirects';
import { toResponseError } from '@/shared/api/util';
import { isServerDebugKey } from '@/shared/lib/helpers/predicates';

import Redirect from './type';

const api = new RedirectsApi();

class AppRedirect {
  readonly ctx: NextPageContext;

  public list: Redirect[];

  constructor(ctx, list?: Redirect[]) {
    this.list = list || [];
    this.ctx = ctx;
  }

  initialize = async () => {
    await this.request();

    const findedRedirect = this.find();

    if (findedRedirect?.to) {
      this.setRedirect(findedRedirect.to);
    }
  };

  find = () => {
    const { req, query } = this.ctx;

    if (!req) {
      return null;
    }

    const isDebug = isServerDebugKey(query, 'redirects');

    if (!this.list?.length) {
      console.log('[core][model][redirects]', 'Redirects list is empty');

      return null;
    }

    const url = new URL(req?.url || '', `https://${req.headers.host}`);
    const testDecoded = `${decodeURIComponent(url.pathname)}${decodeURIComponent(url.search)}${decodeURIComponent(
      url.hash
    )}`;

    const test = `${url.pathname}${url.search}${url.hash}`;
    return this.list.find((x) => isUrlEqual({ from: x.from, url: test, urlDecoded: testDecoded, isDebug }));
  };

  request = async () => {
    try {
      const response = await api.get();

      this.list = response;
    } catch (error) {
      throw toResponseError(error);
    }
  };

  setRedirect = (path: string) => {
    const { req } = this.ctx;

    if (!req) {
      return;
    }

    const url = new URL(req.url ?? '', `https://${req.headers.host}`);

    const destinationUrl = new URL(getNormalizedUrl(path), `https://${req.headers.host}`);

    if (Array.from(url.searchParams?.keys() || [])?.length) {
      url.searchParams.forEach((value, key) => {
        if (!destinationUrl.searchParams.has(key)) {
          destinationUrl.searchParams.set(key, value);
        }
      });
    }

    console.error('[core][model][redirects][RedirectError]', {
      from: url.toString(),
      to: destinationUrl.toString(),
    });

    throw new RedirectError({
      destination: destinationUrl.toString(),
      permanent: true,
    });
  };
}

export default AppRedirect;
