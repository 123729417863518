import { API_ROOT, API_VERSION } from '../../../config/constants/api';

import AbstractApiResource from './abstract';

const BASE_URL = '/shops';

class ShopsApiResource extends AbstractApiResource {
  constructor() {
    super(`${API_ROOT}${API_VERSION.REST}${BASE_URL}`);
  }

  get = (url?: string, query?: Record<string, any>) => this.urlFactory(url, query);
}

export default ShopsApiResource;
