import { MenuCategory } from '@/core/model/type/menu';
import { toResponseError } from '@/shared/api/util';

import BaseAppRequest from '../utils/client';
import ApiResource from '../utils/endpoint';

class MenuApi {
  readonly client: BaseAppRequest;

  constructor() {
    this.client = new BaseAppRequest();
  }

  get = async () => {
    const url = ApiResource.menu.get();

    try {
      const { data } = await this.client.get<MenuCategory[]>(url);
      return data;
    } catch (error) {
      console.error('[menu][api][get]', { url, error });
      throw toResponseError(error);
    }
  };
}

export default MenuApi;
