import React from 'react';
import PropTypes from 'prop-types';
import MDSpinner from 'react-md-spinner';

import classnames from 'classnames';

import styles from './Loader.module.css';


const Loader = ({
  isFetching, className, classNameBody, fixed, size, color,
}) => (
  <div className={classnames(styles.loaderContent, className, { [styles.active]: isFetching, [styles.fixed]: fixed })}>
    <MDSpinner
      className={classNameBody}
      singleColor={color || '#191E80'}
      size={size || '48px'}
    />
  </div>
);

Loader.propTypes = {
  className: PropTypes.string,
  classNameBody: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  fixed: PropTypes.bool,
};

Loader.defaultProps = {
  className: null,
  classNameBody: null,
  fixed: null,
};

export default Loader;
