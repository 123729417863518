import { MutableRefObject } from 'react';

export interface UiScrollContainer {
  active: boolean;
  ref: MutableRefObject<HTMLElement | null> | HTMLElement | null;
}

export interface UiFooter {
  active: boolean;
  ref: MutableRefObject<HTMLElement | null> | HTMLElement | null;
}

export interface UiCounters {
  catalogCount: number;
}

export interface UiLayout {
  headerOffset: number;
  menu: {
    opened: boolean;
  };
  scrollContainer: UiScrollContainer;
  footer: UiFooter;
}

export interface AppUi {
  counters: UiCounters;
  layout: UiLayout;
  offset: {
    x: number;
    y: number;
  };
  onRedirect?: () => void;
}

export type AppUiStore = AppUi;

const DEF_VALUE: AppUi = {
  counters: {
    catalogCount: 0,
  },
  layout: {
    headerOffset: 0,
    menu: { opened: false },
    scrollContainer: { active: false, ref: null },
    footer: { active: true, ref: null },
  },
  offset: {
    x: 0,
    y: 0,
  },
};

const createUiStore = () => ({
  ...DEF_VALUE,
});

export default createUiStore;
