import { useCallback, useMemo } from 'react';

import useAppUi from '@/core/store/ui/useAppUi';
import useHasMounted from '@/shared/hooks/useHasMounted';
import useRefCallback from '@/shared/hooks/useRefCallback';
import useUpdateEffect from '@/shared/hooks/useUpdateEffect';
import useWindowSize from '@/shared/hooks/useWindowSize';

const useLayoutMeasures = () => {
  const headerRefEntry = useRefCallback();
  const [, headerRef, isHeaderReady] = headerRefEntry;

  const windowSize = useWindowSize();

  const { setLayout } = useAppUi();
  const hasMounted = useHasMounted();

  const onHeaderResize = useCallback(
    (target) => {
      if (!target || !isHeaderReady) return;

      const { height: headerOffset } = target.getBoundingClientRect();
      setLayout({ headerOffset });
    },
    [setLayout, isHeaderReady]
  );

  useUpdateEffect(() => {
    const target = headerRef.current as HTMLElement;
    if (!target) return;

    const { height: headerOffset } = target.getBoundingClientRect();
    setLayout({
      headerOffset,
    });
  }, [hasMounted, windowSize, isHeaderReady]);

  return useMemo(() => ({ headerRefEntry, onHeaderResize }), [headerRefEntry, onHeaderResize]);
};

export default useLayoutMeasures;
