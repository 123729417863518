import { useCallback, useMemo } from 'react';

import { useAppStoreApi } from '../AppStoreProvider';
import useAppStore from '../useAppStore';

import { AppClient } from '.';

const useAppClient = () => {
  const { setState } = useAppStoreApi();

  const client = useAppStore((state) => state.client);

  const setClient = useCallback(
    (client: Partial<AppClient>) => {
      setState((state) => {
        const update = {
          ...state.client,
          ...client,
        };
        state.client = update;
      });
    },
    [setState]
  );

  return useMemo(() => ({ ...client, setClient }), [client, setClient]);
};

export default useAppClient;
