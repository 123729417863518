import { ComponentType } from 'react';
import classNames from 'classnames';

import { PropsWithClassName } from '@/shared/types/util';

import style from './WithOverlay.module.css';

interface LoaderWithOverlayProps extends PropsWithClassName {
  component: ComponentType<PropsWithClassName>;
}

const LoaderWithOverlay = ({ component: Component, className }: LoaderWithOverlayProps) => (
  <div className={classNames(style.root, style.overlay, className)}>
    <Component className={style.loader} />
  </div>
);

export default LoaderWithOverlay;
