import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useAmp } from 'next/amp';

import getAmpClassName from '/helpers/getAmpClassName';
import SvgIcon from '/components/SvgIcon';

import arrowDefaultIcon from '../../assets/imgs/installment/installmentIcon.svg';

import styles from './ExpansionPanelMarkdown.module.css';

const ExpansionPanelMarkdown = (props) => {
  const {
    title,
    children,
    titleProps,
  } = props;
  const isAmp = useAmp();
  const getClassName = getAmpClassName(styles, isAmp);
  const [isOpened, setIsOpened] = useState(false);

  const handleClick = () => {
    setIsOpened(!isOpened);
  };

  return (
    <>
      <button
        type='button'
        tabIndex='0'
        className={classnames(getClassName('expansionPanelTitle'), { [getClassName('opened')]: isOpened })}
        onClick={event => handleClick(event)}
        {...titleProps}
      >
        <span>{title}</span>
          &nbsp;
        <SvgIcon className={getClassName('img')} svg={arrowDefaultIcon} />
      </button>
      <div className={classnames(getClassName('expansionPanelContent'), { [getClassName('opened')]: isOpened })}>
        {children}
      </div>
    </>
  );
};

export default ExpansionPanelMarkdown;

ExpansionPanelMarkdown.defaultProps = {
  children: null,
  titleProps: {},
};

ExpansionPanelMarkdown.propTypes = {
  children: PropTypes.any,
  titleProps: PropTypes.object,
};
