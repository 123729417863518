import { APP_SECTION, SECTION_TO_ROUTE } from '@/core/config/constants/routes';
import useSectionRoute, { createRouteUrl, RouteItem } from '@/shared/hooks/useSectionRoute';

const BASE_ROUTE = SECTION_TO_ROUTE[APP_SECTION.CART];

const GET_CART_ROUTE = {
  ROOT: () => createRouteUrl(BASE_ROUTE),
} as const;

const CART_SECTION = {
  section: APP_SECTION.PRODUCT,
  getUrl: GET_CART_ROUTE.ROOT,
};

const useCartSectionRoute = () => useSectionRoute(CART_SECTION) as RouteItem;

export default useCartSectionRoute;
