/* eslint-disable react/prop-types */
import { Provider as ReactProvider, useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import LayoutMaintenance from 'layouts/Layout/components/Maintenance';
import Head from 'next/head';

import type { AppPropsWithLayout } from '@/application';
import AppBootstrap from '@/application/config/AppBootstrap';
import legacyBasePropsConverter from '@/application/config/page/legacyBasePropsConverter';
import useAppMount from '@/application/config/useAppMount';
import { handleReportWebVitals } from '@/application/lib/analytics';
import { AppStoreContext } from '@/core/store';
import ErrorBoundary from '@/shared/lib/container/ErrorBoundary';
import { isClientDebug } from '@/shared/lib/helpers/predicates';

import { PagePropsContext } from '../contexts';
import Layout from '../layouts/Layout';
import createStore from '../redux/store/createStore';

import '../styles/main.css';
import '../styles/grid.css';
import '../styles/fonts.css';

const reduxStore = createStore();

const isMaintenance = !!process.env.NEXT_PUBLIC_MAINTENANCE;

const PagePropsProvider = PagePropsContext.Provider as ReactProvider<any>;


export function reportWebVitals(metric) {
  handleReportWebVitals(metric);
}

const KoverApp = (props: AppPropsWithLayout) => {
  const { Component, pageProps } = props;

  const { session } = pageProps;

  const legacyBaseProps = useMemo(() => ({ ...legacyBasePropsConverter(session), ...pageProps }), [session, pageProps]);

  const store = useAppMount(pageProps);

  useEffect(() => {
    if (isClientDebug()) {
      console.log('Page properties: ', { baseProps: legacyBaseProps, pageProps });
    }
  }, [legacyBaseProps, pageProps]);

  return (
    <Provider store={reduxStore}>
      <AppStoreContext.Provider value={store}>
        <Head>
          <meta name='viewport' content='width=device-width,initial-scale=1' key='viewport' />
        </Head>

        <ErrorBoundary>
          {isMaintenance ? (
            <LayoutMaintenance />
          ) : (
            <PagePropsProvider value={legacyBaseProps}>
              <Layout {...legacyBaseProps}>
                <Component {...pageProps} />
              </Layout>
            </PagePropsProvider>
          )}
        </ErrorBoundary>

        <AppBootstrap />
      </AppStoreContext.Provider>
    </Provider>
  );
};

export default KoverApp;
