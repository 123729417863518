export interface AppPortal {
  id: string;
  active: boolean;
  node: HTMLElement;
}

export interface AppPortals {
  data: Record<string, AppPortal>;
}

export type AppPortalStore = AppPortals;

const DEF_VALUE: AppPortals = {
  data: {},
};

const createPortalStore = () => ({
  ...DEF_VALUE,
});

export default createPortalStore;
