import { handleActions } from 'redux-actions';

import {
  getFavoritesReceive,
  getFavoritesRequest,

  setFavoritesRequest,
  setFavoritesReceive,

  deleteFavoritesRequest,
  deleteFavoritesReceive,
} from './actions';


const initialState = {
  isFavoriteProductsFetching: false,
  favoritesProducts: [],
  pagination: null,
};

export default handleActions({


  [getFavoritesRequest](state, action) {
    return Object.assign({}, state, {
      isFavoriteProductsFetching: !action.payload?.firstCall,
    });
  },
  [getFavoritesReceive](state, action) {
    return Object.assign({}, state, {
      isFavoriteProductsFetching: false,
      favoritesProducts: action.payload.favoritesProducts,
      pagination: action.payload.pagination,
    });
  },
  [setFavoritesRequest](state) {
    return Object.assign({}, state, {
      isFavoriteProductsFetching: true,
    });
  },
  [setFavoritesReceive](state) {
    return Object.assign({}, state, {
      isFavoriteProductsFetching: false,
      // favoritesProducts: action.payload.favoritesProducts,
      // pagination: action.payload.pagination,
    });
  },
  [deleteFavoritesRequest](state) {
    return Object.assign({}, state, {
      isFavoriteProductsFetching: true,
    });
  },
  [deleteFavoritesReceive](state) {
    return Object.assign({}, state, {
      isFavoriteProductsFetching: false,
      // favoritesProducts: action.payload.favoritesProducts,
      // pagination: action.payload.pagination,
    });
  },
}, initialState);
