import { handleActions } from 'redux-actions';

import {
  getSearchRequest,
  getSearchSuccess,
  getSearchFailure,
  clearSearchRequest,
  setIsSearchEnabled,
} from './actions';


const initialState = {
  page: 1,
  pagesCount: 1,
  count: 1,
  products: null,
  aggregations: null,
  isSearchEnabled: false,
  isSearchFetching: false,
  isSearchInitialized: false,
};


export default handleActions({
  [clearSearchRequest]: state => {
    return Object.assign({}, state, {
      page: 1,
      products: null,
      isSearchFetching: false,
    });
  },

  [setIsSearchEnabled]: (state, action) => {
    return Object.assign({}, state, {
      isSearchEnabled: !!action.payload,
    });
  },

  [getSearchRequest]: (state) => {
    return Object.assign({}, state, {
      isSearchFetching: true,
    });
  },

  [getSearchSuccess]: (state, action) => {
    return Object.assign({}, state, {
      page: action.payload.page || 1,
      pagesCount: action.payload.pagesCount,
      count: action.payload.count,
      products: action.payload.products,
      aggregations: action.payload.aggregations || state.aggregations,
      isSearchFetching: false,
      isSearchInitialized: true,
    });
  },

  [getSearchFailure]: (state) => {
    return Object.assign({}, state, {
      page: 1,
      products: null,
      isSearchFetching: false,
    });
  },

}, initialState);
