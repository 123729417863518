import { createAsyncThunk } from '@reduxjs/toolkit';

import * as cabinetConstants from './constants';

const getUserOrdersList = id => dispatch => {
  dispatch({
    type: cabinetConstants.GET_USER_ORDERS_LIST,
    url: `/order`,
  });
};

export const loginUser = createAsyncThunk('login/user', async (info, {dispatch}, ___) => dispatch({
    type: cabinetConstants.LOGIN_USER,
    url: `/user/login/${JSON.stringify(info)}`,
    options: {
      method: 'POST',
    },
  }))

const registerUser = info => dispatch => new Promise((resolve, reject) => {
    dispatch({
      type: cabinetConstants.REGISTER_USER,
      url: `/user/register/${JSON.stringify(info)}`,
      options: {
        method: 'POST',
      },
    })
      .then(data => {
        if (data && data.error) {
          reject(data.msg);
        }

        resolve(data);
      })
      .catch(err => {
        reject(err.error);
      });
  });

const logoutUser = () => dispatch => {
  dispatch({
    type: cabinetConstants.LOGIN_USER,
    url: `/user/logout`,
    options: {
      method: 'POST',
    },
  });
};


const changeUserData = info => dispatch => new Promise((resolve, reject) => {
    dispatch({
      type: cabinetConstants.CHANGE_USER_DATA,
      url: `/user/`,
      requestData: info,
      options: {
        method: 'POST',
      },
    })
      .then(data => {
        if (data && data.error) {
          reject(data.msg);
        }

        // window?.mindbox('async', {
        //   operation: 'Website.EditCustomer',
        //   data: {
        //     customer: {
        //       fullName: data?.name,
        //       email: data?.email,
        //     }
        //   }
        // });

        resolve(data);
      })
      .catch(err => {
        reject(err.error);
      });
  });

const sendPassword = info => dispatch => new Promise((resolve, reject) => {
    dispatch({
      type: cabinetConstants.SEND_PASSWORD,
      url: `/user/sendpassword/${JSON.stringify(info)}`,
      options: {
        method: 'POST',
      },
    })
      .then(data => {
        if (data && data.error) {
          reject(data.msg);
        }

        resolve(data);
      })
      .catch(err => {
        reject(err.error);
      });
  });

export default {
  getUserOrdersList,
  loginUser,
  logoutUser,
  changeUserData,
  registerUser,
  sendPassword,
};
