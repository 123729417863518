/* eslint-disable no-nested-ternary */
import { CSSProperties, forwardRef, useCallback, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import { useAppPortal, useAppUi } from '@/core';
import ArrowDownIcon from '@/shared/assets/icons/tsx/ArrowDown';
import useMount from '@/shared/hooks/useMount';
import { useScrollHandler } from '@/shared/hooks/useScrollHandler';
import useScrollTo from '@/shared/hooks/useScrollTo';
import { isClient } from '@/shared/lib/helpers/predicates';
import { PropsWithClassName } from '@/shared/types/util';
import { DefaultButton } from '@/shared/ui/Button/Button';

import style from './ScrollToTop.module.css';

interface ScrollToTopButtonProps extends PropsWithClassName {
  onClick: () => void;
}

export const SCROLL_TO_TOP_KEY = 'scroll-to-top';

const ScrollToTopButton = forwardRef<HTMLButtonElement, ScrollToTopButtonProps>((props, ref) => {
  const { className, onClick } = props;
  return (
    <DefaultButton ref={ref} className={classNames(style.root, className)} onClick={onClick}>
      <ArrowDownIcon className={style.icon} />
    </DefaultButton>
  );
});
ScrollToTopButton.displayName = 'ScrollToTopButton';

const ScrollToTop = ({
  style: styleInjection,
  className,
}: PropsWithClassName<{ style: CSSProperties; CSSProperties }>) => {
  const [isShown, setIsShown] = useState(false);

  const classes = classNames(style.wrapper, className);

  // TODO: Переработать зависимость от стора, который сбрасывается при навигации
  const { active, onRender, onClose } = useAppPortal(SCROLL_TO_TOP_KEY, { className: classes, style: styleInjection });

  const {
    layout: { scrollContainer },
  } = useAppUi();
  const root = scrollContainer.active ? scrollContainer.ref : isClient ? document : null;

  const firstAnimation = useRef(true);

  const onClick = useScrollTo();

  const onScroll = useCallback((evt: Event) => {
    const scroll = (evt.target as HTMLElement)?.scrollTop ?? window.scrollY;
    const height = document.documentElement.clientHeight;

    if (scroll > height) {
      firstAnimation.current = false;
      return setTimeout(() => setIsShown(true), 0);
    }

    return setTimeout(() => setIsShown(false), 0);
  }, []);

  useScrollHandler({ onScroll, root });

  useMount(noop, onClose);

  if (!active) return null;

  return onRender(
    <Transition show={isShown} enter={style.enter} enterTo={style.show} leave={style.leave} leaveFrom={style.hide}>
      <ScrollToTopButton onClick={onClick} />
    </Transition>
  );
};

export default ScrollToTop;
