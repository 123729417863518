import { PropsWithClassName } from '@/shared/types/util';

function NextArrowIcon({ className }: PropsWithClassName) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='29'
      height='28'
      fill='none'
      viewBox='0 0 29 28'
    >
      <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' d='M11 7l7.5 7.5L11 22' />
    </svg>
  );
}

export default NextArrowIcon;
