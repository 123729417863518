/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { useContactSectionRoute } from '@/core';
import ScrollToTop from '@/features/scroll-to-top';

import { NEXT_PUBLIC_CDN_ROOT } from '../../cfg';
import Markdown from '../../controls/Markdown';
import getAmpClassName from '../../helpers/getAmpClassName';
import initializeAxiosInstance from '../../redux/api/initializeAxiosInstance';

import arrowIcon from './assets/arrow.svg';
import markerIcon from './assets/marker.svg';
import sendWhiteIcon from './assets/send-white.svg';

import styles from './Footer.module.css';

import ddd3Icon from '/assets/imgs/footer/3ddd.svg';
import mastercardIcon from '/assets/imgs/footer/mastercard.svg';
import okIcon from '/assets/imgs/footer/ok.svg';
import samsungPayIcon from '/assets/imgs/footer/samsungpay.svg';
import visaIcon from '/assets/imgs/footer/visa.svg';
import vkIcon from '/assets/imgs/footer/vk.svg';
import youtubeIcon from '/assets/imgs/footer/youtube.svg';
import zenIcon from '/assets/imgs/footer/zen.svg';
import SvgIcon from '/components/SvgIcon';
import { useRouter } from 'next/router';

const FooterMenu = ({ footerData }) => {
  const [isCatalogOpen, setIsCatalogOpen] = useState(false);
  const [footerMenuItems, setFooterMenuItems] = useState(null);

  const trackItemClick = ({ action, label }) => {
    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Каталог в подвале',
      eventAction: action,
      eventLabel: label,
    });
  };

  const getFooterMenuItemsRequest = async () => {
    const instance = initializeAxiosInstance({});

    try {
      const result = await instance.get('/footer-catalog/');

      if (result) {
        const data = result?.data;

        return setFooterMenuItems(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!footerMenuItems?.length) {
      getFooterMenuItemsRequest();
    }
  }, []);

  return (
    <div className={classnames(styles.footerSelect, { [styles.open]: isCatalogOpen })}>
      <div
        className={styles.footerSelectValue}
        role='button'
        tabIndex='0'
        onClick={() => {
          setIsCatalogOpen(!isCatalogOpen);
          window.dataLayer?.push({
            event: 'autoEvent',
            eventCategory: 'Каталог в подвале',
            eventAction: 'Открытие списка разделов',
            eventLabel: '',
          });
        }}
      >
        {footerData?.catalogTitle || 'Каталог ковров'}
        <SvgIcon className={classnames(styles.footerSelectValueIcon, styles.send)} svg={arrowIcon} />
      </div>
      <div className={styles.footerSelectBody}>
        {!!footerData?.catalog?.length &&
          footerData?.catalog?.map((footerMenuItem, k) => {
            const { title: mainTitle, items, code } = footerMenuItem;

            return (
              <div className={styles.footerSelectBodyList} key={`catalog-${k}-${code}`}>
                <div className={styles.footerSelectBodyListTitle}>{mainTitle}</div>
                {!!items?.length &&
                  items?.map((item) => {
                    const { link, title } = item;

                    return (
                      <a
                        key={`footer-list-${title}`}
                        className={styles.footerSelectBodyListItem}
                        href={link}
                        itemProp='url'
                        onClick={() => trackItemClick({ label: title, action: mainTitle })}
                      >
                        {title}
                      </a>
                    );
                  })}
              </div>
            );
          })}
      </div>
    </div>
  );
};

const Footer = ({
  showModal = false,
  contactsData,
  footerData,
  footerMenuItems,
  isAmp,
  getFooterMenuItemsRequest,
  className,
}) => {
  const menu = footerData?.menu;
  const social = footerData?.social;
  const payment = footerData?.payment;
  const contacts = footerData?.contacts;

  const router = useRouter();

  const getClassName = getAmpClassName(styles, isAmp);
  const { to: redirectToContacts } = useContactSectionRoute();

  return (
    <>
      <footer className={classnames(getClassName('footer'), className)} itemScope itemType='http://schema.org/WPFooter'>
        <div className={getClassName('footerRow')}>
          <div className={classnames(getClassName('footerInner'), 'footer__inner')}>
            <div className={getClassName('footerLeft')}>
              <div className={classnames(getClassName('footerLists'), 'footer__lists')}>
                {menu?.length &&
                  menu?.map((item) => (
                    <div key={`menu-${item.code}`} className={getClassName('footerList')}>
                      <div className={getClassName('footerListTitle')}>{item?.title}</div>
                      {item?.items?.length &&
                        item?.items?.map((row) => (
                          <a
                            key={`footer-row-${row?.title}`}
                            className={getClassName('footerListItem')}
                            itemProp='url'
                            href={row?.link}
                          >
                            {row?.title}
                          </a>
                        ))}
                    </div>
                  ))}
              </div>
              <div className={getClassName('footerRowFlex')}>
                <div className={getClassName('footerScs')}>
                  {payment?.length ? (
                    payment?.map(
                      (item) =>
                        !!item?.icon && (
                          <img
                            key={`payment-${item?.icon}`}
                            className={getClassName('footerScsItem')}
                            alt=''
                            src={`${NEXT_PUBLIC_CDN_ROOT}${item?.icon}`}
                            height='15'
                            loading='lazy'
                          />
                        )
                    )
                  ) : (
                    <>
                      <SvgIcon
                        className={getClassName('footerScsItem')}
                        svg={visaIcon}
                        style={{ background: '#fff' }}
                        height='15'
                      />
                      <SvgIcon className={getClassName('footerScsItem')} svg={mastercardIcon} height='15' />
                      <SvgIcon className={getClassName('footerScsItem')} svg={samsungPayIcon} height='15' />
                    </>
                  )}
                </div>
              </div>
              <div className={getClassName('footerRowFlex')}>
                <div className={getClassName('footerScs')}>
                  {social?.length ? (
                    social?.map(
                      (item) =>
                        !!item?.icon && (
                          <a
                            key={`social-${item?.icon}`}
                            className={getClassName('footerScsItem')}
                            href={item?.link}
                            target='_blank'
                            rel='noopener noreferrer'
                            itemProp='url'
                          >
                            <img src={`${NEXT_PUBLIC_CDN_ROOT}${item?.icon}`} alt='' loading='lazy' height='24' />
                          </a>
                        )
                    )
                  ) : (
                    <>
                      <a
                        aria-label='vk'
                        className={getClassName('footerScsItem')}
                        href='https://vk.com/koverru'
                        target='_blank'
                        rel='noopener noreferrer'
                        itemProp='url'
                      >
                        <SvgIcon svg={vkIcon} height='24' />
                      </a>
                      <a
                        aria-label='ok'
                        className={getClassName('footerScsItem')}
                        href='https://ok.ru/group/54661681840365'
                        target='_blank'
                        rel='noopener noreferrer'
                        itemProp='url'
                      >
                        <SvgIcon svg={okIcon} height='24' />
                      </a>
                      <a
                        aria-label='youtube'
                        className={getClassName('footerScsItem')}
                        href='https://www.youtube.com/channel/UCK1bUirMF29ve_FJP-MRI4Q'
                        target='_blank'
                        rel='noopener noreferrer'
                        itemProp='url'
                      >
                        <SvgIcon svg={youtubeIcon} height='24' />
                      </a>
                      <a
                        aria-label='yandex zen'
                        className={getClassName('footerScsItem')}
                        href='https://zen.yandex.ru/id/5f072157267b8b4c84203d4b'
                        target='_blank'
                        rel='noopener noreferrer'
                        itemProp='url'
                      >
                        <SvgIcon svg={zenIcon} height='24' />
                      </a>
                      <a
                        aria-label='ddd3'
                        className={getClassName('footerScsItem')}
                        href='https://3ddd.ru/users/kover_ru'
                        target='_blank'
                        rel='noopener noreferrer nofollow'
                        itemProp='url'
                      >
                        <SvgIcon svg={ddd3Icon} height='24' width='58' />
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={getClassName('footerInfo')}>
              <a
                href={`tel:${contacts?.phone?.replace(/\D/g, '') || '+78003332135'}`}
                className={classnames(getClassName('footerInfoTel'), 'call_phone_495')}
                itemProp='url'
              >
                {contacts?.phone || '+7 800 333-21-35'}
              </a>

              <div
                className={getClassName('footerInfoWorkingHours')}
                dangerouslySetInnerHTML={{
                  __html: contacts?.workTime || 'Пн-Пт с 9:00 до 21:00<br />Сб-Вс с 10:00 до 21:00',
                }}
              />

              <a
                className={getClassName('footerInfoEmail')}
                href={`mailto:${contacts?.email || 'zapros@kover.ru'}`}
                target='_top'
                itemProp='url'
              >{`${contacts?.email || 'zapros@kover.ru'}`}</a>

              <div className={getClassName('footerInfoSeparator')} />

              <div className={getClassName('footerInfoLinks')}>
                {!!contacts?.address && (
                  <div className={getClassName('footerInfoLink')}>
                    <Markdown className={getClassName('footerInfoLinkText')} source={contacts.address} />
                  </div>
                )}

                <Link
                  href={contacts?.addressLink?.link || '/adresa-magazinov'}
                  prefetch={false}
                  className={getClassName('footerInfoLink')}
                  itemProp='url'
                >
                  {!isAmp && <SvgIcon className={getClassName('footerInfoLinkIcon', 'marker')} svg={markerIcon} />}
                  <div className={getClassName('footerInfoLinkText')}>
                    {contacts?.addressLink?.title || 'Адреса магазинов'}
                  </div>
                </Link>
                <div
                  role='button'
                  tabIndex='0'
                  className={getClassName('footerInfoLink')}
                  onClick={() => {
                    redirectToContacts(undefined, undefined, { shallow: true });
                  }}
                >
                  {!isAmp && <SvgIcon className={getClassName('footerInfoLinkIcon', 'send')} svg={sendWhiteIcon} />}
                  <div className={getClassName('footerInfoLinkText')}>{contacts?.contactLinkTitle || 'Контакты'}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={getClassName('footerRow')}>
          <div className={[getClassName('footerInner'), getClassName('column')].join(' ')}>
            {!isAmp && (
              <FooterMenu
                isAmp={isAmp}
                footerData={footerData}
                footerMenuItems={footerMenuItems}
                getFooterMenuItemsRequest={getFooterMenuItemsRequest}
              />
            )}
            <div className={getClassName('footerCopyright')}>© Все права защищены. kover.ru&nbsp;</div>
          </div>
        </div>
      </footer>

      <ScrollToTop key={`scroll-to-top-${router.asPath}`} />
    </>
  );
};

Footer.propTypes = {
  footer: PropTypes.object,
  isAmp: PropTypes.bool,
  footerMenuItems: PropTypes.array,
};

Footer.defaultProps = {
  footer: null,
  isAmp: false,
  footerMenuItems: null,
};

export default Footer;
