import { GetServerSidePropsContext } from 'next';
import queryString from 'query-string';

import UserData from '@/core/model/user/type';
import { toResponseError } from '@/shared/api/util';
import { isDevDebugMode, isServerDebugKey } from '@/shared/lib/helpers/predicates';
import { Id } from '@/shared/types';

import getBaseRequestHeaders from '../utils';
import BaseAppRequest from '../utils/client';
import ApiResource from '../utils/endpoint';

const ENDPOINT = {
  FAVORITES: '/favorites',
};

export type AppFavoritesResponse = {
  data: [] | {
    products: {
      data: { id: Id }[];
    };
  }
};

class UserApi {
  readonly client: BaseAppRequest;

  readonly ctx?: GetServerSidePropsContext;

  constructor(ctx?: GetServerSidePropsContext) {
    this.client = new BaseAppRequest(ctx);
    this.ctx = ctx;
  }

  get = async () => {
    const url = ApiResource.user.get();

    try {
      const { data, config } = await this.client.get<UserData>(url);

      if (isServerDebugKey(this.ctx?.query, 'user')) {
        console.log('[user][api][get]', { url, data, config });
      }

      return data;
    } catch (error) {
      console.error('[user][api][get]', { url, error });
      throw toResponseError(error);
    }
  };

  getFavorites = async (query?: Record<string, any>) => {
    const stringQuery = query ? queryString.stringify(query, { arrayFormat: 'bracket' }) : '';
    const path = stringQuery ? `${ENDPOINT.FAVORITES}?${stringQuery}` : ENDPOINT.FAVORITES;
    const url = ApiResource.user.get(path);

    const settings = {
      url,
      method: 'GET',
      config: this.client.config,
    };

    try {
      const response = await this.client.get(url);

      if (isDevDebugMode()) {
        console.log('[user][api][getFavorites]', { url, response, settings });
      }
      return response;
    } catch (error) {
      console.error('[user][api][getFavorites]', { url, settings, error });
      throw toResponseError(error);
    }
  };

  addFavorites = async (id?: Id) => {
    const stringQuery = id ? `id=${id}` : '';
    const path = stringQuery ? `${ENDPOINT.FAVORITES}?${stringQuery}` : ENDPOINT.FAVORITES;
    const url = ApiResource.user.get(path);

    const settings = {
      url,
      method: 'POST',
      config: this.client.config,
    };

    try {
      const result = await this.client.post<AppFavoritesResponse>(url);

      if (isDevDebugMode()) {
        console.log('[user][api][postFavorites]', { url, result, settings });
      }
      return result;
    } catch (error) {
      console.error('[user][api][postFavorites]', { url, error, settings });
      throw toResponseError(error);
    }
  };

  deleteFavorites = async (id?: Id) => {
    const stringQuery = id ? `id=${id}` : '';
    const path = stringQuery ? `${ENDPOINT.FAVORITES}?${stringQuery}` : ENDPOINT.FAVORITES;
    const url = ApiResource.user.get(path);

    try {
      const result = await this.client.delete<AppFavoritesResponse>(url);
      return result;
    } catch (error) {
      console.error('[user][api][deleteFavorites]', { url, error });
      throw toResponseError(error);
    }
  };
}

export default UserApi;
