/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/button-has-type */
import React, { ButtonHTMLAttributes, forwardRef, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import Link from 'next/link';

import { PropsWithClassName } from '@/shared/types/util';
import SvgIcon from 'components/SvgIcon/SvgIcon';

import { RadialLoader } from '../Loaders';

import style from './Button.module.css';

type NextLink = typeof Link;

export interface DefaultButtonProps<Component>
  extends ButtonHTMLAttributes<Component>,
    PropsWithChildren,
    PropsWithClassName {
  type?: 'button' | 'submit' | 'reset';
  component?: 'button' | 'a' | NextLink;
}

export interface ButtonProps extends DefaultButtonProps<HTMLButtonElement | HTMLAnchorElement | NextLink> {
  medium: boolean;
  secondary: boolean;
  fullWidth: boolean;
  icon: {
    url: string;
    viewBox: string;
  };
  isLoading?: boolean;
  left: boolean;
  right: boolean;
  loaderOnly: boolean;
  grow: boolean;
  ghost: boolean;
  renderIcon?: () => ReactNode;
}

export const DefaultButton = forwardRef<any, DefaultButtonProps<HTMLButtonElement | HTMLAnchorElement>>(
  (props, ref) => {
    const { children, className, component, type = 'button', href, ...restProps } = props;

    const classes = classNames(style.root, className);

    if (component) {
      const Component = component;

      return (
        <Component ref={ref} href={href} className={classes} {...restProps}>
          {children}
        </Component>
      );
    }

    return (
      <button ref={ref} className={classes} type={type} {...restProps}>
        {children}
      </button>
    );
  }
);

DefaultButton.displayName = 'DefaultButton';

const Button = (props: Partial<ButtonProps>) => {
  const {
    children,
    className,
    disabled,
    fullWidth,
    grow,
    ghost,
    icon,
    isLoading,
    left,
    medium,
    loaderOnly,
    right,
    secondary,
    renderIcon,
    ...restProps
  } = props;

  const rootClasses = classNames(style.button, className, {
    [style.disabled]: disabled,
    [style.fullWidth]: fullWidth,
    [style.grow]: grow,
    [style.ghost]: ghost,
    [style.medium]: medium,
    [style.secondary]: secondary,
  });

  const iconClasses = classNames(style.icon, 'button__icon', {
    [style.left]: left,
    [style.right]: right,
  });

  const loaderClasses = classNames(style.loader, 'button__loader', {
    [style.left]: left,
    [style.right]: right,
  });

  return (
    <DefaultButton className={rootClasses} disabled={disabled} {...restProps}>
      {renderIcon && !isLoading ? (
        renderIcon?.()
      ) : icon && !isLoading ? (
        <SvgIcon className={iconClasses} svg={icon!} />
      ) : null}

      {loaderOnly && isLoading ? null : children}

      {isLoading && <RadialLoader className={loaderClasses} />}
    </DefaultButton>
  );
};

export default Button;
