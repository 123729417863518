import { createContext, useContext } from 'react';

import createAppStore from './createStore';
import { AppStoreApi } from './type';

export const AppStoreContext = createContext<AppStoreApi>(createAppStore());

export function useAppStoreApi() {
  const c = useContext(AppStoreContext);

  if (!c) {
    const message = 'You must wrap your component in ContextProvider';

    throw new Error(message);
  }

  return c;
}
