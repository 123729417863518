import { RefCallback, useCallback } from 'react';

import useInitialRect from './useInitialRect';

interface ScrollToOptions {
  left?: number;
  top?: number;
  behavior: 'auto' | 'smooth';
}

interface ScrollIntoViewOptions {
  behavior?: 'auto' | 'smooth';
  block?: 'start' | 'center' | 'end' | 'nearest';
  inline?: 'start' | 'center' | 'end' | 'nearest';
}

interface ScrollToElementOptions extends ScrollToOptions {
  offset?: number;
}

const useScrollTo = () =>
  useCallback((options?: Partial<ScrollToOptions>) => {
    if (typeof window === 'undefined') {
      return;
    }
    const { left = 0, top = 0, behavior = 'smooth' } = options ?? {};
    window.scrollTo({ left, top, behavior });
  }, []);

const useScrollIntoView = (options?: ScrollIntoViewOptions) =>
  useCallback(
    (element: HTMLElement | null) => {
      if (typeof window === 'undefined' || !element) {
        return;
      }
      element.scrollIntoView(options);
    },
    [options]
  );

const useScrollBy = () =>
  useCallback((options?: Partial<ScrollToOptions>) => {
    if (typeof window === 'undefined') {
      return;
    }
    const { left = 0, top = 0, behavior = 'smooth' } = options ?? {};
    window.scrollBy({ left, top, behavior });
  }, []);

const useScrollToElement = <T extends HTMLElement>(options?: ScrollToElementOptions): [RefCallback<T>, () => void] => {
  const [initialRect, setRef, ref] = useInitialRect<T>();

  const scrollBy = useScrollBy();
  const scrollIntoView = useScrollIntoView(options);
  const onScroll = useCallback(() => {
    if (!initialRect) return;

    const { offset = 0, ...restOptions } = options ?? {};

    scrollIntoView(ref.current);
    scrollBy({ ...restOptions, top: -offset });
  }, [scrollBy, scrollIntoView, options, initialRect, ref]);

  return [setRef, onScroll];
};

export { useScrollIntoView, useScrollBy, useScrollToElement };

export default useScrollTo;
