import getNormalizedUrl from './getNormalizedUrl';

const isUrlEqual = ({ from, url, urlDecoded, isDebug }) => {
  let isSimpleRegExpRedirectEnabled = false;
  let isDecodedRegExpRedirectEnabled = false;
  const isSimpleRedirectEnabled = getNormalizedUrl(from) === getNormalizedUrl(url);
  const isDecodedRedirectEnabled = getNormalizedUrl(from) === getNormalizedUrl(urlDecoded);

  const isSimpleSubstringRedirectEnabled = !!getNormalizedUrl(url)?.includes(getNormalizedUrl(from));
  const isDecodedSubstringRedirectEnabled = !!getNormalizedUrl(urlDecoded)?.includes(getNormalizedUrl(from));

  try {
    const simpleRegExpRedirect = getNormalizedUrl(url).matchAll(getNormalizedUrl(from));
    const simpleRegExpRedirectResults = Array.from(simpleRegExpRedirect);
    isSimpleRegExpRedirectEnabled = !!simpleRegExpRedirectResults?.length;
    const decodedRegExpRedirect = getNormalizedUrl(urlDecoded).matchAll(getNormalizedUrl(from));
    const decodedRegExpRedirectResults = Array.from(decodedRegExpRedirect);
    isDecodedRegExpRedirectEnabled = !!decodedRegExpRedirectResults?.length;
  } catch (e) {
    if (isDebug) {
      console.log(e);
    }
  }

  return isSimpleRedirectEnabled
    || isDecodedRedirectEnabled
    || isSimpleSubstringRedirectEnabled
    || isDecodedSubstringRedirectEnabled
    || isSimpleRegExpRedirectEnabled
    || isDecodedRegExpRedirectEnabled;
};

export default isUrlEqual;
