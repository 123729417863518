
const getDefaultWidth = ({ isPhone, isTablet }) => {
  let defaultWidth = 1280;

  if (isTablet) {
    defaultWidth = 768;
  } else if (isPhone) {
    defaultWidth = 360;
  }

  return defaultWidth;
};

export default getDefaultWidth;
