const getNormalizedUrl = (path) => {
  const url = new URL(path, 'https://kover.ru');

  let preparedPathname = url.pathname;

  if (!preparedPathname?.startsWith('/')) {
    preparedPathname = `/${preparedPathname}`;
  }

  if (!preparedPathname?.endsWith('/')) {
    preparedPathname = `${preparedPathname}/`;
  }

  url.pathname = preparedPathname;

  return url.pathname + url.search + url.hash;
};

export default getNormalizedUrl;
