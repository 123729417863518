import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Component that alerts if you click outside of it
 */
export default class ClickOutside extends Component {
  static propTypes = {
    children: PropTypes.array.isRequired,
    handleClickOutside: PropTypes.func.isRequired,
    className: PropTypes.string,
    wrapperRef: PropTypes.any,
    component: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    className: null,
    wrapperRef: null,
    component: 'div',
    onClick: null,
  };

  wrapperRef = React.createRef();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, { capture: true });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, { capture: true });
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = (event) => {
    const ref = this.props.wrapperRef || this.wrapperRef;
    if (ref.current && !ref.current.contains(event.target)) {
      this.props.handleClickOutside(event);
    }
  }

  render() {
    const { component, onClick } = this.props;

    const Component = component;

    return <Component className={this.props.className} ref={this.props.wrapperRef || this.wrapperRef} onClick={onClick}>{this.props.children}</Component>;
  }
}
