import React, { useState } from 'react';
import classnames from 'classnames';

import styles from './TextField.module.css';

import CloseIcon from '../../icons/Close';

const showErrorOnChange = ({
  meta: {
    submitError,
    dirtySinceLastSubmit,
    error,
    touched,
    modified,
  },
}) => !!(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified));

const showErrorOnBlur = ({
  meta: {
    submitError,
    dirtySinceLastSubmit,
    error,
    touched,
  },
}) => !!(((submitError && !dirtySinceLastSubmit) || error) && touched);

const TextField = (props) => {
  const {
    input: {
      name, type, value, onChange, ...restInput
    },
    meta,
    multiline,
    rows,
    placeholder,
    required,
    className,
    theme,
    size,
    disabled,
    showError = showErrorOnChange,
    ...rest
  } = props;

  const { touched, error, submitError } = meta;

  const isError = showError({ meta });

  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleClear = () => {
    if (onChange) {
      onChange('');
    }
  };

  if (multiline) {
    return (
      <div
        className={classnames(styles.textField, className, {
          [styles.textFieldError]: isError,
          [styles.mini]: size === 'mini',
          [styles.white]: theme === 'white',
          [styles.gray]: theme === 'gray',
          [styles.bordered]: theme === 'bordered',
          [styles.disabled]: !!disabled,
        })}
        {...rest}
      >
        <textarea
          className={styles.textFieldInput}
          required={required}
          value={value}
          rows={rows}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={disabled}
          {...restInput}
        />
        {touched && (
          <div className={styles.textFieldError}>{error || submitError}</div>
        )}
      </div>
    );
  }

  return (
    <div
      className={classnames(styles.textField, className, {
        [styles.textFieldError]: isError,
        [styles.mini]: size === 'mini',
        [styles.white]: theme === 'white',
        [styles.gray]: theme === 'gray',
        [styles.bordered]: theme === 'bordered',
        [styles.disabled]: !!disabled,
      })}
      {...rest}
    >
      <input
        className={styles.textFieldInput}
        type={type}
        required={required}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
        {...restInput}
      />
      {touched && (
        <div className={styles.textFieldError}>{error || submitError}</div>
      )}
      {value?.length !== 0 && type !== 'datetime-local' && (
        <button className={styles.clear} onClick={handleClear}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
};

export default TextField;