import { NEXT_PUBLIC_API_ROOT } from '../../../../../cfg';
import { API_RESOURCE, API_VERSION } from '../../../config/constants/api';

import AbstractApiResource from './abstract';

class MainFiltersApiResources extends AbstractApiResource {
  constructor() {
    super(`${NEXT_PUBLIC_API_ROOT}${API_VERSION.V1}${API_RESOURCE.MAIN_FILTERS}`);
  }
  
  get = (url?: string, query?: Record<string, any>) => this.urlFactory(url ?? '', query);
}

export default MainFiltersApiResources;
