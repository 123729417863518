import { useState } from 'react';

import useRefCallback from './useRefCallback';
import useUpdateEffect from './useUpdateEffect';

const useInitialRect = <T extends HTMLElement>() => {
  const [initialRect, setInitialRect] = useState<DOMRectReadOnly | null>(null);

  const [setRef, ref, isReady] = useRefCallback<T>();

  useUpdateEffect(() => {
    if (!isReady || !ref.current) return;

    const rect = ref.current.getBoundingClientRect();
    setInitialRect(rect);
  }, [isReady]);

  return [initialRect, setRef, ref] as const;
};

export default useInitialRect;
