import { AxiosError } from 'axios';

import { filterObjectValues } from '../lib/helpers/object';

import { ResponseError } from './type';

const isValidError = (error: unknown) =>
  typeof error === 'object' &&
  error !== null &&
  ('response' in error || 'request' in error || 'config' in error || 'message' in error);

export function toResponseError(maybeError: unknown) {
  if (isValidError(maybeError)) {
    return maybeError as ResponseError;
  }

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toResponseError(error)?.message;
}

export const transformAxiosError = (error: AxiosError, transformer?: (value: AxiosError) => any): ResponseError => {
  const { response, message, code, name } = error || {};
  const { status, data } = response || {};

  if (transformer) {
    return transformer(error);
  }

  return filterObjectValues({
    name,
    message,
    status,
    code,
    data,
  }) as ResponseError;
};

export const getUrl = (url: string, query: Record<string, any>) => {
  const urlObj = new URL(url);

  Object.entries(query).forEach(([key, value]) => {
    urlObj.searchParams.append(key, value);
  });

  return urlObj.toString();
};
