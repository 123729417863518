import React from 'react';
import { useAmp } from 'next/amp';

import TextField from '../../controls/TextField';
import PhoneField from '../../controls/PhoneField';
import UniversalInlineForm from '../../controls/UniversalInlineForm';


import styles from './IndividualSizesForm.module.css';

const IndividualSizesForm = ({ theme }) => {
  const isAmp = useAmp();

  const handleRequestSubmit = ({ onSubmit, values }) => {
    const data = {
      name: values.name,
      phone: values.phone,
      email: values.email,
    };

    onSubmit(data);

    window.dataLayer?.push({
      event: 'autoEvent',
      eventCategory: 'Запрос цены',
      eventAction: 'Форма',
      eventLabel: '',
    });

    global.window?.dataLayer?.push({ event: 'generate_lead' });
  };

  const fields = [
    {
      name: 'name',
      id: 'personal-order-name',
      component: TextField,
      placeholder: theme === 'row' ? 'Имя*' : 'Как вас зовут?',
      theme: 'white',
      required: true,
    },
    {
      name: 'phone',
      type: 'tel',
      inputmode: 'tel',
      id: 'personal-order-phone',
      component: PhoneField,
      placeholder: theme === 'row' ? 'Телефон*' : 'Телефон',
      theme: 'white',
      required: true,
    },
    {
      name: 'email',
      type: 'email',
      inputmode: 'email',
      id: 'personal-order-email',
      component: TextField,
      placeholder: 'Email',
      theme: 'white',
      required: false,
    },
  ];

  return (
    <UniversalInlineForm
      className={isAmp ? 'individualSizesForm' : styles.form}
      actionTitle='Отправить заказ'
      title={
        theme === 'row'
          ? 'Заполните форму заказа'
          : 'Оставьте заявку на ковер по индивидуальным размерам, и свяжемся с вами в течение часа'
      }
      start='SEND_FORM_REQUEST'
      resolve='SEND_FORM_SUCCESS'
      reject='SEND_FORM_FAILURE'
      url='/personal-order'
      fields={fields}
      onSubmit={handleRequestSubmit}
      theme={theme}
    />
  );
};

export default IndividualSizesForm;
