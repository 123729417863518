
export const showModal = (modalComponent, modalType, modalName, modalProps) => (dispatch) => {
  dispatch({
    type: 'SHOW_MODAL',
    modalComponent,
    modalType,
    modalName,
    modalProps,
  });
};

export const hideModal = (count = 1) => (dispatch) => {
  dispatch({
    type: 'HIDE_MODAL',
    count,
  });
};

export const hideAllModals = () => (dispatch) => {
  dispatch({
    type: 'HIDE_ALL_MODALS',
  });
};

export const actionCreators = {
  showModal,
  hideModal,
  hideAllModals,
};

const ACTION_HANDLERS = {


  SHOW_MODAL: (state, action) => {
    const { modalChildrens } = state;

    return { ...state, modalChildrens: [
        ...modalChildrens,
        {
          component: action.modalComponent || null,
          type: action.modalType || null,
          name: action.modalName || null,
          props: action.modalProps || null,
        }
      ],};
  },
  HIDE_MODAL: (state, action) => {
    const { modalChildrens } = state;
    const size = modalChildrens.length - action.count > 0 ? modalChildrens.length - action.count : 0;

    return { ...state, modalChildrens: modalChildrens.slice(0, size),};
  },

  HIDE_ALL_MODALS: state => ({ ...state, modalChildrens: [],}),
};

const initialState = {
  modalChildrens: [],
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
