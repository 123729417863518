import React from 'react';
import { withRouter } from 'next/router';

import TextField from '../../controls/TextField';
import PhoneField from '../../controls/PhoneField';
import CheckboxField from '../../controls/CheckboxField';
import UniversalInlineForm from '../../controls/UniversalInlineForm';

import useCookie from '../../hooks/useCookie';


import styles from './ProductShowForm.module.css';

const ProductShowForm = ({
  router, theme, activeOffer, onSubmit: outerOnSubmit,
}) => {
  const [gaCookie] = useCookie('_ga');

  const isDebug = router?.query?.debug;

  const handleRequestSubmit = ({ onSubmit, values }) => {
    // const offset = new Date().getTimezoneOffset();

    const data = {
      productId: activeOffer?.id || null,
      clientId: gaCookie || null,
      name: values.name,
      phone: values.phone,
      date: values.date && !values.asap ? new Date(values.date).toUTCString() : null,
    };

    window.dataLayer?.push({ event: 'webit_events_form_consultation' });

    onSubmit(data);

    if (outerOnSubmit) {
      outerOnSubmit(data);
    }

    if (window?.ctw?.createRequest) {
      // '2020-11-24 15:11:00'
      const date = values.date && !values.asap ? new Date(values.date).toLocaleString('ru-RU', { timeZone: 'Europe/Moscow' }).replace(',', '').replace(/\./g, '-') : null;

      window?.ctw?.createRequest(
        'video-1',
        data.phone.replace(/[\s()+-]/g, ''),
        [
          {
            name: 'Name',
            value: data.name,
          },
        ],
        (success, data) => {
          console.log(success, data);
          if (success) {
            console.log(`Создана заявка на колбек, идентификатор: ${data.callbackRequestId}`);
          } else {
            switch (data.type) {
              case 'request_throttle_timeout':
              case 'request_throttle_count':
                console.log('Достигнут лимит создания заявок, попробуйте позже');
                break;
              case 'request_phone_blacklisted':
                console.log('номер телефона находится в черном списке');
                break;
              case 'validation_error':
                console.log('были переданы некорректные данные');
                break;
              default:
                console.log(`Во время выполнения запроса произошла ошибка: ${data.type}`);
            }
          }
        },
        date,
      );
    }
  };

  const fields = [
    {
      name: 'name',
      id: 'product-show-name',
      component: TextField,
      placeholder: 'Имя*',
      theme: 'bordered',
      required: true,
    },
    {
      name: 'phone',
      type: 'tel',
      inputmode: 'tel',
      id: 'product-show-phone',
      component: PhoneField,
      placeholder: 'Телефон*',
      theme: 'bordered',
      required: true,
    },
    {
      name: 'date',
      type: 'datetime-local',
      inputmode: 'datetime-local',
      id: 'product-show-date',
      component: TextField,
      placeholder: 'Дата звонка',
      theme: 'bordered',
      required: false,
      disabled: ({ values }) => !!values?.asap,
    },
    {
      name: 'asap',
      type: 'checkbox',
      id: 'product-show-asap',
      component: CheckboxField,
      placeholder: 'Позвонить в ближайшее время',
    },
  ];

  return (
    <UniversalInlineForm
      className={styles.form}
      actionTitle='Записаться на видеоконсультацию'
      title='Заполните форму заказа'
      start='SEND_FORM_REQUEST'
      resolve='SEND_FORM_SUCCESS'
      reject='SEND_FORM_FAILURE'
      url='/product-show-order'
      fields={fields}
      onSubmit={handleRequestSubmit}
      theme={theme}
      buttonProps={{ medium: true }}
    />
  );
};

export default withRouter(ProductShowForm);
