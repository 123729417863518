import { CSSProperties, MutableRefObject, ReactNode, useCallback, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';

import useMount from '@/shared/hooks/useMount';
import useUpdate from '@/shared/hooks/useUpdate';
import useUpdateEffect from '@/shared/hooks/useUpdateEffect';
import { PropsWithClassName } from '@/shared/types/util';

import { useAppStoreApi } from '../AppStoreProvider';
import useAppStore from '../useAppStore';

import { AppPortal } from '.';

type CreatePortalReturn = [MutableRefObject<HTMLDivElement | null>, () => void, boolean];

export const usePortalStore = () => {
  const { setState } = useAppStoreApi();
  const portals = useAppStore((state) => state.portals);

  const getValue = useCallback((id: string) => portals.data?.[id], [portals.data]);

  const setValue = useCallback(
    (portal: AppPortal) => {
      setState((state) => {
        state.portals.data = {
          ...state.portals.data,
          [portal.id]: portal as any,
        };
      });
    },
    [setState]
  );

  const removeValue = useCallback(
    (id: string) => {
      setState((state) => {
        delete state.portals.data[id];
      });
    },
    [setState]
  );

  return useMemo(
    () => ({
      ...portals,
      getValue,
      setValue,
      removeValue,
    }),
    [portals, getValue, setValue, removeValue]
  );
};

const useCreatePortal = (id: string, attrs?: PropsWithClassName<{ style?: CSSProperties }>): CreatePortalReturn => {
  const [updateCounter, update] = useUpdate();
  const portalElement = useRef<HTMLDivElement | null>(null);
  const { className, style } = attrs || {};

  const removeValue = useCallback(() => {
    if (!portalElement.current) {
      return;
    }
    document.body.removeChild(portalElement.current);
    portalElement.current = null;
  }, []);

  useMount(() => {
    const node = document.createElement('div');
    node.setAttribute('id', id);
    document.body.appendChild(node);

    if (className) {
      node.classList.add(className);
    }

    if (style) {
      Object.keys(style).forEach((key) => {
        node.style[key] = style[key];
      });
    }

    portalElement.current = node;
    update();
  });

  return [portalElement, removeValue, Boolean(updateCounter)];
};

const usePortal = (id: string, attrs?: PropsWithClassName<{ style?: CSSProperties }>) => {
  const { setValue, getValue, removeValue } = usePortalStore();

  const [portal, removeElement, isPortalReady] = useCreatePortal(id, attrs);

  const onRender = useCallback(
    (children: ReactNode) => {
      if (!getValue(id)?.active) {
        return null;
      }

      if (!('current' in portal) || !portal?.current || !isPortalReady) {
        return null;
      }

      return createPortal(children, portal.current);
    },
    [portal, getValue, id, isPortalReady]
  );

  const onClose = useCallback(() => {
    removeValue(id);
    removeElement();
  }, [id, removeValue, removeElement]);

  useUpdateEffect(() => {
    if (getValue(id)?.active || !('current' in portal) || !portal?.current) {
      return;
    }
    const value = { id, active: true, node: portal.current };

    setValue(value);
  }, [id, isPortalReady]);

  return {
    active: Boolean(getValue(id)?.active),
    onRender,
    onClose,
  };
};

export default usePortal;
