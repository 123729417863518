import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './CheckboxField.module.css';

const CheckboxField = ({ input, meta = {}, children, placeholder, className, tooltipFilterItem }) => {
  const { touched, error, warning } = meta;

  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className={classnames(styles.checkbox, className, { [styles.disabled]: input.disabled })}>
      <input
        className={classnames(styles.checkbox__input, { _error: touched && error })}
        id={input.id}
        type='checkbox'
        {...input}
      />
      <label
        className={classnames(styles.checkbox__label, { [styles.checkboxLabelFilterItem]: tooltipFilterItem })}
        htmlFor={input.id}
      >
        {children || placeholder || ''}
      </label>
      {tooltipFilterItem ? (
        <div
          className={styles.tooltipIconWrap}
          data-tooltip-id={`checkbox-${input.name || 'tooltip'}`}
          data-tooltip-content={tooltipFilterItem || ' '}
        >
          <span>?</span>
        </div>
      ) : null}
      {!!touched &&
        ((!!error && <span className={styles.checkbox__error}>{error}</span>) || (!!warning && <span>{warning}</span>))}
      {!!isRendered && !!tooltipFilterItem && (
        <Tooltip
          className={styles.tooltipPopup}
          positionStrategy='fixed'
          id={`checkbox-${input.name || 'tooltip'}`}
          style={{ zIndex: 300 }}
          scrollHide
          place='bottom'
          type='light'
          clickable
          effect='solid'
        />
      )}
    </div>
  );
};

CheckboxField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  tooltipFilterItem: PropTypes.string,
};

export default CheckboxField;
