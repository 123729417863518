import React from 'react';
import { NProgress } from '@tanem/react-nprogress';
import PropTypes from 'prop-types';

import Loader from 'components/Loader';
import Bar from 'components/Progress/components/Bar';
import Container from 'components/Progress/components/Container';

const Progress = ({ isAnimating }) => (
  <NProgress isAnimating={isAnimating}>
    {({ animationDuration, isFinished, progress }) => (
      <Container animationDuration={animationDuration} isFinished={isFinished}>
        <Bar animationDuration={animationDuration} progress={progress} />
        <Loader isFetching={isAnimating} />
      </Container>
    )}
  </NProgress>
);

Progress.propTypes = {
  isAnimating: PropTypes.bool.isRequired,
};

export default Progress;
