/* eslint-disable react-hooks/exhaustive-deps */
import { MutableRefObject, useCallback } from 'react';

import useCancellableEffect from './useAsyncEffect';

const useMount = (fn: () => void, clearFn?: () => void) => {
  const runOnce = useCallback((cancelRef?: MutableRefObject<boolean>) => {
    if (cancelRef?.current) {
      return;
    }

    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useCancellableEffect(runOnce, [], clearFn);
};

export default useMount;
