import { FORCE_BASIC_AUTH } from 'cfg';
import debug from 'debug';
import get from 'lodash/get';
import { setCookie } from 'nookies';
import setCookieParser from 'set-cookie-parser';

const log = debug('api:callAPI');

const getCookieString = (cookies) => {
  const reducer = (acc, currVal) => `${acc}${currVal}=${cookies[currVal]};`;

  return Object.keys(cookies).reduce(reducer, '');
};

const callAPI =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (!action) {
      return false;
    }

    const {
      type,
      types,
      requestData,
      isNotFoundEnabled = true,
      url,
      shouldCallAPI = () => true,
      payload = {},
      options = {},
    } = action;

    const isServer = typeof global?.window === 'undefined';
    const isDebug = false;
    const host = get(getState(), 'client.host');

    if (isDebug) {
      debug.enable('*');
    }

    if (process.env.NODE_ENV === 'production') {
      options.credentials = 'include';
    }

    if (!url) {
      return next(action);
    }

    if (!shouldCallAPI(getState())) {
      return false;
    }

    if (process.env.NODE_ENV === 'production') {
      if (isServer) {
        const { client, userAgent } = getState();

        options.headers = options.header || {};

        if (client.cookie) {
          options.headers.Cookie = getCookieString(client.cookie);
        }

        if (client.ip) {
          options.headers['X-FRONTEND-IP'] = client.ip;
        }

        if (client.ifModifiedSince) {
          options.headers['X-IF-MODIFIED-SINCE'] = client.ifModifiedSince;
        }

        if (userAgent?.value) {
          options.headers['X-FRONTEND-USERAGENT'] = userAgent?.value;
        }
      }
    }

    if (process.env.NODE_ENV === 'development') {
      if (!options.headers) {
        options.headers = {};
      }

      if (!options.headers.Authorization) {
        options.headers.Authorization = 'Basic a292ZXI6a292ZXI=';
      }
    }

    if (FORCE_BASIC_AUTH) {
      if (!options.headers) {
        options.headers = {};
      }

      options.headers.Authorization = FORCE_BASIC_AUTH;
    }

    if (!options.timeout) {
      options.timeout = 30000;
    }

    log('Set headers', options?.headers);

    // TODO: methods without requests should use 'types' array for actions types naming
    let typeRequest = `${type}_REQUEST`;
    let typeSuccess = `${type}_SUCCESS`;
    let typeFail = `${type}_FAIL`;

    if (types && types.length === 3) {
      [typeRequest, typeSuccess, typeFail] = types;
    }

    dispatch({
      type: typeRequest,
      payload,
    });

    const isNewApi = false;
    const envBaseURL = process.env.NEXT_PUBLIC_API_ROOT;

    const baseURL =
      `${envBaseURL.replace('/rest', '')}${isNewApi ? '/' : '/rest'}` ||
      `https://${host}${isNewApi ? '/' : '/rest'}` ||
      `https://kover.ru${isNewApi ? '/' : '/rest'}`;

    return new Promise((resolve, reject) => {
      const q = `${baseURL}${url}${
        requestData
          ? `?${Object.keys(requestData)
              .map((key) => `${key}=${JSON.stringify(requestData[key])}`)
              .join('&')}`
          : ''
      }`;

      if (isDebug) {
        log('Start', q, options);
      }

      fetch(q, { ...options, credentials: 'include' })
        .then(async (response) => {
          const cookies = setCookieParser.parse(response.headers.get('set-cookie'));

          if (
            (response.status === 302 ||
              response.status === 301 ||
              response.status === 307 ||
              response.status === 308) &&
            response.headers.get('X-LOCATION')
          ) {
            dispatch({
              type: 'SET_REDIRECT',
              payload: {
                destination: response.headers.get('X-LOCATION'),
                permanent: response.status === 301 || response.status === 308,
              },
            });
          }

          if (response.status === 404 || response.status === 400) {
            if (isNotFoundEnabled) {
              dispatch({
                type: 'SET_IS_NOT_FOUND',
                payload: true,
              });
            }

            return {
              json: null,
              // cookie: response.headers.get('set-cookie'),
              cookies,
              status: response.status,
            };
          }

          return {
            json: await response.json(),
            // cookie: response.headers.get('set-cookie'),
            cookies,
            status: response.status,
          };
        })
        .then(({ json, cookies, status }) => {
          if (isDebug) {
            log('Finish', q);
          }

          if (options?.ctx) {
            if (cookies?.length) {
              cookies.forEach((cookie) => {
                const { name, value, ...cookieParams } = cookie;

                setCookie(options.ctx, name, value, cookieParams);
              });
            }

            const { client } = getState();

            if (client?.ifModifiedSince && status === 304) {
              options.ctx.res.statusCode = 304;
            }
          }

          if (!json?.error) {
            dispatch({
              type: typeSuccess,
              payload,
              responseJSON: json,
            });
            resolve(json);
          } else {
            dispatch({
              type: typeFail,
              payload,
              responseJSON: json,
            });

            reject(json);
            // throw new Error(json.error.name || json.error.status)
          }
        })
        .catch((error) => {
          dispatch({
            type: typeFail,
            payload,
            responseJSON: {},
          });

          // TODO: fix me
          if (error.name === 'SyntaxError' || (error.name === 'FetchError' && error.type === 'invalid-json')) {
            resolve({});
          }

          resolve({});
          // reject(error);
        });
    });
  };

export default callAPI;
