import { APP_SECTION, SECTION_TO_ROUTE } from '@/core/config/constants/routes';
import useSectionRoute, { createRouteUrl, RouteItem } from '@/shared/hooks/useSectionRoute';

const BASE_ROUTE = SECTION_TO_ROUTE[APP_SECTION.CONTACTS];

const GET_CONTACTS_ROUTE = {
  ROOT: () => createRouteUrl(BASE_ROUTE),
} as const;

const CONTACT_SECTION = {
  section: APP_SECTION.CONTACTS,
  getUrl: GET_CONTACTS_ROUTE.ROOT,
};

const useContactSectionRoute = () => useSectionRoute(CONTACT_SECTION) as RouteItem;

export default useContactSectionRoute;
