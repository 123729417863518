/* eslint-disable no-unused-expressions */
const getBaseDomain = ({ regions, host }) => {
  try {
    const isClient = typeof global?.window !== 'undefined';

    let urlParams = null;

    if (isClient) {
      urlParams = new URLSearchParams(window.location.search);
    }

    const isDebug = urlParams?.has('debug');

    isDebug && console.log('getBaseDomain: start', host, regions);

    const hostRegionBySubdomain = regions?.find((r) => host?.startsWith(`${r.subdomain}.`));

    isDebug && console.log('getBaseDomain: check subdomain', hostRegionBySubdomain);

    if (hostRegionBySubdomain) {
      const [, ...baseDomain] = host.split('.');

      return baseDomain?.join('.') || 'kover.ru';
    }

    return host || 'kover.ru';
  } catch (e) {
    console.log(e);

    return 'kover.ru';
  }
};

export default getBaseDomain;
