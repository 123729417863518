import { useRouter } from 'next/router';

const useActiveOffer = ({ product }) => {
  const router = useRouter();
  const { query: { splat } } = router;
  const offerId = splat?.[0];

  if (product?.offers?.length) {
    if (offerId) {
      const findedOffer = product.offers.find(offer => offer?.id?.toString() === offerId.toString());

      if (findedOffer) {
        return findedOffer;
      }

      return null;
    }

    return product.offers?.[0];
  }

  return null;
};

export default useActiveOffer;
