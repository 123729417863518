import { useCallback, useContext, useMemo } from 'react';

// eslint-disable-next-line import/no-cycle
import { useAppStore, useAppStoreApi } from '@/core/store';
import { toResponseError } from '@/shared/api/util';
import useToggle from '@/shared/hooks/useToggle';
import { isClient } from '@/shared/lib/helpers/predicates';
import { Id } from '@/shared/types';

import { PagePropsContext } from '../../../../../contexts';
import UserApi from '../../../api/user';


const api = new UserApi();

declare const window: Window & {
  dataLayer: Record<string, unknown>[];
  yaCounter37203035: { reachGoal: (event: string) => void };
};

const useAppUserFavorites = () => {
  const [isLoading, toggleLoading] = useToggle(false);

  const { setState } = useAppStoreApi();
  // TODO: refactor
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = useContext(PagePropsContext);


  const list = useAppStore((state) => state.user.favorites);

  const isInFavorites = useCallback((id: Id) => !!list.find((i) => +i === id), [list]);

  const setAppFavorites = useCallback(
    (payload: Id[]) => {
      setState((state) => {
        state.user.favorites = payload;
      });
    },
    [setState]
  );

  const addToFavorites = useCallback(
    async (id: Id) => {
      try {
        const payload = [...(list ?? []), id];

        if (user?.authorized) {
          toggleLoading(true);
          const result = await api.addFavorites(id);
          toggleLoading(false);

          if (!Array.isArray(result.data)) {
            const update = result.data.products.data.map((item) => +item.id);
            setAppFavorites(update);
          }

          return;
        }

        setAppFavorites(payload);

        if (isClient) {
          window?.dataLayer?.push({
            event: 'add_to_wishlist',
            ecommerce: {
              item: {
                products: [payload],
              },
            },
          });

          if (window.yaCounter37203035) {
            window.yaCounter37203035.reachGoal('add_to_wishlist');
          }
        }

        return payload;
      } catch (error: unknown) {
        console.error('[user][api][postFavorites]', { error });

        throw toResponseError(error);
      }
    },
    [user?.authorized, list, setAppFavorites, toggleLoading]
  );

  const removeFromFavorites = useCallback(
    async (id: Id) => {
      try {
        const payload = list.filter((i) => +i !== +id);

        if (user?.authorized) {

          toggleLoading(true);
          const result = await api.deleteFavorites(id);

          if (Array.isArray(result.data) && result.data.length === 0) {
            setAppFavorites([]);
            toggleLoading(false);
            return;
          }

          if (!Array.isArray(result.data)) {
            const update = result.data.products.data.map((item) => +item.id);
            setAppFavorites(update);
            toggleLoading(false);
            return;
          }

          throw new Error('Undefined response data while deleting favorite product from a list');
        }

        setAppFavorites(payload);

        return payload;
      } catch (error: unknown) {
        console.error('[user][api][deleteFavorites]', { error });

        throw toResponseError(error);
      }
    },
    [list, user?.authorized, setAppFavorites, toggleLoading]
  );

  return useMemo(
    () => ({
      isLoading,
      list,
      isInFavorites,
      addToFavorites,
      removeFromFavorites,
    }),
    [isLoading, list, isInFavorites, addToFavorites, removeFromFavorites]
  );
};

export default useAppUserFavorites;
