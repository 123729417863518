import { connect } from 'react-redux';

import { favoritesProductsSelector } from '../../redux/reducers/favorites/selectors';

import Header from './Header';

const mapStateToProps = (state) => ({
  favorites: favoritesProductsSelector(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
