import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { isClient } from '@/shared/lib/helpers/predicates';

import Button from '../../controls/Button';
import getNormalizedUrl from '../../helpers/getNormalizedUrl';
import isUrlEqual from '../../helpers/isUrlEqual';
import initializeAxiosInstance from '../../redux/api/initializeAxiosInstance';

import styles from './NotFound.module.css';

const NotFound = () => {
  const router = useRouter();
  // eslint-disable-next-line consistent-return
  const checkRedirects = async () => {
    const instance = initializeAxiosInstance({});

    const redirectResponse = await instance.get('/redirects');

    const redirects = redirectResponse?.data || [];

    if (redirects?.length) {
      const testDecoded = `${decodeURIComponent(window.location.pathname)}${decodeURIComponent(
        window.location.search
      )}${decodeURIComponent(window.location.hash)}`;
      const test = `${window.location.pathname}${window.location.search}${window.location.hash}`;

      const findedRedirect = redirects.find((x) => isUrlEqual({ from: x.from, url: test, urlDecoded: testDecoded }));

      if (findedRedirect?.to) {
        const destinationUrl = new URL(getNormalizedUrl(findedRedirect.to), `https://${window.location.host}`);

        if (window.location?.searchParams?.size) {
          window.location.searchParams.forEach((value, key) => {
            if (!destinationUrl.searchParams.has(key)) {
              destinationUrl.searchParams.set(key, value);
            }
          });
        }

        return router.push(destinationUrl.toString());
      }
    }

    // return router.push('/catalog');
  };

  useEffect(() => {
    if (!isClient || !window?.yaCounter37203035) {
      return;
    }

    setTimeout(() => {
      window?.dataLayer?.push?.({
        event: 'autoEvent_ni',
        eventCategory: 'error_404',
        eventAction: document.location.href,
        eventLabel: document.referrer,
      });

      checkRedirects();
    }, 300);
  }, []);

  return (
    <>
      <Head>
        <meta name='robots' content='noindex' key='robot' />
      </Head>

      <div className={styles.notFound}>
        <div className={styles.notFoundContent}>
          <div className={styles.notFoundImage}>
            <svg width='144' height='50' viewBox='0 0 144 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M97.1 2H2V47.8H97.1V2Z'
                stroke='#49494D'
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M105.4 30.5V33.7'
                stroke='#49494D'
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M113.7 20.7V36'
                stroke='#49494D'
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M76.6 20.7V36'
                stroke='#49494D'
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M71.4 12.3L65.8 28.8'
                stroke='#49494D'
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M124 21H120L113.7 27.4'
                stroke='#49494D'
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M129.4 20.7V29.7C129.4 33.2 132.3 36.1 135.8 36.1C139.3 36.1 142.2 33.2 142.2 29.7V20.7'
                stroke='#49494D'
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M44.2 20.7V29.7C44.2 33.2 47.1 36.1 50.6 36.1C54.1 36.1 57 33.2 57 29.7V20.7'
                stroke='#49494D'
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M56.9 27.6V18.6C56.9 15.1 54 12.2 50.5 12.2C47 12.2 44.1 15.1 44.1 18.6V27.6'
                stroke='#49494D'
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M65.8 28.8H82.2'
                stroke='#49494D'
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M29.4 20.7V36'
                stroke='#49494D'
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M24.3 12.3L18.7 28.8'
                stroke='#49494D'
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M18.7 28.8H35'
                stroke='#49494D'
                strokeWidth='3'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <br />
          <div className={styles.notFoundText}>
            Мы не нашли страницу которую вы искали. Возможно, ее больше нет или в ссылке есть ошибка.
            <br />
            Предлагаем выбрать ковер из каталога или посмотреть страницу популярных ковров.
          </div>
          <br />
          <Button href='/' className={styles.notFoundButton}>
            На главную
          </Button>
        </div>
      </div>
    </>
  );
};

export default NotFound;
