import { ofType } from 'redux-observable';
import { from, of, EMPTY } from 'rxjs';
import { mergeMap, catchError, takeUntil } from 'rxjs/operators';

import {
  getSearchRequest,
  getSearchSuccess,
  getSearchFailure,
  getSearchTerminate,
} from './actions';

import initializeAxiosInstance from '../../api/initializeAxiosInstance';

const pageSize = 36;

async function getLeadriveSearch({
  query,
  page,
  categoryId,
  sortDir,
  sortName,
}) {
  return window?.fsayer('search', {
    q: query,
    limit: pageSize,
    offset: (page - 1) * pageSize,
    output_format: 'full',
    aggr_format: 'full',
    groupsOnly: 1,
    category_id: categoryId || null,
    sortDir: sortDir || 'desc',
    sortName: sortName || 'relevance',
  });
}

export const getSearchRequestEpic = (action$, state$) => action$.pipe(
  ofType(getSearchRequest),
  mergeMap((actionData) => {
    const {
      payload: {
        page,
        query,
        categoryId,
        sortName,
        sortDir,
        isFirstCall,
        router,
      },
    } = actionData;

    const currentId = state$?.value?.regions?.currentId;

    const instance = initializeAxiosInstance({ store: state$ });

    return from(getLeadriveSearch({
      query,
      categoryId,
      sortName,
      sortDir,
      page,
    }))
      .pipe(
        mergeMap((searchResponse) => {
          console.log('Got Leadrive response', searchResponse);
          const exactMatchCat = searchResponse?.cats?.data?.find(cat => !!cat?.exactMatch);
          const exactMatchItem = searchResponse?.items?.data?.find(cat => !!cat?.exactMatch);
          const exactMatchData = exactMatchCat || exactMatchItem;

          if (exactMatchData?.url) {
            console.log('Got Leadrive exact match', exactMatchData);
            router?.push(exactMatchData.url);

            return EMPTY;
          }

          const productIds = searchResponse?.items?.data?.map(item => item.group_id);

          if (!productIds?.length) {
            return of(
              getSearchSuccess({
                pagesCount: 0,
                page: 0,
                query,
                products: [],
              }),
            );
          }

          const {
            category_id: categories,
            price,
            price_min: priceMin,
            price_max: priceMax,
          } = searchResponse?.items?.aggregations;

          const productsParams = {
            page_size: productIds?.length,
            // pageSize: productIds?.length,
            filter: JSON.stringify({ id: productIds }),
          };

          return from(instance.get(`/regions/${currentId || 'current'}/products/`, { params: productsParams }))
            .pipe(
              mergeMap((result) => {
                // convert to products
                // get filters

                /*
                  items.aggregations.category_id
                    count: 15
                    id: "4492140"
                    name: "Доступные"
                    url: "/catalog/f/price-40000/"
                 */


                const offset = searchResponse?.items?.offset;
                const size = searchResponse?.items?.size;
                const total = searchResponse?.items?.total;
                const searchItems = searchResponse?.items?.data || [];
                const aggregations = searchResponse?.items?.aggregations;

                const products = result?.data?.products?.data || [];

                console.log('Search results:', searchResponse, products);

                const pagination = {
                  page: Math.ceil(offset / pageSize) + 1,
                  pages: Math.ceil(total / pageSize),
                  count: total,
                };

                // return {
                //   products,
                //   pagination: {
                //     page: Math.ceil(offset / pageSize) + 1,
                //     pages: Math.ceil(total / pageSize),
                //     count: total,
                //   },
                // };

                if (result) {
                  // const { products, pagination } = result;

                  if (products?.length) {
                    if (isFirstCall) {
                      if (products.length) {
                        window.dataLayer?.push({
                          event: 'autoEvent_ni',
                          eventCategory: 'Поиск',
                          eventAction: 'Страница поиска',
                          eventLabel: `Запрос: ${query}`,
                        });
                      } else {
                        window.dataLayer?.push({
                          event: 'autoEvent_ni',
                          eventCategory: 'Поиск',
                          eventAction: 'Пустой результат поиска',
                          eventLabel: query,
                        });
                      }
                    }

                    const chunk = {
                      products,
                      page: pagination?.page || 1,
                      pagesCount: pagination?.pages || 1,
                      count: pagination?.count || 1,
                      query,
                    };

                    if (isFirstCall) {
                      chunk.aggregations = {
                        categories,
                        price,
                        priceMin,
                        priceMax,
                      };
                    }

                    return of(getSearchSuccess(chunk));
                  }
                }
                return of(
                  getSearchSuccess({
                    pagesCount: 0,
                    page: 0,
                    query,
                    products: [],
                  }),
                );
              }),
              catchError((error) => {
                if (error) {
                  console.log(error);
                }

                return of(
                  getSearchFailure(),
                );
              }),
            );
        }),
        takeUntil(action$.pipe(ofType(getSearchTerminate))),
      );
  }),
);
