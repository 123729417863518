import { AppSessionData } from '@/application';
import { ColorItem } from '@/core';

const legacyColorsMapper = (colors: ColorItem[]) =>
  colors.map(({ colorHex, imageSrc, ...rest }) => ({
    ...rest,
    rgb: colorHex ?? '',
    file: imageSrc ?? ''
  }));

const legacyBasePropsConverter = (session: AppSessionData) => {
  if (!session) {
    return {};
  }

  const { client, region, dictionary, layoutData, settings, user } = session;

  const { cityShopsCount, totalShopsCount, isFreeShippingDisabled } = region ?? {};

  const { colors } = dictionary ?? { colors: [] };

  const info = {
    colors: legacyColorsMapper(colors),
    currentShopsCount: cityShopsCount,
    shopCount: totalShopsCount,
    isFreeShippingDisabled
  };
  return {
    region,
    regionId: region.id,
    user,
    info,
    ...dictionary,
    ...client,
    ...layoutData,
    ...settings
  };
};

export default legacyBasePropsConverter;
