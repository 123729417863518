import { GetServerSidePropsContext } from 'next';

import CookieService from '@/shared/lib/helpers/cookies';

import { COOKIE_WHITELIST } from '../config/constants/api';

const cookieService = new CookieService();

const getBaseCookies = (ctx: GetServerSidePropsContext) => {
  // remove fucking matomo's broken cookies
  // const cookiesRegexpPrivate = /^_/g;
  const cookiesRegexpMatomo = /^_pk_/g;
  const cookiesRegexpExp = /^experiment-/g;

  let cookies = cookieService.parseServerCookies(ctx) || {};

  if (typeof cookies === 'object' && Object.keys(cookies).length) {
    cookies = Object.keys(cookies)
      .filter(
        (key) =>
          !cookiesRegexpMatomo.test(key) && (cookiesRegexpExp.test(key) || COOKIE_WHITELIST.find((x) => x === key))
      )
      .reduce((reducer, key) => ({ ...reducer, [key]: cookies[key] }), {});
  }

  return cookies;
};

export default getBaseCookies;
