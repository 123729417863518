/* eslint-disable max-len */
import debug from 'debug';
import getBaseDomain from 'helpers/getBaseDomain';
import Cookie from 'js-cookie';

const log = debug('app');

export function trackPageView(url, regions) {
  if (typeof window === 'undefined') {
    return;
  }
  const baseDomain = getBaseDomain({ regions, host: global?.window?.location.host });

  if (window && window.ga) {
    window?.ga('set', 'page', url);
    window?.ga('send', 'pageview', url);
  }

  if (window && window.ym) {
    window?.ym('46651908', 'hit', url);
    window?.ym('37203035', 'hit', url);
  }

  // const isSafetyPopupShowed = Cookie.get('is-safety-popup-showed');
  const pageCounter = Cookie.get('page-counter') || 0;

  Cookie.set('page-counter', +pageCounter + 1, { domain: baseDomain });
}

export function handleReportWebVitals(metric) {
  /*
    ga('send', 'event', {
      eventCategory: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      eventAction: name,
      eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      eventLabel: id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate.
    })
  */
  const { id, name, value } = metric;

  if (typeof window === 'undefined') {
    return;
  }

  // const baseHost = getBaseDomain({ regions, host: global?.window?.location.host });

  // const pagesCount = Cookie.get('kvr_pages_cnt') || 0;

  // if (+pagesCount + 1 === 10 && window.fbq) {
  //   window.fbq('trackCustom', 'Pages_10');
  // }

  // Cookie.set('kvr_pages_cnt', +pagesCount + 1, { expires: new Date(new Date().getTime() + 30 * 60 * 1000), domain: baseHost });

  const urlParams = new URLSearchParams(window.location.search);

  const isDebug = urlParams.has('debug');

  if (isDebug) {
    debug.enable('*');

    log(metric);

    if (window && window.ga) {
      window.ga('send', 'event', {
        eventCategory: 'Performance',
        eventAction: name,
        eventValue: Math.round(name === 'CLS' ? value * 1000 : value),
        eventLabel: id,
        nonInteraction: true,
      });
    }
  }

  if (name === 'Next.js-render') {
    global.window?.dataLayer?.push({ event: 'real_history_changed' });
  }
}
