import { isEmpty } from 'lodash';
import { GetServerSidePropsContext } from 'next';

export const isClient = typeof window !== 'undefined';

export const isDevMode = process.env.NODE_ENV === 'development';

export const isClientDebug = () => {
  if (!isClient) return false;

  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.has('debug');
};

export const isServerDebug = (query: GetServerSidePropsContext['query']) => query?.debug === '1';

export const isServerDebugKey = (query?: GetServerSidePropsContext['query'], key?: string) => query?.debug === key;

export const isDevDebugMode = () => isClient && (isDevMode || isClientDebug());

export const isFalsyOrEmpty = (value: unknown) => {
  if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
    return !value;
  }

  return isEmpty(value);
};
