import { MutableRefObject, RefCallback, useCallback, useRef, useState } from 'react';

export type RefElement<T> = MutableRefObject<T | null>;

export type RefCallbackData<T> = [RefCallback<T>, RefElement<T>, boolean, () => void];

type Middleware<T> = (node: T) => T;

function useRefCallback<T>(middleware?: Middleware<T>): RefCallbackData<T> {
  const [ready, setReady] = useState<boolean>(false);

  const ref = useRef<T | null>(null);

  const setRef = useCallback<RefCallback<T>>(
    (node) => {
      if (!node) {
        return null;
      }

      const result = middleware ? middleware?.(node) : node;

      ref.current = result;

      setReady(true);
    },
    [middleware]
  );

  const onClear = useCallback(() => {
    ref.current = null;
    setReady(false);
  }, []);

  return [setRef, ref, ready, onClear];
}

export default useRefCallback;
