/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import Cookie from 'js-cookie';

import cookies from '../../constants/cookies';
import { PagePropsContext } from '../../contexts';
import Button from '../../controls/Button';
import HowToFitBanner from '../HowToFitBanner';

import styles from './HowToFitPopup.module.css';

export default function HowToFitPopup({ className }) {
  const { domain } = useContext(PagePropsContext);
  const [isOpen, setIsOpen] = useState();

  const handleClose = () => {
    Cookie.set(cookies.isHowToFitShowed, true, { domain });

    setIsOpen(false);
  };

  const checkPopup = () => {
    setTimeout(() => {
      const check = Cookie.get(cookies.isHowToFitShowed);

      if (!check) {
        setIsOpen(true);
      }
    }, 300);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const isEnabled =
      urlParams.get('utm_source') &&
      urlParams.get('utm_source') === 'baza' &&
      urlParams.get('utm_medium') &&
      urlParams.get('utm_medium') === 'sms' &&
      urlParams.get('utm_campaign') &&
      urlParams.get('utm_campaign') === 'locdaun2';

    if (isEnabled) {
      checkPopup();
    }
  }, []);

  return (
    !!isOpen && (
      <div className={classnames(styles.layout, className, { [styles.open]: isOpen })} onClick={handleClose}>
        <div className={styles.content}>
          <HowToFitBanner mode='column' className={styles.banner} />

          <Button className={styles.button} onClick={handleClose} widthMax medium>
            Перейти в магазин
          </Button>
        </div>
      </div>
    )
  );
}
